import { CommonModule } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Input, Output, EventEmitter,NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { catchError, map, Observable, of,switchMap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { ChatService } from '../../services/chat.service';
import { ChangeDetectorRef } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-physical-fitness-dashboard',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule,NgSelectModule],
  templateUrl: './physical-fitness-dashboard.component.html',
  styleUrl: './physical-fitness-dashboard.component.css'
})
export class PhysicalFitnessDashboardComponent implements OnChanges {
  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>();
  private benchmarksMap: Map<string, any> = new Map<string, any>();
  fitnessTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number; averageZScore: number}[] = [];
  enduranceTable:  { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number; averageZScore: number}[] = [];
  strengthTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number; averageZScore: number}[] = [];
  flexibilityTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number; averageZScore: number}[] = [];
  balanceTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number; averageZScore: number}[] = [];
  shouldShowMaleColumn: boolean = false;
  shouldShowFemaleColumn: boolean = false;
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedStrenghtStatus: number = 0;
  selectedFlexibilitytStatus: number = 0;
  selectedBalancesStatus: number = 0;
  selectedEndurancetStatus: number = 0;
  selectedBalancetStatus: number = 0;
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  fitnessTableHeader: any;
  strengthTableHeader: any;
  flexibilityTableHeader: any;
  enduranceTableHeader: any;
  balanceTableHeader: any;
  fitnessHeader: any;
  strengthHeader: any;
  flexibilityHeader: any;
  enduranceHeader: any;
  balanceHeader: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = ''; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = null;
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  TopPerformer: any[] = [];
  StrengthPerformers: any[] = [];
  FlexibilityPerformers: any[] = [];
  BalancePerformers: any[] = [];
  EndurancePerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  admitcardReport: any;
  msg: any;
  nutResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  pfaPerformers: any;
  plaResponse: any;
  plaPerformers: any;
  PhysicalFitness: boolean = false;
  averageOverallZScore: number = 0;
  Orgainsation: any;
  selectedOrg: string = '';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  strengthOverallPercentage: any;
  enduranceOverallPercentage: any;
  flexibilityOverallPercentage: any;
  balanceOverallPercentage: any;
  teams: any;
  leveloneReference: any;
  nutritionReport: any;
  leveloneReport: any;
  shouldShowFitnessMaleColumn: boolean = false;
  shouldShowFitnessFemaleColumn: boolean = false;
  shouldShowEnduranceMaleColumn: boolean = false;
  shouldShowEnduranceFemaleColumn: boolean = false;
  shouldShowStrengthMaleColumn: boolean = false;
  shouldShowStrengthFemaleColumn: boolean = false;
  shouldShowFlexibilityMaleColumn: boolean = false;
  shouldShowFlexibilityFemaleColumn: boolean = false;
  shouldShowBalanceMaleColumn: boolean = false;
  shouldShowBalanceFemaleColumn: boolean = false;
  ImageUrl: any;
  showImage: boolean=false;
  image: any;
  sciStaff: boolean = false;
  athletes: any;
  fitnessCount: any;
  IsAssessor: boolean = false;
 
selectOptions = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Excellent' },
  { value: 2, label: 'Good' },
  { value: 3, label: 'Average' },
  { value: 4, label: 'Poor' },
  ];
  aiData: any;
  playerData: any;

  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
  }
  onStatusChange() {
    this.loadTopPerformersData();
  }
  onFitnessStatusChange() {
    this.loadFitnessTopPerformersData();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }
  async ngOnInit(): Promise<void> {

    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.IsAssessor = this.privilege.isAssessor ?? false;

    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );

    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.isLoading = true;
    this.chat = '';
    this.athletedata = undefined;
    this.selectedStatus = 0;
    await this.getfitnessreport();
    this.loadFitnessTopPerformersData();
    this.loadStrengthTopPerformersData();
    this.loadFlexibilityTopPerformersData();
    this.loadBalancesTopPerformersData();
    this.loadEnduranceTopPerformersData();
   this. getPlayer();
this.ibmCalculation()
this.isLoading = false;
  }
  ibmCalculation(){
    const genderId = 1; // Replace with actual values
    const asiCurrentClubTeam = '11'; // Replace with actual values

    this.assessmentService.getInterNationalBenchMark(genderId, asiCurrentClubTeam).subscribe((response: any[]) => {
      console.log(response, "IBM Report");

      // Process each player's data
      this.pfaResponse.forEach((player: any) => {
        const playerIBM: Record<string, number> = {}; // Define as a record with string keys and number values

        // Match each testColumnName with the player columns
        response.forEach(ibm => {
          const columnName = ibm.testColumnName; // e.g., 'naBodyWater'
          let ibmRange = ibm.ibmRange; // e.g., "7–12", "2.5–4.0", or "16–20"
          let totalPercentage = 0;  // Variable to accumulate total percentage
          let validCount = 0;       // Variable to count valid percentages
        
          if (player[columnName] !== undefined && ibmRange) {
            // Normalize the range by replacing non-standard dashes with a hyphen
            ibmRange = ibmRange.replace(/–/g, '-');
        
            // Validate and parse the range
            const rangeMatch = ibmRange.match(/^([\d.]+)-([\d.]+)$/); // Matches ranges like "7-12" or "17.2-21.4"
        
            if (rangeMatch) {
              const lower = parseFloat(rangeMatch[1]); // Parse the lower bound
              const upper = parseFloat(rangeMatch[2]); // Parse the upper bound
        
              if (!isNaN(lower) && !isNaN(upper)) {
                const value = player[columnName];
        
                // Calculate percentage
                let percentage = ((value - lower) / (upper - lower)) * 100;
        
                // Ensure percentage is positive and capped at 100
                percentage = Math.min(Math.abs(percentage), 100);
        
                // Round to 2 decimal places
                percentage = parseFloat(percentage.toFixed(2));
        
                playerIBM[columnName] = percentage; // Store percentage
        
                // Add to total and count for average calculation
                totalPercentage += percentage;
                validCount++;
              }
            }
          }
        
          // Calculate averageIBM if there were valid entries
          if (validCount > 0) {
            const averageIBM = totalPercentage / validCount;
            playerIBM['averageIBM'] = parseFloat(averageIBM.toFixed(2)); // Store averageIBM with 2 decimal places
          }
        });
        
        
        
        player['pfaIBM'] = playerIBM;
      });

      console.log(this.pfaResponse, "Updated fitResponse with nutIBM");
    });
  }
  getPlayer() {
    this.orgId = this.OrgAdmin ? this.org : this.orgId;

    if (this.orgId) {
      this.assessmentService.getAthleteByOrg(this.orgId).subscribe(
        (athletesData) => {
          this.athletes = athletesData;
          if (this.Coach) {
            this.fitnessCount = this.athletes.filter(
              (item: any) => item.asiCoach === this.UserId
            ).length;
          } else {
            // For OrgAdmin or other users, handle count based on team selection
            if (this.selectedTeamId != 0) {
              this.fitnessCount= this.athletes.filter(
                (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
              ).length;
            } else {
              // If no team is selected, count all athletes
              this.fitnessCount = this.athletes.length;
            }
          }
        
        },
        (error) => {
          console.error('Error fetching athletes:', error);
         
        }
      );
    } else {
      console.error('Organization ID is missing.');
      this.fitnessCount = this.athletes.length; 
    }
  }
  
  orgStudents() {
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
      });
    }
  }

  sendMessage() { 
    this.apiResponse = this.playerData;
    this.msg = " give me result with their pfaAthlete,athleteName and result in both text and json format";

    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
    `;
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            this.aiData = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            this.chatData.emit(this.chat);
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          this.chatData.emit(this.chat);
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }

      this.isLoading = false;

      // Emit the chat data to the parent component

      this.athletedata.forEach((performer: any) => {
          const matchingAssessment = this.pfaResponse.find((item: any) => item.pfaAthlete === performer.pfaAthlete)
          if (matchingAssessment) {
            Object.assign(performer, matchingAssessment);
             this.getProfileImage(performer.pfaAthlete)
            this.getfitnessreport();
            this.loadFitnessTopPerformersData();
          } else {
            console.error(`No matching assessment found`);
          }
      });      this.aiData.forEach((result: any) => {
        const matchingData = this.pfaResponse.find((item: any) =>  item.pfaAthlete === result.pfaAthlete)
           if (matchingData) {
             Object.assign(result, matchingData);
           } else {
             console.error(`No matching data found`);
           }
       });
       this.chatData.emit(this.aiData ?? this.chat);

    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });
  }

  loadTopPerformersData() {
    if (this.athletedata === undefined) {
      this.Performers = this.Response;
      if (this.selectedStatus == 0) {
        this.Performers = this.Performers.sort((a: any, b: any) => b.loaAverageRange - a.loaAverageRange)
          .slice(0, 5);
        this.fitnessTableHeader = "Top";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      } else {
        this.Performers = this.Performers
          .sort((a: any, b: any) => a.loaAverageRange - b.loaAverageRange)
          .slice(0, 5);
        this.fitnessTableHeader = "Under";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      }
    } else {
      this.Performers = this.athletedata;
      this.Performers.forEach((performer: any) => {
        this.getProfileImage(performer.loaAthlete)
      });
    }
  }

  loadFitnessTopPerformersData() {
    this.fitnessTableHeader = "";
    if (this.selectedStatus == 0) {
      this.FitnessPerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      this.TopPerformer = this.FitnessPerformers.slice(0, 5);
    } else if (this.selectedStatus == 1) {
      this.fitnessTableHeader = "Excellent";
      this.FitnessPerformers = this.athleteReport
        .filter((performer: any) => performer.overallZScore >= 85)
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
    } else if (this.selectedStatus == 2) {
      this.fitnessTableHeader = "Good";
      this.FitnessPerformers = this.athleteReport
        .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85)
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
    } else if (this.selectedStatus == 3) {
      this.fitnessTableHeader = "Average";
      this.FitnessPerformers = this.athleteReport
        .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70)
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
    } else {
      this.fitnessTableHeader = "Poor";
      this.FitnessPerformers = this.athleteReport
        .filter((performer: any) => performer.overallZScore < 40)
        .sort((a: any, b: any) => a.overallZScore - b.overallZScore);
    }

    // Fetch the profile images for the filtered and sorted performers
    this.FitnessPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete);
    });
  }  

  loadStrengthTopPerformersData() {
    this.strengthTableHeader = "";
    if (this.selectedStrenghtStatus == 0) {
      this.StrengthPerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
    } else if (this.selectedStrenghtStatus == 1) {
      this.StrengthPerformers = this.athleteReport.filter((performer: any) => performer.overallStrengthZScore >= 85)
        .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
      this.strengthTableHeader = "Excellent";
    } else if (this.selectedStrenghtStatus == 2) {
      this.StrengthPerformers = this.athleteReport.filter((performer: any) => performer.overallStrengthZScore >= 70 && performer.overallStrengthZScore < 85)
        .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
      this.strengthTableHeader = "Good";
    } else if (this.selectedStrenghtStatus == 3) {
      this.StrengthPerformers = this.athleteReport.filter((performer: any) => performer.overallStrengthZScore >= 40 && performer.overallStrengthZScore < 70)
        .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
      this.strengthTableHeader = "Average";
    } else {
      this.StrengthPerformers = this.athleteReport.filter((performer: any) => performer.overallStrengthZScore < 40)
        .sort((a: any, b: any) => a.overallStrengthZScore - b.overallStrengthZScore);
      this.strengthTableHeader = "Poor";
    }

    // Fetch profile images for the performers
    this.StrengthPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }


  loadFlexibilityTopPerformersData() {
    this.flexibilityTableHeader = "";
    if (this.selectedFlexibilitytStatus == 0) {
      this.FlexibilityPerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
    } else if (this.selectedFlexibilitytStatus == 1) {
      this.FlexibilityPerformers = this.athleteReport.filter((performer: any) => performer.overallFlexibilityScore >= 85)
        .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
      this.flexibilityTableHeader = "Excellent";
    } else if (this.selectedFlexibilitytStatus == 2) {
      this.FlexibilityPerformers = this.athleteReport.filter((performer: any) => performer.overallFlexibilityScore >= 70 && performer.overallFlexibilityScore < 85)
        .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
      this.flexibilityTableHeader = "Good";
    } else if (this.selectedFlexibilitytStatus == 3) {
      this.FlexibilityPerformers = this.athleteReport.filter((performer: any) => performer.overallFlexibilityScore >= 40 && performer.overallFlexibilityScore < 70)
        .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
      this.flexibilityTableHeader = "Average";
    } else {
      this.FlexibilityPerformers = this.athleteReport.filter((performer: any) => performer.overallFlexibilityScore <= 40)
        .sort((a: any, b: any) => a.overallFlexibilityScore - b.overallFlexibilityScore);
      this.flexibilityTableHeader = "Poor";
    }

    // Fetch profile images for the performers
    this.FlexibilityPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }


  loadBalancesTopPerformersData() {
    this.balanceTableHeader = "";
    if (this.selectedBalancesStatus == 0) {
      this.BalancePerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
    } else if (this.selectedBalancesStatus == 1) {
      this.BalancePerformers = this.athleteReport.filter((performer: any) => performer.overallBalanceZScore >= 85)
        .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
      this.balanceTableHeader = "Excellent";
    } else if (this.selectedBalancesStatus == 2) {
      this.BalancePerformers = this.athleteReport.filter((performer: any) => performer.overallBalanceZScore >= 70 && performer.overallBalanceZScore < 85)
        .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
      this.balanceTableHeader = "Good";
    } else if (this.selectedBalancesStatus == 3) {
      this.BalancePerformers = this.athleteReport.filter((performer: any) => performer.overallBalanceZScore >= 40 && performer.overallBalanceZScore < 70)
        .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
      this.balanceTableHeader = "Average";
    } else {
      this.BalancePerformers = this.athleteReport.filter((performer: any) => performer.overallBalanceZScore < 40)
        .sort((a: any, b: any) => a.overallBalanceZScore - b.overallBalanceZScore);
      this.balanceTableHeader = "Poor";
    }

    // Fetch profile images for the performers
    this.BalancePerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }

  loadEnduranceTopPerformersData() {
    this.enduranceTableHeader = "";
    if (this.selectedEndurancetStatus == 0) {
      this.EndurancePerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
    } else if (this.selectedEndurancetStatus == 1) {
      this.EndurancePerformers = this.athleteReport.filter((performer: any) => performer.overallEnduranceZScore >= 85)
        .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
      this.enduranceTableHeader = "Excellent";
    } else if (this.selectedEndurancetStatus == 2) {
      this.EndurancePerformers = this.athleteReport.filter((performer: any) => performer.overallEnduranceZScore >= 70 && performer.overallEnduranceZScore < 85)
        .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
      this.enduranceTableHeader = "Good";
    } else if (this.selectedEndurancetStatus == 3) {
      this.EndurancePerformers = this.athleteReport.filter((performer: any) => performer.overallEnduranceZScore >= 40 && performer.overallEnduranceZScore < 70)
        .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
      this.enduranceTableHeader = "Average";
    } else {
      this.EndurancePerformers = this.athleteReport.filter((performer: any) => performer.overallEnduranceZScore < 40)
        .sort((a: any, b: any) => a.overallEnduranceZScore - b.overallEnduranceZScore);
      this.enduranceTableHeader = "Poor";
    }

    // Fetch profile images for the performers
    this.EndurancePerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }


  leveloneRefrences(age: number, athleteGender: any, sport: any) {
    this.assessmentService.levelonereference(age, athleteGender, sport).subscribe(response => {

      this.leveloneReference = response;

    });
  }

  calculateAge(dob: string): number {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  getColor(value: number): string {
    if (value >= 85) {
      return 'darkgreen';  
    } else if (value >= 70) {
      return 'lightgreen';
    } else if (value >= 40) {
      return 'orange';
    } else {
      return 'red';
    }
  }
 
  getLorMarkRangeForEachParameter() {
    let result: any = {}; // Object to store lorMarkRange for each parameter comparison

    for (let reference of this.leveloneReference) {
      // Height comparison
      if (this.nutritionReport.naBodyHeight < reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naBodyHeight >= reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      // Weight comparison
      if (this.nutritionReport.naWeight < reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naWeight >= reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }
    this.leveloneReport = result;
    return result;
  }

  formnavigation() {
    this.router.navigate(['/nutrition']);
  }

  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
          this.updateAllPerformers(userId, this.image);
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.showImage = true;
  
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            if (this.ImageUrl) {
              this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
              this.updateAllPerformers(userId, this.image);
            } else {
              // this.image = "assets/image/profileimg.jpg";
              // this.updateAllPerformers(userId, this.image);
            }
  
            this.cdr.detectChanges();
          },
          (error) => {
            console.error('Error fetching image:', error);
            // this.image = "assets/image/profileimg.jpg"; 
            // this.updateAllPerformers(userId, this.image);
          }
        );
      }
    );
  }
  updateAllPerformers(userId: any, image: string) {
    const updateOrAddPerformer = (performerList: any[]) => {
      const performerEntry = performerList.find((entry: { pfaAthlete: any; }) => entry.pfaAthlete === userId);
      if (performerEntry) {
        performerEntry.image = image;
      } else {
        // performerList.push({ pfaAthlete: userId, image: image });
      }
    };
  
    // Update or add performer image for all categories
    updateOrAddPerformer(this.FitnessPerformers);
    updateOrAddPerformer(this.BalancePerformers);
    updateOrAddPerformer(this.EndurancePerformers);
    updateOrAddPerformer(this.FlexibilityPerformers);
  }
  

  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }

  async selectOrganisation() {
    this.org = this.selectedOrg;
    this.orgStudents();
    this.isLoading = false;
    if (this.selectedOption === 'Physical Fitness') {
      await this.getfitnessreport();
      this.loadTopPerformersData();
      this.isLoading = false;
    }
  }

  async selectTrainer() {
    this.trainerId = this.selectedTrainer;
    if (this.selectedOption === 'Physical Fitness') {
      await this.getfitnessreport();
      this.loadTopPerformersData();
      this.isLoading = false;
    }
  }

  onTeamSelected(selectedValue: any): void {

    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;

    this.getfitnessreport().then(() => {
      this.loadFitnessTopPerformersData();
      this.loadStrengthTopPerformersData();
      this.loadFlexibilityTopPerformersData();
      this.loadBalancesTopPerformersData();
      this.loadEnduranceTopPerformersData();
    });
  }

  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;

    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);

      const a1 = 0.254829592;
      const a2 = -0.284496736;
      const a3 = 1.421413741;
      const a4 = -1.453152027;
      const a5 = 1.061405429;
      const p = 0.3275911;

      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

      return sign * y;
    };

    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }
  getFitAIData(){
    this.assessmentService.getAllFitnessForAI().subscribe(
      (response) => {
        this.playerData=response
        if(this.orgId != '0'){
          this.playerData=this.playerData.filter(
            (item: any) => item.usrOrganization === this.orgId
          );
          if (this.selectedTeamId != '0'){
            this.playerData=this.playerData.filter(
              (item: any) => item.asiCurrentClubTeam == this.selectedTeamId
            );
          }else{
            this.playerData=this.playerData
          }
        }
        console.log(this.playerData,"fitdata")
      })
  }

  async getfitnessreport(): Promise<void> {
    try {
      // Fetch data based on coach/trainer
      this.UserId = this.trainerId || this.UserId;
 
      // Fetch data based on whether the user is a coach or trainer
      this.pfaResponse = (this.Coach || this.IsAssessor)
        ? await this.assessmentService.getPhysicalFitnessDetail(this.org).toPromise()
        : (await this.assessmentService.getAllPhysicalFitnessByAssessment().toPromise())
            // Filter based on organization ID
            .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));
       
      // Additional filtering if Admin or selectedTeamId is set
      if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0') || (this.sciStaff && this.selectedTeamId != '0')) {
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      } else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
        // If not Admin or Coach, filter by assessor ID
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) => item.pfaAssessor === this.UserId
        );
       
        // Optionally filter by selected team ID
        if (this.selectedTeamId != '0') {
          this.pfaResponse = this.pfaResponse.filter(
            (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
          );
        }
      }else if (this.Coach){
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) =>item.asiCoach === this.UserId
        );
      }
      this. getPlayer();
      this.getFitAIData();
      this.pfaPerformers = this.athletedata || this.pfaResponse;
      this.athleteReport = this.pfaPerformers;

      const uniqueAthletesMap = new Map();
      this.athleteReport.forEach(item => {
          uniqueAthletesMap.set(item.pfaAthlete, item);
      });
      this.athleteReport = Array.from(uniqueAthletesMap.values());
      this.reportCount = this.athleteReport.length;


      if (this.athleteReport.length > 0) {
        this.fitnessTable= [];
        this.enduranceTable= [];
        this.strengthTable = [];
        this.flexibilityTable = [];
        this.balanceTable = [];

        const fitnessKeys = ['pfaHeight', 'pfaWeight', 'pfaBMI', 'pfaSingleLegBalanceLeftEyesOpen', 'pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaPartialCurlUp', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaSitNReach'];
        const enduranceKeys = ['pfaSpeed', 'pfaYoYoTest', 'pfaLevel', 'pfaVO2'];
        const strengthKeys = ['pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaSingleLegSquat', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaPartialCurlUp'];
        const flexibilityKeys = ['pfaSitNReach'];
        const balanceKeys = ['pfaSingleLegBalanceLeftEyesOpen', 'pfaSingleLegBalanceLeftEyesClose', 'pfaSingleLegBalanceRightEyesOpen', 'pfaSingleLegBalanceRightEyesClose'];

        const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
          const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
          const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
          return { mean, stdDev };
        };

        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => stdDev === 0 ? 0 : formatDecimalNumber(((value - mean) / stdDev),2);

        const processCategory = (keys: string[]) => {
          let categoryValues: { [key: string]: number[] } = {};
          keys.forEach(key => categoryValues[key] = []);
          this.athleteReport.forEach(report => keys.forEach(key => categoryValues[key].push(report[key])));
          let stats: { [key: string]: { mean: number, stdDev: number } } = {};
          keys.forEach(key => stats[key] = calculateStats(categoryValues[key]));
          return stats;
        };

        const allKeys = [...fitnessKeys, ...strengthKeys, ...flexibilityKeys, ...balanceKeys, ...enduranceKeys];
        // this.athleteReport = this.athleteReport.filter(report =>
        //   allKeys.every(key => typeof report[key] === 'number' && !isNaN(report[key]))
        // );

        const fitnessStats = processCategory(fitnessKeys);
        const strengthStats = processCategory(strengthKeys);
        const flexibilityStats = processCategory(flexibilityKeys);
        const balanceStats = processCategory(balanceKeys);
        const enduranceStats = processCategory(enduranceKeys);

        this.athleteReport.forEach(report => {
          let overallZScore = 0, overallStrengthZScore = 0, overallFlexibilityScore = 0, overallBalanceZScore = 0, overallEnduranceZScore = 0;

          fitnessKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], fitnessStats[key].mean, fitnessStats[key].stdDev));
            overallZScore += report[zScoreKey];
          });

          strengthKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], strengthStats[key].mean, strengthStats[key].stdDev));
            overallStrengthZScore += report[zScoreKey];
          });

          flexibilityKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], flexibilityStats[key].mean, flexibilityStats[key].stdDev));
            overallFlexibilityScore += report[zScoreKey];
          });

          balanceKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], balanceStats[key].mean, balanceStats[key].stdDev));
            overallBalanceZScore += report[zScoreKey];
          });

          enduranceKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], enduranceStats[key].mean, enduranceStats[key].stdDev));
            overallEnduranceZScore += report[zScoreKey];
          });

          report.overallZScore = overallZScore / fitnessKeys.length;
          report.overallStrengthZScore = overallStrengthZScore / strengthKeys.length;
          report.overallFlexibilityScore = overallFlexibilityScore / flexibilityKeys.length;
          report.overallBalanceZScore = overallBalanceZScore / balanceKeys.length;
          report.overallEnduranceZScore = overallEnduranceZScore / enduranceKeys.length;
        });

        function formatDecimalNumber(value: number, decimalPlaces: number): number {
          return !isNaN(value) ? Number(value.toFixed(decimalPlaces)) : 0;
        }

        const uniqueBenchmarkRequests = new Map<string, { genderId: string; sportId: string }>();

        this.athleteReport.forEach((athlete: any) => {
          // Iterate over genderId values '1' and '2'
          ['1', '2'].forEach((genderId) => {
            const key = `${genderId}-${athlete.sportId}`;
            if (!uniqueBenchmarkRequests.has(key)) {
              uniqueBenchmarkRequests.set(key, {
                genderId: genderId, // Assign genderId as '1' or '2'
                sportId: '11', // Assign sportId as '11'
              });
            }
          });
        });        

        const benchmarkPromises = [...uniqueBenchmarkRequests.values()].map(async ({ genderId, sportId }) => {
          return await this.assessmentService.getInterNationalBenchMark(genderId, sportId).toPromise();
        });

        const benchmarksArray = await Promise.all(benchmarkPromises);
        benchmarksArray.forEach((benchmarkResponse, index) => {
          const { genderId, sportId } = [...uniqueBenchmarkRequests.values()][index];
          benchmarkResponse.forEach((benchmark: any) => {
            const key = `${genderId}-${sportId}-${benchmark.testColumnName}`;
            this.benchmarksMap.set(key, benchmark.ibmRange);
          });
        });
        const enduranceZScoreKeys = ['zScorePfaSpeed', 'zScorePfaYoYoTest', 'zScorePfaLevel', 'zScorePfaVO2'];
        const strengthZScoreKeys = ['zScorePfaPushups', 'zScorePfaPullups', 'zScorePfaSquat', 'zScorePfaSingleLegSquat', 'zScorePfaGripStrengthRight', 'zScorePfaGripStrengthLeft', 'zScorePfaPartialCurlUp'];
        const flexibilityZScoreKeys = ['zScorePfaSitNReach'];
        const balanceZScoreKeys = ['zScorePfaSingleLegBalanceLeftEyesOpen', 'zScorePfaSingleLegBalanceLeftEyesClose', 'zScorePfaSingleLegBalanceRightEyesOpen', 'zScorePfaSingleLegBalanceRightEyesClose'];

       // Modified function to calculate averages and Z scores
        const populateTable = (keys: string[], zScoreKeys: string[], table: any[]) => {
          const averageValues: { [key: string]: { total: number; count: number } } = {};
          const averageZScores: { [key: string]: { total: number; count: number } } = {};

          // Calculate average values and average Z Scores
          this.athleteReport.forEach(report => {
            keys.forEach(key => {
              if (report[key] !== undefined) {
                if (!averageValues[key]) {
                  averageValues[key] = { total: 0, count: 0 };
                }
                averageValues[key].total += report[key];
                averageValues[key].count += 1;
              }
            });

            // Calculate average Z Scores for the same keys
            zScoreKeys.forEach(zScoreKey => {
              if (report[zScoreKey] !== undefined) {
                if (!averageZScores[zScoreKey]) {
                  averageZScores[zScoreKey] = { total: 0, count: 0 };
                }
                averageZScores[zScoreKey].total += report[zScoreKey];
                averageZScores[zScoreKey].count += 1;
              }
            });
          });

          const allKeys = new Set([...Object.keys(averageValues), ...Object.keys(averageZScores)]);

          // Loop through the average values and populate the table
          for (const key in averageValues) {
            const averageValue = formatDecimalNumber((averageValues[key].total / this.reportCount),2);

            // Fetch benchmarks for both male and female
            const internationalBenchmarkMale = this.benchmarksMap.get(`1-11-${key}`) || 'N/A';
            const internationalBenchmarkFemale = this.benchmarksMap.get(`2-11-${key}`) || 'N/A';

            // Handle Z Score calculation
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            const zScoreData = averageZScores[zScoreKey];
            const averageZScore = zScoreData
              ? formatDecimalNumber((zScoreData.total / this.reportCount),2)
              : 'N/A'; // Handle missing Z Scores

            table.push({
              testName: key.replace(/^pfa/, ''), // Remove 'pfa' prefix from testName
              internationalBenchmarkMale,
              internationalBenchmarkFemale,
              averageValue,
              averageZScore, // New Z Score column
            });
          }
        };

        // Populate each category's table with updated logic
        populateTable(enduranceKeys, enduranceZScoreKeys, this.enduranceTable);
        populateTable(strengthKeys, strengthZScoreKeys, this.strengthTable);
        populateTable(flexibilityKeys, flexibilityZScoreKeys, this.flexibilityTable);
        populateTable(balanceKeys, balanceZScoreKeys, this.balanceTable);
        

        // Check if there are valid male/female benchmarks for each table
        this.shouldShowFitnessMaleColumn = this.fitnessTable.some(row => row.internationalBenchmarkMale !== 'N/A');
        this.shouldShowFitnessFemaleColumn = this.fitnessTable.some(row => row.internationalBenchmarkFemale !== 'N/A');

        this.shouldShowEnduranceMaleColumn = this.enduranceTable.some(row => row.internationalBenchmarkMale !== 'N/A');
        this.shouldShowEnduranceFemaleColumn = this.enduranceTable.some(row => row.internationalBenchmarkFemale !== 'N/A');

        this.shouldShowStrengthMaleColumn = this.strengthTable.some(row => row.internationalBenchmarkMale !== 'N/A');
        this.shouldShowStrengthFemaleColumn = this.strengthTable.some(row => row.internationalBenchmarkFemale !== 'N/A');

        this.shouldShowFlexibilityMaleColumn = this.flexibilityTable.some(row => row.internationalBenchmarkMale !== 'N/A');
        this.shouldShowFlexibilityFemaleColumn = this.flexibilityTable.some(row => row.internationalBenchmarkFemale !== 'N/A');

        this.shouldShowBalanceMaleColumn = this.balanceTable.some(row => row.internationalBenchmarkMale !== 'N/A');
        this.shouldShowBalanceFemaleColumn = this.balanceTable.some(row => row.internationalBenchmarkFemale !== 'N/A');

        // Final calculations for averages and percentages
        const overallZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.athleteReport.length;
        this.averageOverallPercentage = formatDecimalNumber((averageOverallZScore),2);
        this.fitnessOverallPercentage = this.averageOverallPercentage;

        const overallStrengthZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallStrengthZScore, 0);
        const averageOverallStrengthZScore = overallStrengthZScoreSum / this.athleteReport.length;
        this.strengthOverallPercentage = Math.min(Math.max(((averageOverallStrengthZScore + 3) / 6) * 100, 0), 100);

        const overallFlexibilityZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallFlexibilityScore, 0);
        const averageOverallFlexibilityZScore = overallFlexibilityZScoreSum / this.athleteReport.length;
        this.flexibilityOverallPercentage = Math.min(Math.max(((averageOverallFlexibilityZScore + 3) / 6) * 100, 0), 100);

        const overallBalanceZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallBalanceZScore, 0);
        const averageOverallBalanceZScore = overallBalanceZScoreSum / this.athleteReport.length;
        this.balanceOverallPercentage = Math.min(Math.max(((averageOverallBalanceZScore + 3) / 6) * 100, 0), 100);

        const overallEnduranceZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallEnduranceZScore, 0);
        const averageOverallEnduranceZScore = overallEnduranceZScoreSum / this.athleteReport.length;
        this.enduranceOverallPercentage = Math.min(Math.max(((averageOverallEnduranceZScore + 3) / 6) * 100, 0), 100);

        this.filterPerformers('all', 'overall');
        this.filterPerformers('all', 'strength');
        this.filterPerformers('all', 'flexibility');
        this.filterPerformers('all', 'balance');
        this.filterPerformers('all', 'endurance');

      } else {
        console.error('No valid data available for Z-score calculation.');
      }
    } catch (error) {
      console.error('Error fetching fitness report:', error);
    }
  }

  private async generateChart(
    keys: string[], 
    bindto: string, 
    performerLabels: string[], 
    filteredReport: any[] // Accept filteredReport as an argument
  ): Promise<void> {
  
    const chartData: [string, ...number[]][] = keys.map(key => {
      const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
  
      const zScores = filteredReport.map(report => report[zScoreKey]); // Use filteredReport
  
      if (zScores.length === 0 || zScores.some(zScore => zScore === undefined)) {
        console.warn(`No valid zScores for ${key}, skipping this data set.`);
        return [key.replace('pfa', '').replace(/([A-Z])/g, ' $1')];
      }
  
      return [
        `${key.replace('pfa', '').replace(/([A-Z])/g, ' $1')}`,
        ...zScores
      ];
    });
  
    const filteredChartData = chartData.filter(data => data.length > 1);
  
    const c3 = (await import('c3')).default;
  
    // Generate an empty chart if there's no valid data
    if (filteredChartData.length === 0) {
      c3.generate({
        bindto,
        data: {
          columns: [['No Data', 0]], // Placeholder data to show an empty chart
          types: {
            'No Data': 'area-spline'
          }
        },
        axis: {
          x: { 
            type: 'category', 
            categories: ['N/A'], // Placeholder x-axis label
            tick: { rotate: 90, multiline: false }, 
            height: 100 
          },
          y: { label: 'Z-Score' }
        },
        tooltip: {
          contents: () => '<div class="c3-tooltip">No data available</div>' // Tooltip for empty data
        }
      });
    } else {
      // If valid data exists, generate the chart as usual
      c3.generate({
        bindto,
        data: {
          columns: filteredChartData,
          types: filteredChartData.reduce((acc, [label]) => ({ ...acc, [label]: 'area-spline' }), {})
        },
        axis: {
          x: { type: 'category', categories: performerLabels, tick: { rotate: 90, multiline: false }, height: 100 },
          y: { label: 'Z-Score' }
        },
        tooltip: {
          contents: (data) => {
            let html = '<table class="c3-tooltip">';
            html += '<thead><tr><th>Metric</th><th>Z-Score</th><th>Actual Value</th><th>IBM</th></tr></thead>';
            html += '<tbody>';
  
            data.forEach(item => {
              const key = item.id;
              const zScoreValue = item.value;
              const baseKey = key.replace(' Z-Score', '').trim();
  
              const performer = filteredReport[item.index]; // Use filteredReport
  
              const actualKey = `pfa${baseKey.replace(/ /g, '')}`;
              const actualValue = performer[actualKey];
  
              // TODO sportsId should be dynamic
              const benchmarkKey = `${performer.genderId}-11-${actualKey}`;
              const benchmark = this.benchmarksMap.get(benchmarkKey);
  
              html += '<tr>';
              html += `<td>${baseKey}</td>`;
              html += `<td>${zScoreValue}</td>`;
              html += `<td>${actualValue || 'N/A'}</td>`;
              html += `<td>${benchmark}</td>`;
              html += '</tr>';
            });
  
            html += '</tbody></table>';
            return html;
          }
        }
      });
    }
  }
  


  filterPerformers(filterType: string, chart: string): void {
    let filteredReport: any[] = []; // Initialize filteredReport to an empty array
    
    type FilterType = 'Excellent' | 'Good' | 'Average' | 'Poor' | 'all';
  
    // Common filter and sorting function for all chart types
    const filterAndSortReport = (scoreKey: string, filterType: FilterType) => {
      let scoreThresholds: Record<FilterType, { min: number; max: number; header: string }> = {
        Excellent: { min: 85, max: Infinity, header: 'Excellent Performers' },
        Good: { min: 70, max: 85, header: 'Good Performers' },
        Average: { min: 40, max: 70, header: 'Average Performers' },
        Poor: { min: -Infinity, max: 40, header: 'Poor Performers' },
        all: { min: -Infinity, max: Infinity, header: 'All Performers' }
      };
  
      let { min, max, header } = scoreThresholds[filterType as FilterType];
  
      // Filter and sort the data based on the given scoreKey
      const filtered = this.athleteReport
        .filter((performer: any) => performer[scoreKey] > min && performer[scoreKey] <= max) // Ensure correct filtering
        .sort((a: any, b: any) => b[scoreKey] - a[scoreKey]) // Sort in descending order
        .slice(0, 10); // Get top 10 performers
  
      // Return the filtered report and header
      return {
        filteredReport: filtered,
        header
      };
    };
  
    // Reset filteredReport at the beginning to avoid stale data
    filteredReport = [];
  
    // Determine which chart type to process
    switch (chart) {
      case 'overall':
        this.athleteReport.sort((a, b) => b.overallZScore - a.overallZScore);
        const { filteredReport: overallReport, header: overallHeader } = filterAndSortReport('overallZScore', filterType as FilterType);
        filteredReport = overallReport;
        this.fitnessHeader = `Overall ${overallHeader}`;
        break;
  
      case 'strength':
        this.athleteReport.sort((a, b) => b.overallStrengthZScore - a.overallStrengthZScore);
        const { filteredReport: strengthReport, header: strengthHeader } = filterAndSortReport('overallStrengthZScore', filterType as FilterType);
        filteredReport = strengthReport;
        this.strengthHeader = `Top Strength ${strengthHeader}`;
        break;
  
      case 'flexibility':
        this.athleteReport.sort((a, b) => b.overallFlexibilityScore - a.overallFlexibilityScore);
        const { filteredReport: flexibilityReport, header: flexibilityHeader } = filterAndSortReport('overallFlexibilityScore', filterType as FilterType);
        filteredReport = flexibilityReport;
        this.flexibilityHeader = `Top Flexibility ${flexibilityHeader}`;
        break;
  
      case 'balance':
        this.athleteReport.sort((a, b) => b.overallBalanceZScore - a.overallBalanceZScore);
        const { filteredReport: balanceReport, header: balanceHeader } = filterAndSortReport('overallBalanceZScore', filterType as FilterType);
        filteredReport = balanceReport;
        this.balanceHeader = `Top Balance ${balanceHeader}`;
        break;
  
      case 'endurance':
        this.athleteReport.sort((a, b) => b.overallEnduranceZScore - a.overallEnduranceZScore);
        const { filteredReport: enduranceReport, header: enduranceHeader } = filterAndSortReport('overallEnduranceZScore', filterType as FilterType);
        filteredReport = enduranceReport;
        this.enduranceHeader = `Top Endurance ${enduranceHeader}`;
        break;
  
      default:
        break;
    }
  
    // If there are no results after filtering, clear the chart data
    if (filteredReport.length === 0) {
      console.warn(`No valid data found for filter type: ${filterType}`);
    }
  
    const fitnessKeys = ['pfaHeight', 'pfaWeight', 'pfaBMI', 'pfaSingleLegBalanceLeftEyesOpen', 'pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaPartialCurlUp', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaSitNReach'];
    const enduranceKeys = ['pfaSpeed', 'pfaYoYoTest', 'pfaLevel', 'pfaVO2'];
    const strengthKeys = ['pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaSingleLegSquat', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaPartialCurlUp'];
    const flexibilityKeys = ['pfaSitNReach'];
    const balanceKeys = ['pfaSingleLegBalanceLeftEyesOpen', 'pfaSingleLegBalanceLeftEyesClose', 'pfaSingleLegBalanceRightEyesOpen', 'pfaSingleLegBalanceRightEyesClose'];
    this.cdRef.detectChanges();
    let performerLabels: string[] = [];
    if (this.Admin || this.OrgAdmin) {
        performerLabels = filteredReport.map(report => report.usrFullName);
    }else if( this.Coach){
      performerLabels = filteredReport.map(report => report.athleteName);
    } 
    else {
        performerLabels = filteredReport.map(report => report.pfaAthlete);
    }
    if (chart === 'overall') {
      this.generateChart(fitnessKeys, '#new-chart', performerLabels, filteredReport); // Pass filteredReport
    } else if (chart === 'strength') {
      this.generateChart(strengthKeys, '#strength-chart', performerLabels, filteredReport); // Pass filteredReport
    } else if (chart === 'endurance') {
      this.generateChart(enduranceKeys, '#endurance-chart', performerLabels, filteredReport); // Pass filteredReport
    } else if (chart === 'flexibility') {
      this.generateChart(flexibilityKeys, '#flexibility-chart', performerLabels, filteredReport); // Pass filteredReport
    } else if (chart === 'balance') {
      this.generateChart(balanceKeys, '#balance-chart', performerLabels, filteredReport); // Pass filteredReport
    }
  }
  
  selectRole(role: string, roleId: any) {
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: {  Org:this.org, status:'True' } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }

}
