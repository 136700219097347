<div *ngIf="isLoading" class="loading-spinner">
    <div class="spinner"></div>
  </div>
<div id="scrollContainer" #scrollContainer style="height: 100vh; overflow-y: auto;">
<div class="body-wrapper">
    <div class="d-flex justify-content-between mb-3">
        <!-- <div>
    <select class="form-select" [(ngModel)]="selectedMonth" (change)="filterReportByMonth()">
        <option value="" disabled>Filter Reports</option>
        <option *ngFor="let month of monthList">{{ month }}</option>
      </select>
    </div> -->
        <div>
            <button class="btn btn-primary" (click)="onBack()">Back</button>
        </div>
       
        <div class="d-flex gap-2">
            <div *ngIf="IsAdmin || IsScience">
                <button type="button" (click)="openModal()" class="btn btn-primary">Update Summary</button>
            </div>
            <div>
                <button type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
            </div>
            
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <div class="select-wrapper">
            <ng-select 
                class="ng-select-custom" 
                [items]="statusOptions" 
                [searchable]="false" 
                [clearable]="false" 
                [(ngModel)]="selectedStatus" 
                bindLabel="label" 
                bindValue="value" 
                (change)="calculationMethod()">
                
                <!-- Custom label template -->
                <ng-template ng-label-tmp let-item="item">
                    <span>{{ item.label }}</span>
                    <i class="fa fa-chevron-down ml-2"></i>
                </ng-template>
                
                <!-- Optional custom dropdown item template -->
                <ng-template ng-option-tmp let-item="item">
                    <span>{{ item.label }}</span>
                </ng-template>
            </ng-select>
        </div>
        
    </div>
   
        <div class="contain" id="pdf-content">
            <div class="border-cont">
                <header class="section">
                    <div class="headerSection">

                        <div class="logoAndName d-flex justify-content-between align-items-center">
                            <div class="image">
                                <img src="{{orgimage}}" alt="image">
                            </div>
                            <h1 style="font-size: 33px;">AMS Report Card</h1>
                            <div class="logo-image">
                                <img src="/assets/image/new_id.jpg" alt="image">
                            </div>
                        </div>

                    </div>

                </header>
                <main>
                    <div class="container-fluid">
                        <div class="section">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="row d-flex h-100">
                                    <div class="col-xl-8 d-flex p-0">
 
 
                                        <div class="card1 flex-grow-1">
                                            <div class="card-body">
                                                <div class="row d-flex align-items-center justify-content-center">
                                                    <div class="col-xl-4 col-sm-6 d-flex">
                                                        <div class="diagram ">
                                                            <img *ngIf="showImage && image" src="{{image}}">
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-8 col-sm-6">
                                                        <div class="items ">
                                                            <div class="col-xl-12 mb-2">
 
                                                                <h4>{{ this.userDetail?.[0]?.usrFullName }}</h4>
 
 
                                                                <h5 class="customemail">{{this.userDetail?.[0]?.usrEmail}}
                                                                </h5>
                                                            </div>
                                                            <div class="d-flex align-items-start gap-2">
                                                                <div class="col-xl-6">
                                                                    <!-- <div class="mb-3">
                                                                    <h3>Email: <span>
                                                                        {{this.userDetail[0]?.usrEmail}}
                                                                        </span></h3>
                                                                </div> -->
                                                                    <div class="mb-3">
                                                                        <h3>Age: <span>
                                                                                {{this.age}}
                                                                            </span></h3>
 
 
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h3>Gender: <span
                                                                                *ngIf="this.userDetail[0]?.usrGender == 1">Male</span>
                                                                            <span
                                                                                *ngIf="this.userDetail[0]?.usrGender == 2">Female</span>
                                                                            <span
                                                                                *ngIf="this.userDetail[0]?.usrGender != 1 && this.userDetail[0].usrGender != 2">Other</span>
                                                                        </h3>
 
 
                                                                    </div>
 
                                                                    <div class="mb-3">
                                                                        <h3>Height:
                                                                            <span>{{this.userDetail[0]?.usrHeight
                                                                                ??this.nutrition?.naBodyHeight}}</span>
                                                                        </h3>
 
 
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h3>Weight:
                                                                            <span>{{this.userDetail[0]?.usrWeight ??
                                                                                this.nutrition?.naBodyWeight}}</span>
                                                                        </h3>
 
 
                                                                    </div>
 
                                                                </div>
                                                                <div class="col-xl-6">
                                                                    <div class="mb-3">
                                                                        <h3>Sport: <span>
                                                                                {{this.userDetail[0]?.asiPrimarySport}}
                                                                            </span></h3>
 
                                                                    </div>
 
                                                                    <div class="mb-3">
                                                                        <h3>Phone:
                                                                            <span>
                                                                                {{this.userDetail[0]?.usrPhoneNo}}
                                                                            </span>
                                                                        </h3>
 
                                                                    </div>
 
                                                                    <div class="mb-3">
                                                                        <h3>Events: <span>
                                                                                {{this.userDetail[0]?.asiEvent}}
                                                                            </span></h3>
 
                                                                    </div>
 
                                                                    <div class="mb-3">
                                                                        <h3>Team Name:
                                                                            <span>{{this.allTeams?.[0]?.teamName}}</span>
                                                                        </h3>
 
 
                                                                    </div>
                                                                </div>
                                                            </div>
 
                                                            <div class="col-xl-12">
                                                                <div class="mb-3">
                                                                    <h3>Area: <span>
                                                                            {{this.userDetail[0]?.usrAddress}}
                                                                        </span></h3>
 
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-12">
                                                                <div class="mb-3">
                                                                    <h3>Coach:
                                                                        <span>{{this.coachName?.[0]?.usrFullName}}</span>
                                                                    </h3>
 
 
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-12">
                                                        <div class="mb-3">
                                                            <h3>Team Name:
                                                                 <span>{{this.allTeams[0]?.teamName}}</span>
                                                            </h3>
 
 
                                                        </div>
                                                        </div> -->
 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
 
                                    </div>
                                    <div class="col-xl-4 d-flex flex-column p-0">
                                        <div class="card2 flex-grow-1">
                                            <div class="card-body">
                                                <div
                                                    class="items d-flex flex-column justify-content-between align-items-center">
                                                    <span class="fs-14 font-w400"
                                                        *ngIf="this.summarydetails">{{this.summarydetails[0]?.ausRemark}}</span>
                                                    <div class="text-center">
                                                        <h2 class="fs-18 font-w700 mb-0">{{calculateOverallAverage()}}%
                                                        </h2>
                                                        <p>Overall Score</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
 
 
                                    </div>
 
                                </div>
                            </div>
                        </div>
                        </div>
                        <!-- Physical Fitness and Performance Analysis -->

                        <div class="row mb-2">
                           
                            <div class="col-xl-12 ">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom ">
                                                    <div class="header-cont mb-5 section" *ngIf="this.cardio?.coaYoYoTest || this.fitness?.pfaLevel || this.fitness?.pfaVO2 || this.fitness?.pfaSpeed || 
                                                    this.fitness?.pfaTTest ||this.fitness?.pfaPushups || this.fitness?.pfaSquat || this.fitness?.pfaPartialCurlUp || this.fitness?.pfaGripStrengthRight ||
                                                     this.fitness?.pfaGripStrengthLeft || this.fitness?.pfaAppleysScratchTest || this.fitness?.pfaSingleLegBalanceRightEyesOpen || 
                                                    this.fitness?.pfaSingleLegBalanceLeftEyesOpen || this.fitness?.pfaSingleLegBalanceRightEyesClose || this.fitness?.pfaSingleLegBalanceLeftEyesClose">
                                                        <h2>Physical Fitness and Performance Analysis</h2>
                                                    </div>
                                                    <div class="row px-4 section">
                                                        <div
                                                            class="bench-tag d-flex justify-content-end align-items-center">
                                                            <img src="/assets/newimages/bench-mark.png" alt="IBM"
                                                                style="width: 25px; height: 25px;" />
                                                            <h5> IBM :
                                                                <span>
                                                                    International BenchMark

                                                                </span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="row px-4 section">
                                                        <div *ngIf="this.cardio?.coaYoYoTest || this.fitness?.pfaLevel || this.fitness?.pfaVO2 || this.fitness?.pfaSpeed">
                                                            <div class="main-title section">
                                                                <h4>Endurance</h4>
                                                            </div>
                                                            <div class="subtitle mb-2" *ngIf="this.cardio?.coaYoYoTest || this.fitness?.pfaLevel || this.fitness?.pfaVO2">
                                                                <h6>Yo-Yo Test

                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 d-flex equal-height p-0 mb-5" *ngIf="this.cardio?.coaYoYoTest || this.fitness?.pfaLevel || this.fitness?.pfaVO2">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngIf="this.cardio?.coaYoYoTest">
                                                                                <td>
                                                                                    Distance Covered (m)
                                                                                    <p class="desc">IBM : {{
                                                                                        getBenchMarkRange('coaYoYoTest')
                                                                                        }}</p>
                                                                                    <!-- <img class="table-img"
                                                                                src="/assets/newimages/med-ball.jpg"> -->
                                                                                </td>
                                                                                <td>{{ this.cardio?.coaYoYoTest ?? 0 }}
                                                                                </td>
                                                                                <td>{{this.fitnessDataIBMObject?.IBM?.coaYoYoTest ??this.cardio?.coaYoYoTest ?? 0}} %
                                                                                </td>
                                                                                <td [ngClass]="(
                                                                                    this.fitnessDataIBMObject?.IBM?.coaYoYoTest ??this.cardio?.coaYoYoTest) >= 85 ? 'dark-green' :
                                                                            (this.fitnessDataIBMObject?.IBM?.coaYoYoTest ??this.cardio?.coaYoYoTest) >= 70 ? 'light-green' :
                                                                            (this.fitnessDataIBMObject?.IBM?.coaYoYoTest ??this.cardio?.coaYoYoTest) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                    {{getScoreLabel(this.fitnessDataIBMObject?.IBM?.coaYoYoTest ?? this.cardio?.coaYoYoTest ?? 0)}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.fitness?.pfaLevel">
                                                                                <td>Level
                                                                                    <p class="desc">IBM : {{
                                                                                        getBenchMarkRange('pfaLevel') }}
                                                                                    </p>
                                                                                </td>
                                                                                <td>{{this.fitness?.pfaLevel ?? 0
                                                                                    }}</td>
                                                                                <td>{{this.fitnessDataIBMObject?.IBM?.pfaLevel ?? this.fitness?.pfaLevel ?? 0
                                                                                    }} %</td>
                                                                                <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.pfaLevel ?? this.fitness?.pfaLevel ) >= 85 ? 'dark-green' :
                                                                            ( this.fitnessDataIBMObject?.IBM?.pfaLevel ?? this.fitness?.pfaLevel ) >= 70 ? 'light-green' :
                                                                            ( this.fitnessDataIBMObject?.IBM?.pfaLevel ?? this.fitness?.pfaLevel ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                    {{getLevelScoreLabel(this.fitnessDataIBMObject?.IBM?.pfaLevel ?? this.fitness?.pfaLevel
                                                                                    ?? 0)}}</td>
                                                                            </tr>
                                                                            <tr *ngIf="this.fitness?.pfaVO2">
                                                                                <td>
                                                                                    VO2 Max (ml/kg/min)
                                                                                    <p class="desc">IBM : {{
                                                                                        getBenchMarkRange('pfaVO2') }}
                                                                                    </p>
                                                                                    <!-- <img class="table-img"
                                                                                src="/assets/newimages/med-ball.jpg"> -->
                                                                                </td>

                                                                                <td>{{ this.fitness?.pfaVO2 ?? 0}}</td>
                                                                                <td>{{this.fitnessDataIBMObject?.IBM?.pfaVO2 ?? this.fitness?.pfaVO2 ?? 0}} %
                                                                                </td>
                                                                                <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.pfaVO2 ??this.fitness?.pfaVO2) >= 85 ? 'dark-green' :
                                                                            (this.fitnessDataIBMObject?.IBM?.pfaVO2 ??this.fitness?.pfaVO2) >= 70 ? 'light-green' :
                                                                             (this.fitnessDataIBMObject?.IBM?.pfaVO2 ??this.fitness?.pfaVO2) >= 40 ? 'yellow' :'red'"
                                                                                    class="rating">
                                                                                    {{getScoreLabel(this.fitnessDataIBMObject?.IBM?.pfaVO2 ??this.fitness?.pfaVO2
                                                                                    ?? 0)}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0" *ngIf="this.fitness?.pfaVO2">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle"
                                                                            [ngClass]="(this.fitness?.pfaVO2) >= 85 ? 'dark-green' :
                                                                            (this.fitness?.pfaVO2) >= 70 ? 'light-green' :
                                                                             (this.fitness?.pfaVO2) >= 40 ? 'yellow' :'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{this.fitnessDataIBMObject?.IBM?.pfaVO2 ?? this.fitness?.pfaVO2 ?? 0}}%</h2>
                                                                            </div>

                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="section1" class="mb-5" *ngIf="this.fitness?.pfaSpeed">
                                                            <div class="subtitle mb-2">
                                                                <h6>speed

                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 d-flex equal-height p-0 mb-4">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            <tr>
                                                                                <td>
                                                                                    Speed
                                                                                    <p class="desc">IBM : {{
                                                                                        getBenchMarkRange('pfaSpeed') }}
                                                                                    </p>
                                                                                </td>

                                                                                <td>{{this.fitness?.pfaSpeed ?? 0}}</td>
                                                                                <td>{{ this.percentages.pfaSpeed ??
                                                                                    this.fitness?.pfaSpeed ?? 0}} %</td>
                                                                                <td [ngClass]="(this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                    {{getScoreLabel(this.percentages.pfaSpeed
                                                                                    ??
                                                                                    this.fitness?.pfaSpeed ?? 0)}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle" [ngClass]="(calculateSpeedAverage()) >= 85 ? 'dark-green' :
                                                                                        (calculateSpeedAverage()) >= 70 ? 'light-green' :
                                                                                        (calculateSpeedAverage()) >= 40 ? 'yellow' :
                                                                                        'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{calculateSpeedAverage()}}%</h2>
                                                                            </div>
                                                                            <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausEndurance}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                        <div class="mb-5" id="section2" *ngIf="this.fitness?.pfaTTest">

                                                            <div class="main-title">
                                                                <h4>Agility</h4>
                                                            </div>
                                                            <div class="subtitle mb-2">
                                                                <h6>T-test

                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 d-flex equal-height p-0">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            <tr>
                                                                                <td>
                                                                                    T-test (sec)
                                                                                    <p class="desc">IBM : {{
                                                                                        getBenchMarkRange('pfaTTest') }}
                                                                                    </p>
                                                                                </td>

                                                                                <td>{{this.fitness?.pfaTTest ?? 0}}</td>
                                                                                <td>{{ this.percentages.pfaTTest ??
                                                                                    this.fitness?.pfaTTest ?? 0}} %</td>
                                                                                <td [ngClass]="(this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                    {{getScoreLabel(this.percentages.pfaTTest
                                                                                    ??
                                                                                    this.fitness?.pfaTTest ?? 0)}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle" [ngClass]="(calculateTTestAverage()) >= 85 ? 'dark-green' :
                                                                                        (calculateTTestAverage()) >= 70 ? 'light-green' :
                                                                                        (calculateTTestAverage()) >= 40 ? 'yellow' :
                                                                                        'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{calculateTTestAverage()}}%</h2>
                                                                            </div>
                                                                            <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="remarks-container p-2">
                                                                <div class="subtitle mb-2">
                                                                    <h6>Remarks</h6>
                                                                </div>
                                                                <div class="row col-md-12 Summary">
                                                                    <span>
                                                                        {{this.summarydetails?.[0]?.ausAgility}}
                                                                    </span>
                                                                 
                                                                  </div>
                                                                </div>
                                                        </div>
                                                      

                                                    </div>
                                                  <div class="row px-4">
                                                    <div class="mb-5" *ngIf="this.fitness?.pfaPushups || this.fitness?.pfaSquat || this.fitness?.pfaPartialCurlUp || this.fitness?.pfaGripStrengthRight || this.fitness?.pfaGripStrengthLeft || this.fitness?.pfaAppleysScratchTest || this.fitness?.pfaSingleLegBalanceRightEyesOpen || this.fitness?.pfaSingleLegBalanceLeftEyesOpen || this.fitness?.pfaSingleLegBalanceRightEyesClose || this.fitness?.pfaSingleLegBalanceLeftEyesClose">
                                                        <div class="main-title section">
                                                            <h4>Strength</h4>
                                                        </div>
                                                        <div class="section">
                                                        <div class="subtitle mb-2" *ngIf="this.fitness?.pfaPushups">
                                                            <h6>Upper Body

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-4 d-flex equal-height p-0" *ngIf="this.fitness?.pfaPushups">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Push Ups (min)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaPushups')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/push.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{ this.fitness?.pfaPushups ?? 0 }}
                                                                            </td>
                                                                            <td>{{this.percentages.pfaPushups ??
                                                                                this.fitness?.pfaPushups ?? 0}} %
                                                                            </td>
                                                                            <td [ngClass]="(this.percentages.pfaPushups ?? this.fitness?.pfaPushups) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaPushups ?? this.fitness?.pfaPushups) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaPushups ?? this.fitness?.pfaPushups) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaPushups
                                                                                ??
                                                                                this.fitness?.pfaPushups ?? 0)}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(calculatePushUpsAverage()) >= 85 ? 'dark-green' :
                                                                        (calculatePushUpsAverage()) >= 70 ? 'light-green' :
                                                                        (calculatePushUpsAverage()) >= 40 ? 'yellow' :
                                                                        'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculatePushUpsAverage()}}%</h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                    </div>
                                                    <div class="section">
                                                        <div class="subtitle mb-2 " *ngIf="this.fitness?.pfaSquat">
                                                            <h6>Lower Body

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-4 d-flex equal-height p-0" *ngIf="this.fitness?.pfaSquat">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Squats (min)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaSquat')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/newimages/squat.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{ this.fitness?.pfaSquat ?? 0
                                                                                }}</td>
                                                                            <td>{{ this.percentages.pfaSquat ??
                                                                                this.fitness?.pfaSquat ?? 0}} %</td>
                                                                            <td [ngClass]="( this.percentages.pfaSquat ?? 
                                                                    this.fitness?.pfaSquat) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaSquat ?? 
                                                                        this.fitness?.pfaSquat) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaSquat ?? 
                                                                        this.fitness?.pfaSquat) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSquat
                                                                                ??
                                                                                this.fitness?.pfaSquat ?? 0)}}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="( calculateFitnessAverages()) >= 85 ? 'dark-green' :
                                                            (calculateFitnessAverages()) >= 70 ? 'light-green' :
                                                            (calculateFitnessAverages()) >= 40 ? 'yellow' :
                                                            'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateFitnessAverages() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                    </div>
                                                    <div class="section">

                                                        <div class="subtitle mb-2" *ngIf="this.fitness?.pfaPartialCurlUp">
                                                            <h6>abdominal/Core strength

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-4 d-flex equal-height p-0" *ngIf="this.fitness?.pfaPartialCurlUp">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Partial Curl Ups (min)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaPartialCurlUp')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/newimages/curl.jpg">
                                                                                </div>
                                                                            </td>

                                                                            <td>{{this.fitness?.pfaPartialCurlUp ??
                                                                                0}}</td>
                                                                            <td>{{this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??this.polynomialDataObject?.IBM?.pfaPartialCurlUp ??this.fitness?.pfaPartialCurlUp ??
                                                                                0}} %
                                                                            </td>
                                                                            <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??this.polynomialDataObject?.IBM?.pfaPartialCurlUp?? this.fitness?.pfaPartialCurlUp) >= 85 ? 'dark-green' :
                                                                        ( this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??this.polynomialDataObject?.IBM?.pfaPartialCurlUp?? this.fitness?.pfaPartialCurlUp) >= 70 ? 'light-green' :
                                                                        (this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??this.polynomialDataObject?.IBM?.pfaPartialCurlUp?? this.fitness?.pfaPartialCurlUp) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??this.polynomialDataObject?.IBM?.pfaPartialCurlUp?? this.fitness?.pfaPartialCurlUp
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="( calculateCurlUpAverages()) >= 85 ? 'dark-green' :
                                                            (calculateCurlUpAverages()) >= 70 ? 'light-green' :
                                                            ( calculateCurlUpAverages()) >= 40 ? 'yellow' :
                                                            'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{this.fitnessDataIBMObject?.IBM?.pfaPartialCurlUp ??calculateCurlUpAverages() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                        <div class="subtitle mb-2 section" *ngIf="this.fitness?.pfaGripStrengthRight || this.fitness?.pfaGripStrengthLeft">
                                                            <h6>Grip Strength

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 d-flex equal-height p-0 section" *ngIf="this.fitness?.pfaGripStrengthRight || this.fitness?.pfaGripStrengthLeft">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngIf="this.fitness?.pfaGripStrengthRight">
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Right Grip (kgs)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaGripStrengthRight')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/grip.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{this.fitness?.pfaGripStrengthRight
                                                                                ?? 0}}
                                                                            </td>
                                                                            <td>{{this.percentages.pfaGripStrengthRight
                                                                                ??
                                                                                this.fitness?.pfaGripStrengthRight
                                                                                ?? 0}}
                                                                                %</td>
                                                                            <td [ngClass]="(this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaGripStrengthRight
                                                                                ??
                                                                                this.fitness?.pfaGripStrengthRight
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr *ngIf="this.fitness?.pfaGripStrengthLeft">
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Left Grip (kgs)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaGripStrengthLeft')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/grip.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{
                                                                                this.fitness?.pfaGripStrengthLeft ??
                                                                                0
                                                                                }}</td>
                                                                            <td>{{
                                                                                this.percentages.pfaGripStrengthLeft
                                                                                ??
                                                                                this.fitness?.pfaGripStrengthLeft ??
                                                                                0}} %
                                                                            </td>
                                                                            <td [ngClass]="(this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaGripStrengthLeft
                                                                                ?? this.fitness?.pfaGripStrengthLeft
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(calculateGripStrengthAverages()) >= 85 ? 'dark-green' :
                                                                       (calculateGripStrengthAverages()) >= 70 ? 'light-green' :
                                                                       (calculateGripStrengthAverages()) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{(calculateGripStrengthAverages())}}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="remarks-container p-2">
                                                        <div class="subtitle mb-2">
                                                            <h6>Remarks</h6>
                                                        </div>
                                                        <div class="row col-md-12 Summary">
                                                            <span>
                                                                {{this.summarydetails?.[0]?.ausStrength}}
                                                            </span>
                                                         
                                                          </div>
                                                        </div>
                                                    <div class="mb-5" *ngIf="this.fitness?.pfaAppleysScratchTest || this.fitness?.pfaSitNReach">
                                                        <div class="main-title section">
                                                            <h4>Flexibility</h4>
                                                        </div>
                                                        <div class="subtitle mb-2 section" *ngIf="this.fitness?.pfaAppleysScratchTest">
                                                            <h6>Upper Body

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-4 d-flex equal-height p-0 section" *ngIf="this.fitness?.pfaAppleysScratchTest">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Apley's Scratch Test (cm)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaAppleysScratchTest')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/apley.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{
                                                                                this.fitness?.pfaAppleysScratchTest
                                                                                ?? 0}}</td>
                                                                            <td>{{this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                                this.fitness?.pfaAppleysScratchTest
                                                                                ?? 0}} %</td>
                                                                            <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??this.fitness?.pfaAppleysScratchTest ) >= 85 ? 'dark-green' :
                                                                        (this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??this.fitness?.pfaAppleysScratchTest ) >= 70 ? 'light-green' :
                                                                        (this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??this.fitness?.pfaAppleysScratchTest ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">{{getScoreLabel(this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                                this.fitness?.pfaAppleysScratchTest
                                                                                ?? 0)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="( this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                this.fitness?.pfaAppleysScratchTest
                                                                ?? 0) >= 85 ? 'dark-green' :
                                                                   ( this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                    this.fitness?.pfaAppleysScratchTest
                                                                    ?? 0) >= 70 ? 'light-green' :
                                                                   ( this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                    this.fitness?.pfaAppleysScratchTest
                                                                    ?? 0) >= 40 ? 'yellow' :
                                                                   'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{this.fitnessDataIBMObject?.IBM?.pfaAppleysScratchTest ??
                                                                                this.fitness?.pfaAppleysScratchTest
                                                                                ?? 0 }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="subtitle mb-2 section" *ngIf="this.fitness?.pfaSitNReach">
                                                            <h6>Lower Body

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12  d-flex equal-height p-0 section" *ngIf="this.fitness?.pfaSitNReach">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Sit and Reach (cm)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaSitNReach')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/sit.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{this.fitness?.pfaSitNReach ?? 0}}
                                                                            </td>
                                                                            <td>{{this.percentages.pfaSitNReach ??
                                                                                this.fitness?.pfaSitNReach ?? 0}} %
                                                                            </td>
                                                                            <td [ngClass]="(this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaSitNReach ??this.fitness?.pfaSitNReach ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaSitNReach ??this.fitness?.pfaSitNReach ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSitNReach
                                                                                ?? this.fitness?.pfaSitNReach ??
                                                                                0)}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <!-- <div
                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                            <div class="donut-circle" [ngClass]="(averagePctlValues[7]) >= 85 ? 'dark-green' :
                                                                   (averagePctlValues[7]) >= 70 ? 'light-green' :
                                                                   (averagePctlValues[7]) >= 40 ? 'yellow' :
                                                                   'red'">
                                                                <div class="inner-circle">
                                                                    <h2>{{ averagePctlValues[7] }}%
                                                                    </h2>
                                                                </div>

                                                            </div>
                                                        </div> -->
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 85 ? 'dark-green' :
                                                                   (this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 70 ? 'light-green' :
                                                                   (this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 40 ? 'yellow' :
                                                                   'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ this.percentages.pfaSitNReach ??
                                                                                this.fitness?.pfaSitNReach ?? 0 }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="remarks-container p-2">
                                                        <div class="subtitle mb-2">
                                                            <h6>Remarks</h6>
                                                        </div>
                                                        <div class="row col-md-12 Summary">
                                                            <span>
                                                                {{this.summarydetails?.[0]?.ausFlexibility}}
                                                            </span>
                                                         
                                                          </div>
                                                        </div>
                                                    <div class="mb-5" *ngIf="this.fitness?.pfaSingleLegBalanceRightEyesOpen || this.fitness?.pfaSingleLegBalanceLeftEyesOpen || this.fitness?.pfaSingleLegBalanceRightEyesClose || this.fitness?.pfaSingleLegBalanceLeftEyesClose">
                                                        <div class="main-title mb-2 section">
                                                            <h4>Balance</h4>
                                                        </div>
                                                        <div class="col-md-12 d-flex equal-height p-0 section">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngIf="this.fitness?.pfaSingleLegBalanceRightEyesOpen">
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Static Balance Right Eyes
                                                                                        Open (min/sec)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaSingleLegBalanceRightEyesOpen')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/balance-r.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                                ?? 0}}</td>
                                                                            <td>{{this.percentages.pfaSingleLegBalanceRightEyesOpen
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                                ?? 0 }} %</td>
                                                                            <td [ngClass]="( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 85 ? 'dark-green' :
                                                                        ( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 70 ? 'light-green' :
                                                                        ( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSingleLegBalanceRightEyesOpen
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr *ngIf="this.fitness?.pfaSingleLegBalanceLeftEyesOpen">
                                                                            <td>
                                                                                <div
                                                                                    class="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        Static Balance Left Eyes
                                                                                        Open (min/sec)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaSingleLegBalanceLeftEyesOpen')
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <img class="table-img"
                                                                                        src="/assets/image/balance-s.jpg">
                                                                                </div>
                                                                            </td>
                                                                            <td>{{this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                                ?? 0}}</td>
                                                                            <td>{{this.percentages.pfaSingleLegBalanceLeftEyesOpen
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                                ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSingleLegBalanceLeftEyesOpen
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                                ?? 0 )}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr *ngIf="this.fitness?.pfaSingleLegBalanceRightEyesClose">
                                                                            <td>
                                                                                Static Balance Right Eyes Close
                                                                                (min/sec)
                                                                                <p class="desc">IBM : {{
                                                                                    getBenchMarkRange('pfaSingleLegBalanceRightEyesClose')
                                                                                    }}</p>
                                                                            </td>
                                                                            <td>{{
                                                                                this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                                ?? 0}}</td>
                                                                            <td>{{this.percentages.pfaSingleLegBalanceRightEyesClose
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                                ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSingleLegBalanceRightEyesClose??
                                                                                this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                                ?? 0)}}</td>
                                                                        </tr>

                                                                        <tr *ngIf="this.fitness?.pfaSingleLegBalanceLeftEyesClose">
                                                                            <td>
                                                                                Static Balance Left Eyes Close
                                                                                (min/sec)
                                                                                <p class="desc">IBM : {{
                                                                                    getBenchMarkRange('pfaSingleLegBalanceLeftEyesClose')
                                                                                    }}</p>
                                                                            </td>
                                                                            <td>{{
                                                                                this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                                ?? 0}}</td>
                                                                            <td>{{this.percentages.pfaSingleLegBalanceLeftEyesClose
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                                ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                {{getScoreLabel(this.percentages.pfaSingleLegBalanceLeftEyesClose
                                                                                ??
                                                                                this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                                ?? 0)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(calculateAveragePctl()) >= 85 ? 'dark-green' :
                                                                   (calculateAveragePctl()) >= 70 ? 'light-green' :
                                                                   (calculateAveragePctl()) >= 40 ? 'yellow' :
                                                                   'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateAveragePctl() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                    <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausBalance}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Cognitive Skills -->
                        <div class="row mb-2 section" id="section3" *ngIf="this.cognitive?.cnaRulerDrop || this.cognitive?.cnaWallToss">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-5">
                                                        <h2>Cognitive Skills</h2>
                                                    </div>

                                                    <div class="row px-4" *ngIf="this.cognitive?.cnaRulerDrop || this.cognitive?.cnaWallToss">
                                                        <div class="col-md-12 d-flex flex-column">
                                                            <div class="mb-5">
                                                                <!-- <h2 class="sub-title">Flexibility</h2> -->
                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngIf="this.cognitive?.cnaRulerDrop">
                                                                                    <td>
                                                                                        Reaction Time (ms)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('cnaRulerDrop')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cognitive?.cnaRulerDrop
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cognitivePercentages.cnaRulerDrop
                                                                                        ?? this.cognitive?.cnaRulerDrop
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ??  this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ??  this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRulerDropMarkRange
                                                                                        ??
                                                                                        this.cognitivePercentages?.cnaRulerDrop
                                                                                        ??
                                                                                        this.cognitive?.cnaRulerDrop)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngIf="this.cognitive?.cnaWallToss">
                                                                                    <td>
                                                                                        Co-ordination wall test (min)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('cnaWallToss')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{this.cognitive?.cnaWallToss ??
                                                                                        0}}</td>
                                                                                    <td>{{this.fitnessDataIBMObject?.IBM?.cnaWallToss ??
                                                                                        this.cognitivePercentages?.cnaWallToss
                                                                                        ?? this.cognitiveReport?.wallTossZScorePercent ??
                                                                                        this.cognitive?.cnaWallToss ??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.cnaWallToss ?? this.cognitivePercentages?.cnaWallToss ?? this.cognitiveReport?.wallTossZScorePercent ??  this.cognitive?.cnaWallToss) >= 85 ? 'dark-green' :
                                                                                (this.fitnessDataIBMObject?.IBM?.cnaWallToss ??this.cognitivePercentages?.cnaWallToss ?? this.cognitiveReport?.wallTossZScorePercent ??  this.cognitive?.cnaWallToss) >= 70 ? 'light-green' :
                                                                                (this.fitnessDataIBMObject?.IBM?.cnaWallToss ??this.cognitivePercentages?.cnaWallToss ??  this.cognitive?.cnaWallToss) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.fitnessDataIBMObject?.IBM?.cnaWallToss ??this.cognitivePercentages?.cnaWallToss
                                                                                        ?? this.cognitiveReport?.wallTossZScorePercent ??
                                                                                        this.cognitive?.cnaWallToss ??
                                                                                        0)}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(calculateCognitiveAveragePctl()) >= 85 ? 'dark-green' :
                                                                                (calculateCognitiveAveragePctl()) >= 70 ? 'light-green' :
                                                                                (calculateCognitiveAveragePctl()) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{
                                                                                        calculateCognitiveAveragePctl()
                                                                                        }}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausCognitive}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <!-- Cardio Skills -->
                        <div class="row mb-2 section" id="section4" *ngIf="this.cardio?.coaFatigueIndex">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-5">
                                                        <h2>Cardio Skills</h2>
                                                    </div>

                                                    <div class="row px-4" >
                                                        <div class="col-md-12 d-flex flex-column">
                                                            <div class="mb-5">
                                                                <!-- <h2 class="sub-title">Flexibility</h2> -->
                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Rast
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST
                                                                                        ?? this.cardio?.coaRAST
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRASTRange ?? this.cardioPercentages.coaRAST ?? this.cardioPercentages.coaRAST ?? this.cardio?.coaRAST) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRASTRange ?? this.cardioPercentages.coaRAST ?? this.cardioPercentages.coaRAST ?? this.cardio?.coaRAST) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRASTRange ?? this.cardioPercentages.coaRAST ?? this.cardioPercentages.coaRAST ?? this.cardio?.coaRAST) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRASTRange ?? this.cardioPercentages.coaRAST ?? this.cardioPercentages.coaRAST ?? this.cardio?.coaRAST)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Rast2
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST2')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST2
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST2
                                                                                        ?? this.cardio?.coaRAST2
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRAST2Range ?? this.cardioPercentages.coaRAST2 ?? this.cardioPercentages.coaRAST2 ?? this.cardio?.coaRAST2) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRAST2Range ?? this.cardioPercentages.coaRAST2 ?? this.cardioPercentages.coaRAST2 ?? this.cardio?.coaRAST2) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRAST2Range ?? this.cardioPercentages.coaRAST2 ?? this.cardioPercentages.coaRAST2 ?? this.cardio?.coaRAST2) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRAST2Range ?? this.cardioPercentages.coaRAST2 ?? this.cardioPercentages.coaRAST2 ?? this.cardio?.coaRAST2)}}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td>
                                                                                        Rast3
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST3')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST3
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST3
                                                                                        ?? this.cardio?.coaRAST3
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRAST3Range ?? this.cardioPercentages.coaRAST3 ?? this.cardioPercentages.coaRAST3 ?? this.cardio?.coaRAST3) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRAST3Range ?? this.cardioPercentages.coaRAST3 ?? this.cardioPercentages.coaRAST3 ?? this.cardio?.coaRAST3) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRAST3Range ?? this.cardioPercentages.coaRAST3 ?? this.cardioPercentages.coaRAST3 ?? this.cardio?.coaRAST3) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRAST3Range ?? this.cardioPercentages.coaRAST3 ?? this.cardioPercentages.coaRAST3 ?? this.cardio?.coaRAST3)}}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td>
                                                                                        Rast4
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST4')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST4
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST4
                                                                                        ?? this.cardio?.coaRAST4
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRAST4Range ?? this.cardioPercentages.coaRAST4 ?? this.cardioPercentages.coaRAST4 ?? this.cardio?.coaRAST4) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRAST4Range ?? this.cardioPercentages.coaRAST4 ?? this.cardioPercentages.coaRAST4 ?? this.cardio?.coaRAST4) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRAST4Range ?? this.cardioPercentages.coaRAST4 ?? this.cardioPercentages.coaRAST4 ?? this.cardio?.coaRAST4) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRAST4Range ?? this.cardioPercentages.coaRAST4 ?? this.cardioPercentages.coaRAST4 ?? this.cardio?.coaRAST4)}}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td>
                                                                                        Rast5
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST5')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST5
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST5
                                                                                        ?? this.cardio?.coaRAST5
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRAST5Range ?? this.cardioPercentages.coaRAST5 ?? this.cardioPercentages.coaRAST5 ?? this.cardio?.coaRAST5) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRAST5Range ?? this.cardioPercentages.coaRAST5 ?? this.cardioPercentages.coaRAST5 ?? this.cardio?.coaRAST5) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRAST5Range ?? this.cardioPercentages.coaRAST5 ?? this.cardioPercentages.coaRAST5 ?? this.cardio?.coaRAST5) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRAST5Range ?? this.cardioPercentages.coaRAST5 ?? this.cardioPercentages.coaRAST5 ?? this.cardio?.coaRAST5)}}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td>
                                                                                        Rast6
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaRAST6')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.cardio?.coaRAST6
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.cardioPercentages.coaRAST6
                                                                                        ?? this.cardio?.coaRAST6
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorRAST6Range ?? this.cardioPercentages.coaRAST6 ?? this.cardioPercentages.coaRAST6 ?? this.cardio?.coaRAST6) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRAST6Range ?? this.cardioPercentages.coaRAST6 ?? this.cardioPercentages.coaRAST6 ?? this.cardio?.coaRAST6) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRAST6Range ?? this.cardioPercentages.coaRAST6 ?? this.cardioPercentages.coaRAST6 ?? this.cardio?.coaRAST6) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorRAST6Range ?? this.cardioPercentages.coaRAST6 ?? this.cardioPercentages.coaRAST6 ?? this.cardio?.coaRAST6)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Fatigue
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('coaFatigueIndex')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{this.cardio?.coaFatigueIndex
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{ this.cardioPercentages.coaFatigueIndex ?? this.cardio?.coaFatigueIndex ?? 0}} %</td>
                                                                                    <td [ngClass]="( this.cardioPercentages.coaFatigueIndex ?? this.cardio?.coaFatigueIndex) >= 85 ? 'dark-green' :
                                                                                ( this.cardioPercentages.coaFatigueIndex ?? this.cardio?.coaFatigueIndex) >= 70 ? 'light-green' :
                                                                                ( this.cardioPercentages.coaFatigueIndex ?? this.cardio?.coaFatigueIndex) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel( this.cardioPercentages.coaFatigueIndex ?? this.cardio?.coaFatigueIndex)}}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(calculateCardioAveragePctl()) >= 85 ? 'dark-green' :
                                                                                (calculateCardioAveragePctl()) >= 70 ? 'light-green' :
                                                                                (calculateCardioAveragePctl()) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{
                                                                                        calculateCardioAveragePctl()
                                                                                        }}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="remarks-container p-2">
                                                                <div class="subtitle mb-2">
                                                                    <h6>Remarks</h6>
                                                                </div>
                                                                <div class="row col-md-12 Summary">
                                                                    <span>
                                                                        {{this.summarydetails?.[0]?.ausCardio}}
                                                                    </span>
                                                                 
                                                                  </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Injury & Injury Prevention Analysis -->
                        <div class="row mb-2 section" id="section5" *ngIf="this.injury">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-5">
                                                        <h2>Injury Prevention Analysis
                                                        </h2>
                                                    </div>

                                                    <div class="row px-4">
                                                        <div class="col-md-12 d-flex flex-column">
                                                            <div class="mb-5">
                                                                <div class="main-title mb-2">
                                                                    <h4>Functional Movement Screening</h4>
                                                                </div>

                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Deep Squat
                                                                                    </td>
                                                                                    <td>{{this.injury?.ipaDeepSquat??
                                                                                        0}}</td>
                                                                                    <td>{{(this.injury?.ipaDeepSquat /
                                                                                        3) * 100
                                                                                        | number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="((this.injury?.ipaDeepSquat / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaDeepSquat / 3) * 100) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaDeepSquat / 3) * 100) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaDeepSquat
                                                                                        / 3) * 100)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Inline Lunge
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.injury?.ipaInlineLunge??
                                                                                        0}}</td>
                                                                                    <td>{{
                                                                                        (this.injury?.ipaInlineLunge /
                                                                                        3) * 100
                                                                                        | number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="((this.injury?.ipaInlineLunge / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaInlineLunge / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaInlineLunge / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaInlineLunge
                                                                                        / 3) * 100)}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Hurdle Step
                                                                                    </td>
                                                                                    <td>{{this.injury?.ipaHurdleStep??
                                                                                        0}}</td>
                                                                                    <td>{{(this.injury?.ipaHurdleStep /
                                                                                        3) * 100
                                                                                        | number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="((this.injury?.ipaHurdleStep / 3) * 100 ) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaHurdleStep / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaHurdleStep / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaHurdleStep
                                                                                        / 3) * 100 )}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Active SLR
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.injury?.ipaActiveSLR?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        (this.injury?.ipaActiveSLR / 3)
                                                                                        * 100 |
                                                                                        number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 85 ? 'dark-green' :
                                                                                ( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaActiveSLR
                                                                                        / 3) * 100 )}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Shoulder Mobility
                                                                                    </td>
                                                                                    <td>{{this.injury?.ipaShoulderMobility??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{(this.injury?.ipaShoulderMobility
                                                                                        / 3)
                                                                                        * 100 | number: '1.0-2' }} %
                                                                                    </td>
                                                                                    <td [ngClass]="((this.injury?.ipaShoulderMobility / 3)
                                                                            * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaShoulderMobility / 3)
                                                                                * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaShoulderMobility / 3)
                                                                                * 100 ) >= 45 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaShoulderMobility
                                                                                        / 3) * 100)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Push Up
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.injury?.ipaPushUp ?? 0}}
                                                                                    </td>
                                                                                    <td>{{
                                                                                        (this.injury?.ipaPushUp / 3) *
                                                                                        100 |
                                                                                        number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="((this.injury?.ipaPushUp / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaPushUp / 3) * 100) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaPushUp / 3) * 100) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaPushUp
                                                                                        / 3)* 100)}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Rotary Stability
                                                                                    </td>
                                                                                    <td>{{this.injury?.ipaRotaryStability??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{(this.injury?.ipaRotaryStability
                                                                                        / 3)
                                                                                        * 100 | number: '1.0-2' }} %
                                                                                    </td>
                                                                                    <td [ngClass]="((this.injury?.ipaRotaryStability / 3)
                                                                            * 100 ) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaRotaryStability / 3)
                                                                                * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaRotaryStability / 3)
                                                                                * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel((this.injury?.ipaRotaryStability
                                                                                        / 3)* 100)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Total Score
                                                                                    </td>
                                                                                    <td>{{(this.injury?.ipaTotalScore ||
                                                                                        (this.injury?.ipaDeepSquat +
                                                                                        this.injury?.ipaInlineLunge +
                                                                                        this.injury?.ipaHurdleStep +
                                                                                        this.injury?.ipaActiveSLR +
                                                                                        this.injury?.ipaShoulderMobility
                                                                                        + this.injury?.ipaPushUp +
                                                                                        this.injury?.ipaRotaryStability))
                                                                                        ?? 0}}</td>
                                                                                    <td>{{((this.injury?.ipaTotalScore
                                                                                        || (this.injury?.ipaDeepSquat +
                                                                                        this.injury?.ipaInlineLunge +
                                                                                        this.injury?.ipaHurdleStep +
                                                                                        this.injury?.ipaActiveSLR +
                                                                                        this.injury?.ipaShoulderMobility
                                                                                        + this.injury?.ipaPushUp +
                                                                                        this.injury?.ipaRotaryStability))
                                                                                        / 21) *
                                                                                        100 | number: '1.0-2' }} %</td>
                                                                                    <td [ngClass]="(((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 85 ? 'dark-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 70 ? 'light-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(((this.injury?.ipaTotalScore
                                                                                        || (this.injury?.ipaDeepSquat +
                                                                                        this.injury?.ipaInlineLunge +
                                                                                        this.injury?.ipaHurdleStep +
                                                                                        this.injury?.ipaActiveSLR +
                                                                                        this.injury?.ipaShoulderMobility
                                                                                        + this.injury?.ipaPushUp +
                                                                                        this.injury?.ipaRotaryStability))
                                                                                        / 21) * 100)}}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 85 ? 'dark-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 70 ? 'light-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{ ((this.injury?.ipaTotalScore
                                                                                        || (this.injury?.ipaDeepSquat +
                                                                                        this.injury?.ipaInlineLunge +
                                                                                        this.injury?.ipaHurdleStep +
                                                                                        this.injury?.ipaActiveSLR +
                                                                                        this.injury?.ipaShoulderMobility
                                                                                        + this.injury?.ipaPushUp +
                                                                                        this.injury?.ipaRotaryStability))
                                                                                        / 21) *
                                                                                        100 | number: '1.0-2' }}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausInjury}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <!-- sportss Performance Analysis -->
                        <div class="row mb-2" id="section6" *ngIf="this.sports?.spaAverageTorqueIR || this.sports?.spaPeakTorqueIR || this.sports?.spaAverageTorqueIL || this.sports?.spaPeakTorqueIL ||
                        this.sports?.spaAverageTorqueCR || this.sports?.spaPeakTorqueCR || this.sports?.spaAverageTorqueCL || this.sports?.spaPeakTorqueCL || this.fitness?.pfaStandingBroadJump || this.fitness?.pfaLowerBodyForce || this.fitness?.pfaLowerBodyPower || this.fitness?.pfaSeatedMedBallThrow || this.fitness?.pfaUpperBodyForce || this.fitness?.pfaUpperBodyPower">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-5 section">
                                                        <h2>Sports Performance Analysis</h2>
                                                    </div>
                                                    <div class="row px-4">
                                                        <div class="col-md-12 d-flex flex-column">


                                                            <div class="mb-4" *ngIf="this.sports?.spaAverageTorqueIR || this.sports?.spaPeakTorqueIR || this.sports?.spaAverageTorqueIL || this.sports?.spaPeakTorqueIL">
                                                                <div class="section">
                                                                <div class="subtitle mb-2">
                                                                    <h6>Isokinetic Dynamometer

                                                                    </h6>
                                                                </div>
                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngIf="this.sports?.spaAverageTorqueIR">
                                                                                    <td>Right Average Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaAverageTorqueIR')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.sports?.spaAverageTorqueIR
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaAverageTorqueIR_avg
                                                                                        ??this.sports?.spaAverageTorqueIR
                                                                                        ?? 0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaAverageTorqueIR_avg
                                                                                        ??
                                                                                        this.sports?.spaAverageTorqueIR
                                                                                        ??this.sports?.spaAverageTorqueIR
                                                                                        ?? 0 ) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Right Peak Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaPeakTorqueIR">
                                                                                    <td>Right Peak Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaPeakTorqueIR')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.sports?.spaPeakTorqueIR
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaPeakTorqueIR_avg
                                                                                        ?? this.sports?.spaPeakTorqueIR
                                                                                        ?? 0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaPeakTorqueIR_avg
                                                                                        ?? this.sports?.spaPeakTorqueIR
                                                                                        ?? this.sports?.spaPeakTorqueIR
                                                                                        ?? 0) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Left Average Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaAverageTorqueIL">
                                                                                    <td>Left Average Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaAverageTorqueIL')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.sports?.spaAverageTorqueIL
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaAverageTorqueIL_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueIL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueIL)
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaAverageTorqueIL_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueIL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueIL)
                                                                                        ?? 0) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Left Peak Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaPeakTorqueIL">
                                                                                    <td>Left Peak Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaPeakTorqueIL')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.sports?.spaPeakTorqueIL
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaPeakTorqueIL_avg
                                                                                        ?? (this.sports?.spaPeakTorqueIL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueIL) ??
                                                                                        0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaPeakTorqueIL_avg
                                                                                        ?? (this.sports?.spaPeakTorqueIL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueIL) ??
                                                                                        0) }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]=" (calculateTorqueAveragePctl()) >= 85 ? 'dark-green' :
                                                                        (calculateTorqueAveragePctl()) >= 70 ? 'light-green' :
                                                                        (calculateTorqueAveragePctl()) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{ calculateTorqueAveragePctl()
                                                                                        }}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausIsokinetic}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-4">
                                                        <div class="col-md-12 d-flex flex-column">


                                                            <div class="mb-4" *ngIf="this.sports?.spaAverageTorqueCR || this.sports?.spaPeakTorqueCR || this.sports?.spaAverageTorqueCL || this.sports?.spaPeakTorqueCL">
                                                                <div class="section">
                                                                <div class="subtitle mb-2">
                                                                    <h6>Concentric

                                                                    </h6>
                                                                </div>
                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngIf="this.sports?.spaAverageTorqueCR">
                                                                                    <td>Right Average Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaAverageTorqueCR')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.sports?.spaAverageTorqueCR
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaAverageTorqueCR_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueCR
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueCR)
                                                                                        ?? 0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaAverageTorqueCR_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueCR
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueCR)
                                                                                        ?? 0) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Right Peak Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaPeakTorqueCR">
                                                                                    <td>Right Peak Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaPeakTorqueCR')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.sports?.spaPeakTorqueCR
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaPeakTorqueCR_avg
                                                                                        ?? (this.sports?.spaPeakTorqueCR
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueCR) ??
                                                                                        0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaPeakTorqueCR_avg
                                                                                        ?? (this.sports?.spaPeakTorqueCR
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueCR) ??
                                                                                        0) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Left Average Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaAverageTorqueCL">
                                                                                    <td>Left Average Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaAverageTorqueCL')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.sports?.spaAverageTorqueCL
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaAverageTorqueCL_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueCL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueCL)
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaAverageTorqueCL_avg
                                                                                        ??
                                                                                        (this.sports?.spaAverageTorqueCL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaAverageTorqueCL)
                                                                                        ?? 0) }}
                                                                                    </td>
                                                                                </tr>

                                                                                <!-- Left Peak Torque (Extension/Flexion) -->
                                                                                <tr *ngIf="this.sports?.spaPeakTorqueCL">
                                                                                    <td>Left Peak Torque
                                                                                        (Extension/Flexion)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('spaPeakTorqueCL')
                                                                                            }}</p>
                                                                                    </td>
                                                                                    <td>{{ this.sports?.spaPeakTorqueCL
                                                                                        ?? 0 }}</td>
                                                                                    <td>{{
                                                                                        this.spaPercentages?.spaPeakTorqueCL_avg
                                                                                        ?? (this.sports?.spaPeakTorqueCL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueCL) ??
                                                                                        0 }} %</td>
                                                                                    <td [ngClass]="(this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                                        {{
                                                                                        getScoreLabel(this.spaPercentages?.spaPeakTorqueCL_avg
                                                                                        ?? (this.sports?.spaPeakTorqueCL
                                                                                        > 100 ? 100 :
                                                                                        this.sports?.spaPeakTorqueCL) ??
                                                                                        0) }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]=" (calculateTorqueAveragePctlForCRandCL()) >= 85 ? 'dark-green' :
                                                                                                (calculateTorqueAveragePctlForCRandCL()) >= 70 ? 'light-green' :
                                                                                                (calculateTorqueAveragePctlForCRandCL()) >= 40 ? 'yellow' :
                                                                                               'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{
                                                                                        calculateTorqueAveragePctlForCRandCL()
                                                                                        }}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausConcentric}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row px-4" *ngIf="this.fitness?.pfaSeatedMedBallThrow || this.fitness?.pfaUpperBodyForce || this.fitness?.pfaUpperBodyPower">
                                                        <div class="col-md-12 d-flex flex-column">
                                                            <div class="mb-4">
                                                                
                                                                <div class="main-title section">
                                                                    <h4>Power</h4>
                                                                </div>
                                                               
                                                                <div class="subtitle mb-2 section" *ngIf="this.fitness?.pfaSeatedMedBallThrow || this.fitness?.pfaUpperBodyForce || this.fitness?.pfaUpperBodyPower">
                                                                    <h6>Upper Body

                                                                    </h6>
                                                                </div>
                                                                <div class="col-md-12 mb-4 d-flex equal-height p-0 section" *ngIf="this.fitness?.pfaSeatedMedBallThrow || this.fitness?.pfaUpperBodyForce || this.fitness?.pfaUpperBodyPower">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngIf="this.fitness?.pfaSeatedMedBallThrow">
                                                                                    <td>
                                                                                        <div
                                                                                            class="d-flex align-items-center justify-content-between">
                                                                                            <div>
                                                                                                Seated Med Ball Throws
                                                                                                (m)
                                                                                                <p class="desc">IBM : {{
                                                                                                    getBenchMarkRange('pfaSeatedMedBallThrow')
                                                                                                    }}</p>
                                                                                            </div>
                                                                                            <img class="table-img"
                                                                                                src="/assets/image/med-ball.jpg">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{this.fitness?.pfaSeatedMedBallThrow
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.fitnessDataIBMObject?.IBM?.pfaSeatedMedBallThrow ?? this.leveloneReport?.lorSeatedMedBallMarkRange
                                                                                        ??
                                                                                        this.percentages.pfaSeatedMedBallThrow
                                                                                        ??
                                                                                        this.fitness?.pfaSeatedMedBallThrow
                                                                                        ?? 0}} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.pfaSeatedMedBallThrow ??this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow ) >= 85 ? 'dark-green' :
                                                                        (this.fitnessDataIBMObject?.IBM?.pfaSeatedMedBallThrow ??this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow ) >= 70 ? 'light-green' :
                                                                        (this.fitnessDataIBMObject?.IBM?.pfaSeatedMedBallThrow ??this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorSeatedMedBallMarkRange
                                                                                        ??
                                                                                        this.percentages.pfaSeatedMedBallThrow
                                                                                        ??
                                                                                        this.fitness?.pfaSeatedMedBallThrow)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngIf="this.fitness?.pfaUpperBodyForce">
                                                                                    <td>
                                                                                        Force (N)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaUpperBodyForce')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.fitness?.pfaUpperBodyForce??
                                                                                        0}}</td>
                                                                                    <td>{{
                                                                                        this.percentages?.pfaUpperBodyForce
                                                                                        ??
                                                                                        this.fitness?.pfaUpperBodyForce??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(
                                                                                        this.percentages?.pfaUpperBodyForce
                                                                                        ??
                                                                                        this.fitness?.pfaUpperBodyForce
                                                                                        ?? 0)}}</td>
                                                                                </tr>
                                                                                <tr *ngIf="this.fitness?.pfaUpperBodyPower">
                                                                                    <td>
                                                                                        Power (W)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaUpperBodyPower')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.fitness?.pfaUpperBodyPower??
                                                                                        0}}</td>
                                                                                    <td>{{
                                                                                        this.percentages?.pfaUpperBodyPower
                                                                                        ??
                                                                                        this.fitness?.pfaUpperBodyPower??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="( this.percentages?.pfaUpperBodyPower ?? this.fitness?.pfaUpperBodyPower) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaUpperBodyPower ?? this.fitness?.pfaUpperBodyPower) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaUpperBodyPower ?? this.fitness?.spaForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(this.percentages?.pfaUpperBodyPower
                                                                                        ??
                                                                                        this.fitness?.pfaUpperBodyPower
                                                                                        ?? 0)}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(calculateSeatedMedBallAverage()) >= 85 ? 'dark-green' :
                                                            (calculateSeatedMedBallAverage()) >= 70 ? 'light-green' :
                                                            (calculateSeatedMedBallAverage()) >= 40 ? 'yellow' :
                                                            'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{calculateSeatedMedBallAverage()}}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausUpperBodyPower}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                          
                                                      
                                                                <div class="subtitle mb-2 section" *ngIf="this.fitness?.pfaStandingBroadJump || this.fitness?.pfaLowerBodyForce || this.fitness?.pfaLowerBodyPower">
                                                                    <h6>Lower Body

                                                                    </h6>
                                                                </div>
                                                                <div class="col-md-12 d-flex equal-height p-0 section" *ngIf="this.fitness?.pfaStandingBroadJump || this.fitness?.pfaLowerBodyForce || this.fitness?.pfaLowerBodyPower">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>


                                                                                <tr *ngIf="this.fitness?.pfaStandingBroadJump">
                                                                                    <td>
                                                                                        <div
                                                                                            class="d-flex align-items-center justify-content-between">
                                                                                            <div>
                                                                                                Broad Jump (ft)
                                                                                                <p class="desc">IBM : {{
                                                                                                    getBenchMarkRange('pfaStandingBroadJump')
                                                                                                    }}</p>
                                                                                            </div>
                                                                                            <img class="table-img"
                                                                                                src="/assets/image/jump.jpg">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        this.fitness?.pfaStandingBroadJump
                                                                                        ?? 0}}</td>
                                                                                    <td>{{
                                                                                        this.percentages.pfaStandingBroadJump
                                                                                        ??
                                                                                        this.fitness?.pfaStandingBroadJump
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(this.percentages.pfaStandingBroadJump
                                                                                        ??
                                                                                        this.fitness?.pfaStandingBroadJump
                                                                                        ?? 0)}}</td>
                                                                                </tr>
                                                                                <tr *ngIf="this.fitness?.pfaLowerBodyForce">
                                                                                    <td>
                                                                                        Force (N)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaLowerBodyForce')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.fitness?.pfaLowerBodyForce??
                                                                                        0}}</td>
                                                                                    <td>{{
                                                                                        this.percentages?.pfaLowerBodyForce
                                                                                        ??
                                                                                        this.fitness?.pfaLowerBodyForce??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(this.percentages?.pfaLowerBodyForce
                                                                                        ??
                                                                                        this.fitness?.pfaLowerBodyForce
                                                                                        ?? 0)}}</td>
                                                                                </tr>
                                                                                <tr *ngIf="this.fitness?.pfaLowerBodyPower">
                                                                                    <td>
                                                                                        Power (W)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('pfaLowerBodyPower')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.fitness?.pfaLowerBodyPower??
                                                                                        0}}</td>
                                                                                    <td>{{this.percentages?.pfaLowerBodyPower
                                                                                        ??
                                                                                        this.fitness?.pfaLowerBodyPower??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="(this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 85 ? 'dark-green' :
                                                                                        (this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 70 ? 'light-green' :
                                                                                        (this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                                        {{getScoreLabel(this.percentages?.pfaLowerBodyPower
                                                                                        ??
                                                                                        this.fitness?.pfaLowerBodyPower
                                                                                        ?? 0)}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(calculateFitnesslowerAverage()) >= 85 ? 'dark-green' :
                                                                        (calculateFitnesslowerAverage()) >= 70 ? 'light-green' :
                                                                        (calculateFitnesslowerAverage()) >= 40 ? 'yellow' :
                                                                        'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{calculateFitnesslowerAverage()}}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausLowerBodyPower}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                                </div>
                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Nutrition Analysis -->

                        <div class="row mb-2" id="section7">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    
                                                    <div class="row px-4">
                                                        <div class="col-md-12 d-flex flex-column">
                                                            <div class="mb-4" *ngIf="this.nutrition">
                                                            
                                                           <div class="section">
                                                                <div class="header-cont mb-5">
                                                                    <h2>Nutrition Analysis</h2>
                                                                </div>
                                                                <div class="subtitle mb-2">
                                                                    <h6>Body Composition

                                                                    </h6>
                                                                </div>
                                                                <div class="col-md-12 d-flex equal-height p-0">
                                                                    <div class="col-md-9 p-0">
                                                                        <table #fitnessTable class="custom-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Test</th>
                                                                                    <th>Score</th>
                                                                                    <th>pctl</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Height (cm)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBodyHeight')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBodyHeight??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{this.fitnessDataIBMObject?.IBM?.naBodyHeight ??this.leveloneReport?.lorHeightMarkRange
                                                                                        ??
                                                                                        this.nutritionPercentages.naBodyHeight
                                                                                        ??
                                                                                        this.nutrition?.naBodyHeight ??
                                                                                        0 }} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.fitnessDataIBMObject?.IBM?.naBodyHeight ??this.leveloneReport?.lorHeightMarkRange
                                                                            ?? this.nutritionPercentages.naBodyHeight??this.nutrition?.naBodyHeight ?? 0) >= 85 ? 'dark-green' :
                                                                                (this.fitnessDataIBMObject?.IBM?.naBodyHeight ??this.leveloneReport?.lorHeightMarkRange??
                                                                                 this.nutritionPercentages.naBodyHeight??
                                                                                this.nutrition?.naBodyHeight ?? 0) >= 70 ? 'light-green' :
                                                                                (this.fitnessDataIBMObject?.IBM?.naBodyHeight ??this.leveloneReport?.lorHeightMarkRange?? this.nutritionPercentages.naBodyHeight??
                                                                                this.nutrition?.naBodyHeight ?? 0) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.fitnessDataIBMObject?.IBM?.naBodyHeight ??this.leveloneReport?.lorHeightMarkRange
                                                                                        ??
                                                                                        this.nutritionPercentages.naBodyHeight??
                                                                                        this.nutrition?.naBodyHeight ??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Weight (kgs)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBodyWeight')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBodyWeight??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{this.leveloneReport?.lorWeightMarkRange
                                                                                        ??
                                                                                        this.nutritionPercentages.naBodyWeight
                                                                                        ?? this.nutrition?.naBodyWeight
                                                                                        ?? 0}} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.leveloneReport?.lorWeightMarkRange
                                                                                        ??
                                                                                        this.nutritionPercentages.naBodyWeight
                                                                                        ?? this.nutrition?.naBodyWeight
                                                                                        ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        BMI (kg/m²)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBMI')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBMI?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naBMI
                                                                                        ??
                                                                                        this.nutrition?.naBMI ?? 0}} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naBMI
                                                                                        ?? this.nutrition?.naBMI ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Body Fat (%)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBodyFat')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBodyFat??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naBodyFat
                                                                                        ??
                                                                                        this.nutrition?.naBodyFat}} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 40 ? 'yellow' : 'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naBodyFat
                                                                                        ?? this.nutrition?.naBodyFat ??
                                                                                        0)}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Fat Free Weight (kg)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naFatFreeWeight')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naFatFreeWeight??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naFatFreeWeight
                                                                                        ??
                                                                                        this.nutrition?.naFatFreeWeight
                                                                                        ?? 0}} %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naFatFreeWeight
                                                                                        ??
                                                                                        this.nutrition?.naFatFreeWeight
                                                                                        ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Subcutaneous Fat (kg)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naSubcutaneousFat')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naSubcutaneousFat??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naSubcutaneousFat
                                                                                        ??
                                                                                        this.nutrition?.naSubcutaneousFat??
                                                                                        0}} %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 40 ? 'yellow' :'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naSubcutaneousFat
                                                                                        ??
                                                                                        this.nutrition?.naSubcutaneousFat
                                                                                        ??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Visceral Fat (kg)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naVisceralFat')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naVisceralFat
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naVisceralFat
                                                                                        ?? this.nutrition?.naVisceralFat
                                                                                        ?? 0 }}
                                                                                        %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naVisceralFat
                                                                            ?? this.nutrition?.naVisceralFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naVisceralFat
                                                                                ?? this.nutrition?.naVisceralFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naVisceralFat ?? this.nutrition?.naVisceralFat) >= 40 ? 'yellow' :
                                                                                                                                           'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naVisceralFat
                                                                                        ??
                                                                                        this.nutrition?.naVisceralFat)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Body Water (%)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBodyWater')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBodyWater ??
                                                                                        0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naBodyWater
                                                                                        ?? this.nutrition?.naBodyWater
                                                                                        ?? 0 }} %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naBodyWater
                                                                                        ?? this.nutrition?.naBodyWater
                                                                                        ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Skeletal Muscle (%)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naSkeletalMuscle')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naSkeletalMuscle
                                                                                        ??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naSkeletalMuscle
                                                                                        ??
                                                                                        this.nutrition?.naSkeletalMuscle
                                                                                        ?? 0
                                                                                        }} %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 40 ? 'yellow' :'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naSkeletalMuscle
                                                                                        ??
                                                                                        this.nutrition?.naSkeletalMuscle
                                                                                        ??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Lean Mass (kg)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naLeanMass')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naLeanMass ??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naLeanMass
                                                                                        ?? this.nutrition?.naLeanMass ??
                                                                                        0 }} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 40 ? 'yellow' :'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naLeanMass
                                                                                        ?? this.nutrition?.naLeanMass ??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Bone Mass (kg)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBoneMass')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBoneMass??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naBoneMass
                                                                                        ?? this.nutrition?.naBoneMass ??
                                                                                        0 }} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 40 ? 'yellow' :'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naBoneMass
                                                                                        ?? this.nutrition?.naBoneMass ??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Protein (%)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naProtein')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naProtein??
                                                                                        0}}</td>
                                                                                    <td>{{this.nutritionPercentages.naProtein
                                                                                        ??
                                                                                        this.nutrition?.naProtein?? 0 }}
                                                                                        %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 40 ? 'yellow' :'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naProtein
                                                                                        ?? this.nutrition?.naProtein??
                                                                                        0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        BMR (kcal/day)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naBMR')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naBMR ?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naBMR
                                                                                        ??
                                                                                        this.nutrition?.naBMR ?? 0 }} %
                                                                                    </td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 40 ? 'yellow' : 'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naBMR
                                                                                        ?? this.nutrition?.naBMR ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Metabolic Age (years)
                                                                                        <p class="desc">IBM : {{
                                                                                            getBenchMarkRange('naMetabolicAge')
                                                                                            }}</p>

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naMetabolicAge
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naMetabolicAge
                                                                                        ??
                                                                                        this.nutrition?.naMetabolicAge
                                                                                        ?? 0
                                                                                        }} %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naMetabolicAge
                                                                                        ??
                                                                                        this.nutrition?.naMetabolicAge
                                                                                        ?? 0
                                                                                        )}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Health Score

                                                                                    </td>
                                                                                    <td>{{this.nutrition?.naHealthScore
                                                                                        ?? 0}}
                                                                                    </td>
                                                                                    <td>{{this.nutritionPercentages.naHealthScore
                                                                                        ?? this.nutrition?.naHealthScore
                                                                                        ?? 0 }}
                                                                                        %</td>
                                                                                    <td [ngClass]="(this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 40 ? 'yellow' : 'red'"
                                                                                        class="rating">
                                                                                        {{getScoreLabel(this.nutritionPercentages.naHealthScore
                                                                                        ?? this.nutrition?.naHealthScore
                                                                                        ?? 0)}}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="col-md-3 p-0">
                                                                        <div
                                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                                            <div class="donut-circle" [ngClass]="(calculateNutritionMetricsAverage() ) >= 85 ? 'dark-green' :
                                                                                (calculateNutritionMetricsAverage() ) >= 70 ? 'light-green' :
                                                                                (calculateNutritionMetricsAverage() ) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                                <div class="inner-circle">
                                                                                    <h2>{{
                                                                                        calculateNutritionMetricsAverage()}}%
                                                                                    </h2>
                                                                                </div>
                                                                                <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                           
                                                        <div class="remarks-container p-2">
                                                            <div class="subtitle mb-2">
                                                                <h6>Remarks</h6>
                                                            </div>
                                                            <div class="row col-md-12 Summary">
                                                                <span>
                                                                    {{this.summarydetails?.[0]?.ausNutrition}}
                                                                </span>
                                                             
                                                              </div>
                                                            </div>
                                                            </div>
                                                            

                                                            <div class="subtitle mb-2 section" *ngIf="this.SleepandRest">
                                                                <h6>Sleep and Recovery

                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 mb-4 d-flex equal-height p-0 section" *ngIf="this.SleepandRest">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Sleep and Recovery
                                                                                </td>
                                                                                <td>{{ this.SleepandRest ?? 0 }}</td>
                                                                                <td>{{this.SleepandRest ?? 0}} %</td>
                                                                                <td [ngClass]="(this.SleepandRest) >= 85 ? 'dark-green' :
                                                                (this.SleepandRest) >= 70 ? 'light-green' :
                                                                (this.SleepandRest) >= 40 ? 'yellow' : 'red'"
                                                                                    class="rating">
                                                                                    {{getScoreLabel(this.SleepandRest ??
                                                                                    0)}}
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle" [ngClass]="(calculateSleepAndRestAverage()) >= 85 ? 'dark-green' :
                                                    (calculateSleepAndRestAverage()) >= 70 ? 'light-green' :
                                                    (calculateSleepAndRestAverage()) >= 40 ? 'yellow' : 'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{ calculateSleepAndRestAverage()
                                                                                    }}%</h2>
                                                                            </div>
                                                                            <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     
                                                     
                                                            <div class="subtitle mb-2 section" *ngIf="this.hydration">
                                                                <h6>Nutrition and Hydration

                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 mb-4 d-flex equal-height p-0 section" *ngIf="this.hydration">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Nutrition and Hydration
                                                                                </td>
                                                                                <td>{{ this.hydration ?? 0 }}</td>
                                                                                <td>{{this.hydration ?? 0}} %</td>
                                                                                <td [ngClass]="(this.hydration ) >= 85 ? 'dark-green' :
                                                                (this.hydration) >= 70 ? 'light-green' :
                                                                (this.hydration ) >= 40 ? 'yellow' :
                                                                'red'" class="rating">{{getScoreLabel(this.hydration ??
                                                                                    0)}}
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle" [ngClass]="(calculateHydrationAverage() ) >= 85 ? 'dark-green' :
                                                                (calculateHydrationAverage()) >= 70 ? 'light-green' :
                                                                (calculateHydrationAverage() ) >= 40 ? 'yellow' :
                                                                'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{ calculateHydrationAverage() }}%
                                                                                </h2>
                                                                            </div>
                                                                            <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     
                                                       
                                                            <div class="subtitle mb-2 section" *ngIf="this.questionary">
                                                                <h6>24-Hour Recall Assessment</h6>
                                                            </div>
                                                            <div class="col-md-12 mb-4 d-flex equal-height p-0 section" *ngIf="this.questionary">
                                                                <div class="col-md-9 p-0">
                                                                    <table #fitnessTable class="custom-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Test</th>
                                                                                <th>Score</th>
                                                                                <th>pctl</th>
                                                                                <th>Rating</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    24 Hours Recall Questionaries
                                                                                </td>
                                                                                <td>{{ this.questionary ?? 0 }}</td>
                                                                                <td>{{this.questionary ?? 0}} %</td>
                                                                                <td [ngClass]="(this.questionary) >= 85 ? 'dark-green' :
                                                                (this.questionary) >= 70 ? 'light-green' :
                                                                (this.questionary) >= 40 ? 'yellow' : 'red'"
                                                                                    class="rating">
                                                                                    {{getScoreLabel(this.questionary ??
                                                                                    0)}}
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-3 p-0">
                                                                    <div
                                                                        class="donut-container d-flex align-items-center justify-content-center">
                                                                        <div class="donut-circle" [ngClass]="(calculateQuestionaryAverage()) >= 85 ? 'dark-green' :
                                                    (calculateQuestionaryAverage()) >= 70 ? 'light-green' :
                                                    (calculateQuestionaryAverage()) >= 40 ? 'yellow' : 'red'">
                                                                            <div class="inner-circle">
                                                                                <h2>{{ calculateQuestionaryAverage() }}%
                                                                                </h2>
                                                                            </div>
                                                                            <p class="score-tag" *ngIf="this.selectedStatus == 0">Z-score</p>
                                                                        <p class="score-tag" *ngIf="this.selectedStatus == 1">IBM-score</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="row mb-2 section" id="section8" *ngIf="summarydetails">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-3">
                                                        <h2>Area of Improvement</h2>
                                                    </div>
                                                    <div class="row px-4 col-md-12 Summary">
                                                        <span style="white-space: pre-wrap; word-wrap: break-word;" *ngIf="summarydetails?.[0]?.ausSummary; else noData">
                                                            {{ summarydetails[0].ausSummary }}
                                                        </span>
                                                        <ng-template #noData>
                                                         
                                                        </ng-template>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                  
                        <div class="row mb-2 section" id="section9" *ngIf="summarydetails">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="container p-0">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="card-custom">
                                                    <div class="header-cont mb-3">
                                                        <h2>Recommendation Plan</h2>
                                                    </div>
                                                    <div class="row px-4 col-md-12 Summary">
                                                        <span style="white-space: pre-wrap; word-wrap: break-word;" *ngIf="summarydetails?.[0]?.ausActionPlan; else noData">
                                                            {{ summarydetails[0].ausActionPlan }}
                                                        </span>
                                                        <ng-template #noData>
                                                         
                                                        </ng-template>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                    </div>
                </main>
            </div>

        </div>
       
        <button
        class="back-to-top"
        id="scroll-up-btn"
        [class.show]="windowScrolled"
        (click)="scrollToTop()">
        <i class="fa fa-chevron-up"></i>
      </button>
          
        </div>
    </div>

    <div> <!-- Some content to make the container scrollable -->
</div>

<div class="modal fade" id="summaryModal" tabindex="-1" aria-labelledby="summaryModalLabel" aria-hidden="true"
data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 75%;">
    <div class="modal-content" >
      <div class="modal-header">
        <h5 class="modal-title" id="summaryModalLabel">Update Summary</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
        <div class="form first">
          <div class="details personal">
            <div class="fields">
              <div class="modal-body">
                <div class="input-field">
                  <label>Summary Detail</label>
                  <textarea [(ngModel)]="summary.ausSummary" name="ausSummary" [ngClass]="{
                    'invalid-field': assessmentForm.submitted && !summary.ausSummary,
                    'has-value': summary.ausSummary
                  }" required></textarea>
                  <div *ngIf="assessmentForm.submitted && !summary.ausSummary" class="error-message text-center">
                    Summary is required.
                </div>
                </div>
                <div class="input-field">
                  <label>Action Plan</label>
                  <textarea [(ngModel)]="summary.ausActionPlan" name="ausActionPlan" [ngClass]="{
                    'invalid-field': assessmentForm.submitted && !summary.ausActionPlan,
                    'has-value': summary.ausActionPlan
                  }" required></textarea>
                  <div *ngIf="assessmentForm.submitted && !summary.ausActionPlan" class="error-message text-center">
                    Action Plan is required.
                </div>
                </div>
                <div class="input-field" *ngIf="this.cardio?.coaYoYoTest || this.fitness?.pfaLevel || this.fitness?.pfaVO2 || this.fitness?.pfaSpeed">
                    <label>Endurance</label>
                    <textarea [(ngModel)]="summary.ausEndurance" name="ausEndurance" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausEndurance,
                      'has-value': summary.ausEndurance
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausEndurance?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausEndurance" class="error-message text-center">
                        Endurance is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.fitness?.pfaTTest">
                    <label>Agility</label>
                    <textarea [(ngModel)]="summary.ausAgility" name="ausAgility" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausAgility,
                      'has-value': summary.ausAgility
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausAgility?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausAgility" class="error-message text-center">
                      Agility is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.fitness?.pfaPushups || this.fitness?.pfaSquat || this.fitness?.pfaPartialCurlUp || this.fitness?.pfaGripStrengthRight || this.fitness?.pfaGripStrengthLeft">
                    <label>Strength</label>
                    <textarea [(ngModel)]="summary.ausStrength" name="ausStrength" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausStrength,
                      'has-value': summary.ausStrength
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausStrength?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausStrength" class="error-message text-center">
                      Strength is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.fitness?.pfaSitNReach || this.fitness?.pfaAppleysScratchTest ">
                    <label>Flexibility</label>
                    <textarea [(ngModel)]="summary.ausFlexibility" name="ausFlexibility" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausFlexibility,
                      'has-value': summary.ausFlexibility
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausFlexibility?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausFlexibility" class="error-message text-center">
                        Flexibility is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.fitness?.pfaSingleLegBalanceRightEyesOpen || this.fitness?.pfaSingleLegBalanceLeftEyesOpen || this.fitness?.pfaSingleLegBalanceRightEyesClose || this.fitness?.pfaSingleLegBalanceLeftEyesClose">
                    <label>Balance</label>
                    <textarea [(ngModel)]="summary.ausBalance" name="ausBalance" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausBalance,
                      'has-value': summary.ausBalance
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausBalance?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausBalance" class="error-message text-center">
                        Balance is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.cardio">
                    <label>Cardio</label>
                    <textarea [(ngModel)]="summary.ausCardio" name="ausCardio" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausCardio,
                      'has-value': summary.ausCardio
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausCardio?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausCardio" class="error-message text-center">
                        Cardio is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.cognitive">
                    <label>Cognitive</label>
                    <textarea [(ngModel)]="summary.ausCognitive" name="ausCognitive" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausCognitive,
                      'has-value': summary.ausCognitive
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausCognitive?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausCognitive" class="error-message text-center">
                        Cognitive is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.injury">
                    <label>Injury Prevention</label>
                    <textarea [(ngModel)]="summary.ausInjury" name="ausInjury" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausInjury,
                      'has-value': summary.ausInjury
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausInjury?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausInjury" class="error-message text-center">
                        Injury is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.sports">
                    <label>Isokinetic</label>
                    <textarea [(ngModel)]="summary.ausIsokinetic" name="ausIsokinetic" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausIsokinetic,
                      'has-value': summary.ausIsokinetic
                    }" maxlength="500" required></textarea>
                    <div *ngIf="summary.ausIsokinetic?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                    <div *ngIf="assessmentForm.submitted && !summary.ausIsokinetic" class="error-message text-center">
                        Isokinetic is required.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.sports">
                    <label>Concentric</label>
                    <textarea 
                      [(ngModel)]="summary.ausConcentric" 
                      name="ausConcentric" 
                      [ngClass]="{
                        'invalid-field': assessmentForm.submitted && !summary.ausConcentric,
                        'has-value': summary.ausConcentric
                      }" 
                      maxlength="500" 
                      required>
                    </textarea>
                    <div *ngIf="assessmentForm.submitted && !summary.ausConcentric" class="error-message text-center">
                      Concentric is required.
                    </div>
                    <div *ngIf="summary.ausConcentric?.length >= 500" class="error-message text-center">
                      Maximum length of 500 characters exceeded.
                    </div>
                  </div>
                  
                  <div class="input-field" *ngIf="this.fitness?.pfaSeatedMedBallThrow || this.fitness?.pfaUpperBodyForce || this.fitness?.pfaUpperBodyPower">
                    <label>Upper Body Power</label>
                    <textarea [(ngModel)]="summary.ausUpperBodyPower" name="ausUpperBodyPower" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausUpperBodyPower,
                      'has-value': summary.ausUpperBodyPower
                    }" maxlength="500" required></textarea>
                    <div *ngIf="assessmentForm.submitted && !summary.ausUpperBodyPower" class="error-message text-center">
                        Upper Body Power is required.
                  </div>
                  <div *ngIf="summary.ausUpperBodyPower?.length >= 500" class="error-message text-center">
                    Maximum length of 500 characters exceeded.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.fitness?.pfaStandingBroadJump || this.fitness?.pfaLowerBodyForce || this.fitness?.pfaLowerBodyPower">
                    <label>Lower Body Power</label>
                    <textarea [(ngModel)]="summary.ausLowerBodyPower" name="ausLowerBodyPower" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausLowerBodyPower,
                      'has-value': summary.ausLowerBodyPower
                    }" maxlength="500" required></textarea>
                    <div *ngIf="assessmentForm.submitted && !summary.ausLowerBodyPower" class="error-message text-center">
                        Lower Body Power is required.
                  </div>
                  <div *ngIf="summary.ausLowerBodyPower?.length >= 500" class="error-message text-center">
                    Maximum length of 500 characters exceeded.
                  </div>
                  </div>
                  <div class="input-field" *ngIf="this.nutrition">
                    <label>Nutrition</label>
                    <textarea [(ngModel)]="summary.ausNutrition" name="ausNutrition" [ngClass]="{
                      'invalid-field': assessmentForm.submitted && !summary.ausNutrition,
                      'has-value': summary.ausNutrition
                    }" maxlength="500" required></textarea>
                    <div *ngIf="assessmentForm.submitted && !summary.ausNutrition" class="error-message text-center">
                        Nutrition is required.
                  </div>
                  <div *ngIf="summary.ausNutrition?.length >= 500" class="error-message text-center">
                    Maximum length of 500 characters exceeded.
                  </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      
    </div>
  </div>
</div>
