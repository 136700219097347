import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../services/Notifications.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AssessmentService } from '../../services/assessment.service';
import * as XLSX from 'xlsx';
import { NgToastModule } from 'ng-angular-popup';
import { NgSelectModule } from '@ng-select/ng-select';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridAngular } from 'ag-grid-angular';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfWorkerService } from '../../services/pdf.worker.service';
interface IRow {}
interface Issue {
  serialNo: number;
  fullName: string;
  email: string;
  type: string;
}
@Component({
  selector: 'app-fileupload',
  standalone: true,
  imports: [FormsModule, CommonModule,AgGridAngular, NgToastModule, NgSelectModule],
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() name: any;
  errorMessage: string = '';
  selectedFiles: any;
  selectedAssessment: any;
  assessments: any[] = []; // Declare an array to hold assessments
  assessmentPlayers: any;
  assessmentTests:any;
  assessment:any;
  categoryResponse: any;
  category: any;
  categoryId: any;
  gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onSelectionChanged: this.onSelectionChanged.bind(this),
    getRowHeight: (params) => {
      const typeText = params.data.type || ""; // Get the error text
      const numberOfLines = (typeText.match(/\n/g) || []).length + 1; // Count lines
      return Math.max(50, numberOfLines * 40); // Minimum height of 50px or calculated height
    }
  };
  
  issueColumns = [
    { headerName: 'Row No', field: 'serialNo', sortable: true, filter: true, minWidth: 200, maxWidth: 220 },
    { headerName: 'Name', field: 'fullName', sortable: true, filter: true, minWidth: 200, maxWidth: 250 },
    { headerName: 'Email', field: 'email', sortable: true, filter: true, minWidth: 200, maxWidth: 280 },
    {
      headerName: 'Missing Parameters',
      field: 'type',
      sortable: true,
      filter: true,
      cellStyle: { 'white-space': 'pre-wrap', 'word-wrap': 'break-word' }, // Allow for wrapping
      minWidth: 800, maxWidth: 900
    },
  ];
  themeClass = "ag-theme-quartz";
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
  highlightedColumns: Record<number, Set<number>> = {};
  issueData: any[] = []; // Your grid data
  selectedRows: any;
  emptySheetsMessage: string = '';
  selectedTab: string = 'Cardio';
  preview: any;
  fileData: any[] = []; // To store parsed Excel data
  displayedColumns: string[] = []; // To store table headers
  showTable = false;
  availableTabs: string[] = [];
  workbook: XLSX.WorkBook | null = null;
  sheetNames: string[] = [];
  isLoading:boolean= false;
  filterData: any[] =[];
 
  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private router: Router,private progressService: PdfWorkerService,
    private assessmentService: AssessmentService,private cdr: ChangeDetectorRef
  ) {}
 
  ngOnInit() {
    this.assessmentService.getcategories().subscribe(
      response => {
        this.categoryResponse = response;
        if (this.name === 'Sports Talent Identification Assessment') {
          this.category = this.categoryResponse.filter((item: any) => item.catName === this.name);
          this.categoryId = this.category[0].catId;
          this.fetchAssessments(); 
        } else {
          this.category = this.categoryResponse.filter((item: any) => item.catName === this.name);
          this.categoryId = this.category[0].catId;
          this.saifetchAssessments();
        }
      },
      error => {
        console.error("Error fetching categories:", error);
      }
    );
  }  
  onSelectionChanged(event: any) {
    this.selectedRows = event.api.getSelectedRows();
  }
 
  fetchAssessments() {
    this.assessmentService.getAssessmentBycategory(this.categoryId).subscribe(
      response => {
        this.assessments = response;
        const currentDate = new Date();
        this.assessments = this.assessments.filter((item: any) => {
          const assessmentDate = new Date(item.asmDate); // Parse the asmDate
          return item.asmStatus === 0 && assessmentDate <= currentDate; // Check conditions
        });
        const uniqueAssessments = new Map<number, any>();
 
        this.assessments.forEach((item: any) => {
          if (!uniqueAssessments.has(item.asmId)) {
            uniqueAssessments.set(item.asmId, {
              asmId: `${item.asmId}`,
              asmName: `${item.asmName}`,
              TeamName: `${item.teamName}`,
              CoachName: `${item.coachName}`,
              OrganizationName: `${item.orgName}`,
              Description: `${item.asmDescription}`,
              category:`${item.testCategory}`,
              AssessmentDate: new Date(item.asmDate).toLocaleDateString('en-US'),
              testNames: [item.testName],
              CoachId: item.asmCoach,
              id: item.asmId,
              asmStatus: item.asmStatus,
            });
          } else {
            const existingAssessment = uniqueAssessments.get(item.asmId);
            if (!existingAssessment.testNames.includes(item.testName)) {
              existingAssessment.testNames.push(item.testName);
            }
          }
        });
        this.assessments = Array.from(uniqueAssessments.values());
        console.log("this.assessments:",this.assessments)
      },
      error => {
        console.error('Error submitting assessment', error);
      }
    );
  }
  saifetchAssessments() {
    this.assessmentService.getAssessmentBycategory(this.categoryId).subscribe(
      response => {
        this.assessments = response;
        const currentDate = new Date();
        this.assessments = this.assessments.filter((item: any) => {
          const assessmentDate = new Date(item.asmDate); // Parse the asmDate
          return item.asmStatus === 0 && assessmentDate <= currentDate; // Check conditions
        });
        const uniqueAssessments = new Map<number, any>();
 
        this.assessments.forEach((item: any) => {
          if (!uniqueAssessments.has(item.asmId)) {
            uniqueAssessments.set(item.asmId, {
              asmId: `${item.asmId}`,
              asmName: `${item.asmName}`,
              TeamName: `${item.teamName}`,
              CoachName: `${item.coachName}`,
              OrganizationName: `${item.orgName}`,
              Description: `${item.asmDescription}`,
              category:`${item.testCategory}`,
              AssessmentDate: new Date(item.asmDate).toLocaleDateString('en-US'),
              testNames: [item.testName],
              CoachId: item.asmCoach,
              id: item.asmId,
              asmStatus: item.asmStatus,
            });
          } else {
            const existingAssessment = uniqueAssessments.get(item.asmId);
            if (!existingAssessment.testNames.includes(item.testName)) {
              existingAssessment.testNames.push(item.testName);
            }
          }
        });
        this.assessments = Array.from(uniqueAssessments.values());
        console.log("this.assessments:",this.assessments)
      },
      error => {
        console.error('Error submitting assessment', error);
      }
    );
  }
 
  onAssessmentSelect() {
    this.assessmentService.getTestByAssessment(this.selectedAssessment).subscribe(response => {
      this.assessmentTests = response;
    });
    this.assessmentService.getPlayerByAssessmentId(this.selectedAssessment).subscribe(response => {
      this.assessmentPlayers = response;
    });
  }
  ngAfterViewInit() {
    this.cdr.detectChanges(); // Ensure view updates
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    this.preview = event.target.files;

    if (this.preview && this.preview.length > 0) {
      const file = this.preview[0]; // Assuming a single file
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        this.workbook = XLSX.read(data, { type: 'array' });

        if (this.workbook) {
          this.sheetNames = this.workbook.SheetNames;
          if (this.sheetNames.length > 0) {
            this.selectedTab = this.sheetNames[0]; // Default to the first sheet
            this.loadSheetData(this.selectedTab);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  loadSheetData(sheetName: string) {
    if (this.workbook) {
      const sheet = this.workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (sheetData.length) {
        this.displayedColumns = sheetData[0] as string[]; // First row as headers
        this.fileData = sheetData.slice(1); // Remaining rows as data
      }
    }
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.loadSheetData(tab);

    const existingModal = document.getElementById('filePreviewModal');
    if (existingModal) {
      const modalInstance = new (window as any).bootstrap.Modal(existingModal);
      modalInstance.hide();
    }

    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach(backdrop => backdrop.remove());

    this.previewFile();
  }
 
  previewFile() {
    if (this.preview && this.preview.length > 0) {
      const file = this.preview[0]; // Assuming a single file
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        const sheetNames = workbook.SheetNames;
        const matchingSheetName = sheetNames.find(sheetName => sheetName === this.selectedTab);
  
        if (matchingSheetName) {
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[matchingSheetName], { header: 1 });
  
          if (sheetData.length) {
            this.displayedColumns = sheetData[1] as string[]; // Set columns
            this.fileData = sheetData.slice(2); // Set file data
            this.highlightedColumns = this.getHighlightedColumns();
            this.showTable = true;
  
            // Filter and map issueData based on selectedTab and remove rows with no matching data
            this.filterData = this.issueData
              .map(issue => {
                if (issue.type) {
                  // Split the type string by newline and filter out the specific tab-related part
                  const typeLines = issue.type.split('\n');
                  const filteredType = typeLines.filter((line: any) => line.toLowerCase().includes(this.selectedTab.toLowerCase())).join('\n');
                  
                  // Only return the issue if it contains relevant type information for the selected tab
                  if (filteredType) {
                    return { ...issue, type: filteredType };
                  }
                }
                // Return null if no relevant type found, which will be removed in the next step
                return null;
              })
              .filter(issue => issue !== null); // Remove any null values (empty or irrelevant rows)
  
            const errorModal = document.getElementById('filePreviewModal')!;
            const modal = new (window as any).bootstrap.Modal(errorModal);
            modal.show();
          }
        } else {
          console.error(`No matching sheet found for tab: ${this.selectedTab}`);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }
  

  getHighlightedColumns() {
    const highlightedColumns: Record<number, Set<number>> = {};
  
    this.issueData.forEach(issue => {
      this.fileData.forEach((row, rowIndex) => {
        // Check if the row matches the issue data
        if (row[0]?.trim() === issue.fullName?.trim() && row[1]?.trim() === issue.email?.trim()) {
          // Extract missing fields from the issue type, cleaning up the "Must be an integer" and "Must be an integer and range between 0 to 3" parts
          const missingFields = issue.type
            .split("\n")
            .flatMap((line: string) => {
              const [, fields] = line.split(":");
              return fields ? fields.split(",").map(field => field.trim()
                .replace(' Must be an integer', '') // Remove "Must be an integer"
                .replace(' Must be an integer and range between 0 to 3', '') // Remove "Must be an integer and range between 0 to 3"
              ) : [];
            });
  
          missingFields.forEach((field: string) => {
            const trimmedColumns = this.displayedColumns.map(column => column.trim());
            const columnIndex = trimmedColumns.indexOf(field);
          
            console.log(`Processing Field: "${field}" | Found at Index: ${columnIndex}`);
            
            if (columnIndex !== -1) {
              if (!highlightedColumns[rowIndex]) {
                highlightedColumns[rowIndex] = new Set<number>();
              }
              highlightedColumns[rowIndex].add(columnIndex);
            } else {
              console.warn(`Field "${field}" not found in displayedColumns:`, trimmedColumns);
            }
          });
          
        }
      });
    });
  
    console.log('Highlighted Columns Map:', highlightedColumns);
    return highlightedColumns;
  }
  
  isHighlighted(rowIndex: number, colIndex: number): boolean {
    const isHighlighted =
      this.highlightedColumns[rowIndex] &&
      this.highlightedColumns[rowIndex].has(colIndex);
  
    // Debugging to ensure correct behavior
    if (isHighlighted) {
      console.log(`Highlighting row ${rowIndex}, column ${colIndex}`);
    } else {
      console.log(`No highlight for row ${rowIndex}, column ${colIndex}`);
    }
  
    return isHighlighted;
  }
  

  ngOnDestroy() {
    const existingModal = document.getElementById('filePreviewModal');
    if (existingModal) {
      const modalInstance = new (window as any).bootstrap.Modal(existingModal);
      modalInstance.hide();
    }

    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach(backdrop => backdrop.remove());
  }
  
  getValue(row: any, index: number): string {
    switch (index) {
      case 0:
        return row.fullName;
      case 1:
        return row.email;
      default:
        return row.error;
    }
  }
  
  isErrorField(row: any, col: string): boolean {
    // Match the error message with the header column
    if (col === 'Error') {
      return !!row.error;
    }
    return false;
  }
  onUpload() {
    const formData = new FormData();
    this.isLoading=true;
    this.progressService.setIsDownloading(true);
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      // Perform filename validation only for 'Sports Talent Identification'
      if (this.name === 'Sports Talent Identification Assessment' || this.name === 'SAI Health & Fitness Assessment') {
        const matchingAssessment = this.assessments.find(
          (item: any) => item.asmId == Number(this.selectedAssessment)
        );        
        this.progressService.setProgress(50);
        const assessmentNameInFile = matchingAssessment.asmName.replace(/[\s_-]/g, '');
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          if (!file.name.includes(assessmentNameInFile)) {
            this.notify.failed(`Please select a valid file`);
            return;
          }
        }
      }
      const fileKey = (this.name === 'Sports Talent Identification Assessment' || this.name === 'levelone' || this.name === 'SAI Health & Fitness Assessment') ? 'file' : 'files';
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append(fileKey, this.selectedFiles[i], this.selectedFiles[i].name);
      }
      this.progressService.setProgress(70);
      let uploadUrl = '';
      if (this.name === 'Sports Talent Identification Assessment' || this.name === 'SAI Health & Fitness Assessment') {
        uploadUrl = `${environment.BaseUrl}${environment.uploadAssessment}${this.selectedAssessment}`;
      } else if (this.name !== 'levelone') {
        uploadUrl = `${environment.BaseUrl}${environment.UploadFiles}`;
      } else {
        uploadUrl = `${environment.BaseUrl}${environment.LevelOnefileUpload}`;
      }
  
      this.http.post(uploadUrl, formData, {
        headers: this.assessmentService.getAuthHeaders(),
        responseType: 'text'
      }).subscribe(
        (response: string) => {
          this.progressService.setProgress(100);
          if(response == "Data saved successfully.") {
            this.progressService.setIsDownloading(false);
            this.progressService.setProgress(0);
            this.isLoading=false;   
            this.notify.success(response);
          } else {
            this.progressService.setIsDownloading(false);
            this.isLoading=false; 
            this.errorMessage = response;
            if (response.includes('The following sheets had missing fields')) {
              const emptySheets = this.parseEmptySheets(response);
              this.parseErrorResponse(response); // Trigger error parsing when missing fields message is found
              if (this.issueData.some(item => item.type)) {
                this.previewFile();
                // this.emptySheetsMessage = `Empty Sheets: ${emptySheets}`;
                // const errorModal = document.getElementById('filePreviewModal')!;
                // const modal = new (window as any).bootstrap.Modal(errorModal);
                // modal.show();
              } else {             
                if (emptySheets) {
                  this.isLoading=false; 
                  // Display this in your UI as required
                  this.emptySheetsMessage = `Empty Sheets: ${emptySheets}`;
                  this.errorMessage = `Data saved successfully and Empty Sheets: ${emptySheets}`;
                  const errorModal = document.getElementById('errorModal')!;
                  const modal = new (window as any).bootstrap.Modal(errorModal);
                  modal.show(); // For other errors, still parse but may not show modal
                }else{
                  this.notify.success(response);
                }
              }
              
            } else {
              this.isLoading=false; 
              const errorModal = document.getElementById('errorModal')!;
              const modal = new (window as any).bootstrap.Modal(errorModal);
              modal.show(); // For other errors, still parse but may not show modal
            }
          }
  
          if (this.name === 'levelone') {
            setTimeout(() => this.router.navigate(['levelonelist']), 1000);
          }
        },
        (error: any) => {
          if (error.error.includes('Some values are outside the allowed range (1-3)')) {
            this.errorMessage = error.error;
            const errorModal = document.getElementById('errorModal')!;
            const modal = new (window as any).bootstrap.Modal(errorModal);
            modal.show(); // For other errors, still parse but may not show modal
          } else {
            this.notify.failed('File upload failed!' + error.error);
          }
        }
      );
    } else {
      this.notify.failed('No files selected!');
    }
  }
  
  parseErrorResponse(response: string) {
    this.issueData = []; // Reset issue data

    // Define section headers
    const sectionHeaders = [
      "Cardio",
      "Cognitive",
      "PhysicalFitness",
      "InjuryPreventionAnalysis",
      "SportsPerFormanceAnalysis",
      "Nutritional",
      "24 Hours Recall questionaire",
      "Hydration questionaire",
      "Sleep questionnaire"
    ];

    // Create regex to capture sections dynamically
    const sectionRegex = new RegExp(
      `(${sectionHeaders.map((header) => `${header}:`).join('|')})([\\s\\S]*?)(?=${sectionHeaders.map((header) => `${header}:`).join('|')}|$)`,
      'g'
    );

    const sections: { name: string; content: string }[] = [];
    let match;

    // Extract all sections using regex
    while ((match = sectionRegex.exec(response)) !== null) {
      const name = match[1]?.replace(':', '').trim(); // Extract section name
      const content = match[2]?.trim(); // Extract content
      sections.push({ name, content });
    }

    const playerErrors: { [key: string]: any } = {};

    // Parse each section
    sections.forEach((section) => {
      const rows = section.content.split('|');
      rows.forEach((row) => {
        const rowMatch = row.match(/Row (\d+):/);
        const rowNumber = rowMatch ? rowMatch[1] : '';
        const rowDetails = row.replace(/Row \d+:/, '').trim();
        const fields = rowDetails.split(',');

        if (fields.length >= 3) {
          const fullName = fields[0]?.trim();
          const email = fields[1]?.trim();
          const id = rowNumber;
          const errorMessage = fields.slice(3).join(', ').trim();

          const key = `${fullName}-${email}`;

          if (!playerErrors[key]) {
            playerErrors[key] = { fullName, email, id, types: [] };
          }

          if (errorMessage) {
            if(this.selectedTab == "InjuryPreventionAnalysis"){
              playerErrors[key].types.push(`${section.name}: ${errorMessage} Must be an integer and range between 0 to 3`);
            }
            else{
              playerErrors[key].types.push(`${section.name}: ${errorMessage} Must be an integer`);
            }
          }
        }
      });
    });

    // Prepare issueData array with combined errors and index
    this.issueData = Object.values(playerErrors)
      .map(item => ({
        serialNo: parseInt(item.id, 10),
        fullName: item.fullName,
        email: item.email,
        type: item.types
          .filter((type: any) => type)
          .map((type: any, index: number) => `${index + 1}. ${type}`)
          .join('\n')
      }))
      .filter(item => item.type)
      .sort((a, b) => a.serialNo - b.serialNo);
  }
  
  parseEmptySheets(response: string): string {
    // Updated regex to ensure it matches the exact part of the response.
    const emptySheetsRegex = /The following sheets were empty: (.*?)(?=\.|$)/;
    const match = response.match(emptySheetsRegex);
    return match ? match[1].trim() : '';
  }
  
 
  closeModal() {
    this.selectedAssessment = null;
    this.selectedFiles = null;
  }
 
 
  downloadTemplate(fileName: string) {
    if (this.name === 'Sports Talent Identification Assessment') {
      const fileUrl = 'assets/Templates/assessmentForm_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe(async (blob: Blob) => {
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
 
        // Define sheet names mapping based on testCategory
        const sheetMapping: { [key: number]: string } = {
          4: 'PhysicalFitness',
          5: 'Nutritional',
          7: 'Cardio',
          8: 'Cognitive',
          10: 'InjuryPreventionAnalysis',
          11: 'SportsPerFormanceAnalysis'
        };
 
        const categoryTestsToRemove = [
          { category: 5, sheetName: 'Nutritional' },
          { category: 12, sheetName: '24 Hours Recall questionaire' },
          { category: 13, sheetName: 'Hydration questionaire' },
          { category: 14, sheetName: 'Sleep questionnaire' }
        ];
       
        // Iterate through each category and remove the worksheet if the category is not in assessmentTests
        categoryTestsToRemove.forEach(({ category, sheetName }) => {
          const testsForCategory = this.assessmentTests.filter((test: any) => test.testCategory === category);
          if (testsForCategory.length === 0) {
            delete workbook.Sheets[sheetName];
            workbook.SheetNames = workbook.SheetNames.filter(name => name !== sheetName);
          }
        });        
 
        // Define column letters for A, B, C
        const athleteNameColumn = 'A';
        const emailColumn = 'B';
        const athleteIdColumn = 'C';
        const startRow = 3;
 
        // Iterate over sheetMapping and process each relevant worksheet
        for (const [category, sheetName] of Object.entries(sheetMapping)) {
          const worksheet = workbook.Sheets[sheetName];
          if (worksheet) {
            // Track existing players in the worksheet to avoid duplicates
            const existingPlayers = new Set<string>();
            let rowIndex = startRow;
            let playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            while (worksheet[playerIdCellAddress]) {
              existingPlayers.add(worksheet[playerIdCellAddress].v.toString());
              rowIndex++;
              playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            }
 
            // Add athlete details if not already present
            this.assessmentPlayers.forEach((player: any) => {
              if (!existingPlayers.has(player.aplPlayer.toString())) {
                const fullNameCellAddress = `${athleteNameColumn}${rowIndex}`;
                const emailCellAddress = `${emailColumn}${rowIndex}`;
                const playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
                XLSX.utils.sheet_add_aoa(worksheet, [[player.usrFullName, player.usrEmail, player.aplPlayer]], { origin: fullNameCellAddress });
                existingPlayers.add(player.aplPlayer.toString());
                rowIndex++;
              }
            });
 
            // Track added tests for the category to avoid duplicates
            const addedTests = new Set<string>();
            const testsForCategory = this.assessmentTests.filter((test: any) => test.testCategory === +category);
            let lastColumnIndex = XLSX.utils.decode_col(athleteIdColumn) + 1; // Start after column C
            const initialLastColumnIndex = lastColumnIndex;
 
            testsForCategory.forEach((test: any) => {
              if (!addedTests.has(test.testName)) {
                const newColumnLetter = XLSX.utils.encode_col(lastColumnIndex);
                const headerCellAddress = `${newColumnLetter}2`;
 
                // Set header for testColumnName
                worksheet[headerCellAddress] = { v: test.testName + (test.testUnit ? ` ${test.testUnit}` : '') };
                XLSX.utils.sheet_add_aoa(worksheet, [[test.testName + (test.testUnit ? ` ${test.testUnit}` : '')]], { origin: headerCellAddress });
 
                // Populate each row with placeholders (or default value if required)
                rowIndex = startRow;
                this.assessmentPlayers.forEach(() => {
                  const cellAddress = `${newColumnLetter}${rowIndex}`;
                  worksheet[cellAddress] = { v: '' };
                  rowIndex++;
                });
 
                addedTests.add(test.testName);
                lastColumnIndex++;
              }
            });
 
            // Check if any new columns were added; remove the worksheet if none
            if (lastColumnIndex === initialLastColumnIndex) {
              delete workbook.Sheets[sheetName];
              workbook.SheetNames = workbook.SheetNames.filter(name => name !== sheetName);
            }
          }
        }
 
        // Add athlete details to any additional worksheets (not in sheetMapping)
        workbook.SheetNames.forEach(sheetName => {
          if (!Object.values(sheetMapping).includes(sheetName)) {
            const worksheet = workbook.Sheets[sheetName];
            if (worksheet) {
              let rowIndex = startRow;
              const existingPlayers = new Set<string>();
              let playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
              while (worksheet[playerIdCellAddress]) {
                existingPlayers.add(worksheet[playerIdCellAddress].v.toString());
                rowIndex++;
                playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
              }
 
              // Add athlete details if not already present
              this.assessmentPlayers.forEach((player: any) => {
                if (!existingPlayers.has(player.aplPlayer.toString())) {
                  const fullNameCellAddress = `${athleteNameColumn}${rowIndex}`;
                  const emailCellAddress = `${emailColumn}${rowIndex}`;
                  const playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
                  XLSX.utils.sheet_add_aoa(worksheet, [[player.usrFullName, player.usrEmail, player.aplPlayer]], { origin: fullNameCellAddress });
                  existingPlayers.add(player.aplPlayer.toString());
                  rowIndex++;
                }
              });
            }
          }
        });
 
        // Create a Blob from the modified workbook and trigger the download
        const modifiedBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], {
          type: 'application/octet-stream'
        });
        const matchingAssessment = this.assessments.find(
          (item: any) => item.asmId == Number(this.selectedAssessment)
        );
 
       if (matchingAssessment) {
          this.assessment = matchingAssessment.asmName.replace(/[\s_-]/g, '');
        } else {
          this.assessment = '';
        }
        const filename = `${this.assessment}_Template.xlsx`;
        saveAs(modifiedBlob, filename);
      }, error => {
        console.error('Error downloading or modifying file', error);
      });
    } else {
      const fileUrl = 'assets/Templates/assessmentForm_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe(async (blob: Blob) => {
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
 
        // Define sheet names mapping based on testCategory
        const sheetMapping: { [key: number]: string } = {
          9:'LevelOne'
        };
 
        const categoryTestsToRemove = [
          { category: 5, sheetName: 'Nutritional' },
          { category: 12, sheetName: '24 Hours Recall questionaire' },
          { category: 13, sheetName: 'Hydration questionaire' },
          { category: 14, sheetName: 'Sleep questionnaire' }
        ];
       
        // Iterate through each category and remove the worksheet if the category is not in assessmentTests
        categoryTestsToRemove.forEach(({ category, sheetName }) => {
          const testsForCategory = this.assessmentTests.filter((test: any) => test.testCategory === category);
          if (testsForCategory.length === 0) {
            delete workbook.Sheets[sheetName];
            workbook.SheetNames = workbook.SheetNames.filter(name => name !== sheetName);
          }
        });        
 
        // Define column letters for A, B, C
        const athleteNameColumn = 'A';
        const emailColumn = 'B';
        const athleteIdColumn = 'C';
        const startRow = 3;
 
        // Iterate over sheetMapping and process each relevant worksheet
        for (const [category, sheetName] of Object.entries(sheetMapping)) {
          const worksheet = workbook.Sheets[sheetName];
          if (worksheet) {
            // Track existing players in the worksheet to avoid duplicates
            const existingPlayers = new Set<string>();
            let rowIndex = startRow;
            let playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            while (worksheet[playerIdCellAddress]) {
              existingPlayers.add(worksheet[playerIdCellAddress].v.toString());
              rowIndex++;
              playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            }
 
            // Add athlete details if not already present
            this.assessmentPlayers.forEach((player: any) => {
              if (!existingPlayers.has(player.aplPlayer.toString())) {
                const fullNameCellAddress = `${athleteNameColumn}${rowIndex}`;
                const emailCellAddress = `${emailColumn}${rowIndex}`;
                const playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
                XLSX.utils.sheet_add_aoa(worksheet, [[player.usrFullName, player.usrEmail, player.aplPlayer]], { origin: fullNameCellAddress });
                existingPlayers.add(player.aplPlayer.toString());
                rowIndex++;
              }
            });
 
            // Track added tests for the category to avoid duplicates
            const addedTests = new Set<string>();
            const testsForCategory = this.assessmentTests.filter((test: any) => test.testCategory === +category);
            let lastColumnIndex = XLSX.utils.decode_col(athleteIdColumn) + 1; // Start after column C
            const initialLastColumnIndex = lastColumnIndex;
 
            testsForCategory.forEach((test: any) => {
              if (!addedTests.has(test.testName)) {
                const newColumnLetter = XLSX.utils.encode_col(lastColumnIndex);
                const headerCellAddress = `${newColumnLetter}2`;
 
                // Set header for testColumnName
                worksheet[headerCellAddress] = { v: test.testName + (test.testUnit ? ` ${test.testUnit}` : '') };
                XLSX.utils.sheet_add_aoa(worksheet, [[test.testName + (test.testUnit ? ` ${test.testUnit}` : '')]], { origin: headerCellAddress });
 
                // Populate each row with placeholders (or default value if required)
                rowIndex = startRow;
                this.assessmentPlayers.forEach(() => {
                  const cellAddress = `${newColumnLetter}${rowIndex}`;
                  worksheet[cellAddress] = { v: '' };
                  rowIndex++;
                });
 
                addedTests.add(test.testName);
                lastColumnIndex++;
              }
            });
 
            // Check if any new columns were added; remove the worksheet if none
            if (lastColumnIndex === initialLastColumnIndex) {
              delete workbook.Sheets[sheetName];
              workbook.SheetNames = workbook.SheetNames.filter(name => name !== sheetName);
            }
          }
        }
 
        // Add athlete details to any additional worksheets (not in sheetMapping)
        workbook.SheetNames.forEach(sheetName => {
          if (!Object.values(sheetMapping).includes(sheetName)) {
            const worksheet = workbook.Sheets[sheetName];
            if (worksheet) {
              let rowIndex = startRow;
              const existingPlayers = new Set<string>();
              let playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
              while (worksheet[playerIdCellAddress]) {
                existingPlayers.add(worksheet[playerIdCellAddress].v.toString());
                rowIndex++;
                playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
              }
 
              // Add athlete details if not already present
              this.assessmentPlayers.forEach((player: any) => {
                if (!existingPlayers.has(player.aplPlayer.toString())) {
                  const fullNameCellAddress = `${athleteNameColumn}${rowIndex}`;
                  const emailCellAddress = `${emailColumn}${rowIndex}`;
                  const playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
                  XLSX.utils.sheet_add_aoa(worksheet, [[player.usrFullName, player.usrEmail, player.aplPlayer]], { origin: fullNameCellAddress });
                  existingPlayers.add(player.aplPlayer.toString());
                  rowIndex++;
                }
              });
            }
          }
        });
 
        // Create a Blob from the modified workbook and trigger the download
        const modifiedBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], {
          type: 'application/octet-stream'
        });
        const matchingAssessment = this.assessments.find(
          (item: any) => item.asmId == Number(this.selectedAssessment)
        );
 
       if (matchingAssessment) {
          this.assessment = matchingAssessment.asmName.replace(/[\s_-]/g, '');
        } else {
          this.assessment = '';
        }
        const filename = `${this.assessment}_Template.xlsx`;
        saveAs(modifiedBlob, filename);
      }, error => {
        console.error('Error downloading or modifying file', error);
      });
    }
  }
  downloadPDF(): void {
    const pdf = new jsPDF('p', 'mm', 'a4');

    // Set the table data
    const tableData = this.issueData.map(item => [item.serialNo,item.fullName, item.email,item.type]);

    // Define column headers
    const headers = [['Row No','Name', 'Email','Missing Parameters']];

    // Add the auto table to the PDF
    autoTable(pdf, {
      head: headers,
      body: tableData,
      startY: 20, // Start position for the table
      theme: 'grid', // You can customize the theme
      styles: { cellPadding: 5, fontSize: 10 },
    });

    // Save the PDF
    pdf.save('QuestionarieError.pdf');
  }
}
 