import { CommonModule } from '@angular/common';
import { Component, Inject, PLATFORM_ID ,Input, Output, EventEmitter, SimpleChanges, NgZone, OnChanges, ChangeDetectorRef} from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import c3 from 'c3';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
 
@Component({
  selector: 'app-injury-prevention-dashboard',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, RouterModule, FormsModule,NgSelectModule],
  templateUrl: './injury-prevention-dashboard.component.html',
  styleUrl: './injury-prevention-dashboard.component.css'
})
export class InjuryPreventionDashboardComponent implements OnChanges{
  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any = null;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>();
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = 'Level Onenew'; // Default value
  public imageUrls: any[] = [];
  reportTitle: string = 'OverAll Performers In Injury Prevention'; // Initialize title
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  InjuryPerformers: any[] = [];
  admitcardReport: any;
  msg: any;
  nutResponse: any;
  ipaResponse: any;
  ipaPerformers: any;
  averageOverallZScore: number = 0;
  Orgainsation: any;
  selectedOrg: string = '';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  PhysicalFitness: boolean = false;
  Trainer: any;
  teams:any;
  fitnessOverallPercentage: any;
  Id: any;
  ImageUrl: any;
  showImage: boolean=false;
  image: any;
  sciStaff: boolean = false;
  athletes: any;
  injuryCount: any;
  IsAssessor: boolean = false;
  selectOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Excellent' },
    { value: 2, label: 'Good' },
    { value: 3, label: 'Average' },
    { value: 4, label: 'Poor' },
    ];
  aiData: any;
  playerData: any;
  
  constructor(
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone,private router:Router
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }
  onStatusChange() {
    this.loadTopPerformersData();
  }
  onFitnessStatusChange() {
    this.loadInjuryPerformersData();
  }
  async ngOnInit(): Promise<void> {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.PhysicalFitness=this.privilege?.isPhysicalFitness ?? false;
    this.user = this.userService.getUser();
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.IsAssessor = this.privilege.isAssessor ?? false;
 this.getPlayer();
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.isLoading = true;
    if (!this.Isplayer) {
      await this.getallinjurypreventionByAsm();
      this.loadInjuryPerformersData();
      this.isLoading = false;
    }
  }
  getPlayer() {
    this.orgId = this.OrgAdmin ? this.org : this.orgId;

    if (this.orgId) {
      this.assessmentService.getAthleteByOrg(this.orgId).subscribe(
        (athletesData) => {
          this.athletes = athletesData;
          if (this.Coach) {
            this.injuryCount  = this.athletes.filter(
              (item: any) => item.asiCoach === this.UserId
            ).length;
          } else {
            // For OrgAdmin or other users, handle count based on team selection
            if (this.selectedTeamId != '0') {
              this.injuryCount  = this.athletes.filter(
                (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
              ).length;
            } else {
              // If no team is selected, count all athletes
              this.injuryCount  = this.athletes.length;
            }
          }
        
        },
        (error) => {
          console.error('Error fetching athletes:', error);
         
        }
      );
    } else {
      console.error('Organization ID is missing.');
      this.injuryCount = this.athletes.length; 
    }
  }
  orgStudents() {
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
      });
    }
  }
  sendMessage() {
      this.apiResponse = this.playerData;
      this.msg = " give me result with their ipaAthlete,athleteFullName,sports and result in both text and json format";
    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
   `;
  //  console.log(combinedMessage)
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          // Extract JSON array or object from the chat response
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            this.aiData = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            this.chatData.emit(this.chat);
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          this.chatData.emit(this.chat);
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
 
      this.isLoading = false;
 
      // Update each performer with matching assessment data and image URL
      this.athletedata.forEach((performer: any) => {
          const matchingAssessment = this.ipaResponse.find((item: any) => item.ipaAthlete === performer.ipaAthlete)
          if (matchingAssessment) {
            Object.assign(performer, matchingAssessment);
            this.getProfileImage(performer.ipaAthlete)
            this.getallinjurypreventionByAsm();
            this.loadInjuryPerformersData();
          } else {
            console.error(`No matching assessment found`);
          }
       
      });   
        this.aiData.forEach((result: any) => {
        const matchingData = this.ipaResponse.find((item: any) =>  item.ipaAthlete === result.ipaAthlete)
           if (matchingData) {
             Object.assign(result, matchingData);
           } else {
             console.error(`No matching data found`);
           }
       });
       this.chatData.emit(this.aiData ?? this.chat);
    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });
 
  }
 
  getFilteredChat(): string {
    // Assuming "JSON Format" starts from a specific keyword you want to hide
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  // Return text before "JSON Format"
    }
    return this.chat;  // If "JSON Format" is not found, return the full text
  }
 
  loadTopPerformersData() {
    if (this.athletedata === undefined) {
      this.Performers = this.Response;
      if (this.selectedStatus == 0) {
        this.Performers = this.Performers.sort((a: any, b: any) => b.ipaTotalScore - a.ipaTotalScore)
          .slice(0, 5);
        this.header = "Top Performers";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.ipaAthlete)
        });
      } else {
        this.Performers = this.Performers
          .sort((a: any, b: any) => a.ipaTotalScore - b.ipaTotalScore)
          .slice(0, 5);
        this.header = "Under Performers";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.ipaAthlete)
        });
      }
    } else {
      this.Performers = this.athletedata;
      this.Performers.forEach((performer: any) => {
        this.getProfileImage(performer.ipaAthlete)
      });
    }
  }
  loadInjuryPerformersData() {
    this.header = "";
    if (this.selectedStatus == 0) {
      this.InjuryPerformers = this.athleteReport
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      // Show all performers sorted by overallZScore
      this.topPerformer = this.InjuryPerformers.slice(0, 5);
      this.header = "All";
    } else if (this.selectedStatus == 1) {
      this.InjuryPerformers = this.athleteReport.filter((performer: any) => performer.overallZScore > 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      this.header = "Excellent";
    } else if (this.selectedStatus == 2) {
      this.InjuryPerformers = this.athleteReport.filter((performer: any) => performer.overallZScore > 70 && performer.overallZScore <= 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      this.header = "Good";
    } else if (this.selectedStatus == 3) {
      this.InjuryPerformers = this.athleteReport.filter((performer: any) => performer.overallZScore > 40 && performer.overallZScore <= 70).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      this.header = "Average";
    } else {
      this.InjuryPerformers = this.athleteReport.filter((performer: any) => performer.overallZScore <= 40).sort((a: any, b: any) => a.overallZScore - b.overallZScore);
      this.header = "Poor";

    }
    console.log(this.InjuryPerformers,"injury")
   if(this.InjuryPerformers.length > 0){
    this.InjuryPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.ipaAthlete)
    });
   }

    console.log(this.InjuryPerformers,"injury-img")
  }
 
  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
          const athleteEntry = this.InjuryPerformers.find((entry: { ipaAthlete: any; }) => entry.ipaAthlete === userId);
          if (athleteEntry) {
            athleteEntry.image = this.image;
          } else {
            // this.InjuryPerformers.push({ ipaAthlete: userId, image: this.image });
          }
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.showImage = true;
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
            const athleteEntry = this.InjuryPerformers.find((entry: { ipaAthlete: any; }) => entry.ipaAthlete === userId);
            if (athleteEntry) {
              athleteEntry.image = this.image;
            } else {
              // this.InjuryPerformers.push({ ipaAthlete: userId, image: this.image });
            }
            this.cdr.detectChanges();
          }
        );
      }
    );
  }
 
  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
 
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }
 
  async selectOrganisation() {
    this.org = this.selectedOrg;
    this.orgStudents();
    this.isLoading = false;
    if (this.selectedOption === 'Injury Prevention') {
      await this.getallinjurypreventionByAsm();
      this.loadInjuryPerformersData();
      this.isLoading = false;
    }
  }
 
  async selectTrainer() {
    this.trainerId = this.selectedTrainer;
    this.isLoading = true;
    if (this.selectedOption === 'Injury Prevention') {
      await this.getallinjurypreventionByAsm();
      this.loadInjuryPerformersData();
      this.isLoading = false;
    }
  }
getCircleColor(value: number): string {
  if (value === 3) {
    return '#28a745'; // Green for 100% (value 3)
  } else if (value >= 2) {
    return '#ffc107'; // Yellow for 66% (value 2)
  } else if (value >= 1) {
    return '#ff8800'; // Orange for 33% (value 1)
  } else {
    return '#ff5521'; // Default color (less than 1)
  }
}
 
 
 
onTeamSelected(selectedValue: any): void { 
 
    // Set selectedTeamId based on the selected value
    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;
 
    // Fetch cognitive report data and load the top performers
    this.getallinjurypreventionByAsm().then(() => {
      // Load top performers with the updated selected team ID
      this.loadInjuryPerformersData();
    });
  }
  getInjuryAIData(){
    this.assessmentService.getAllInjuryForAI().subscribe(
      (response) => {
        this.playerData=response
        if(this.orgId != '0'){
          this.playerData=this.playerData.filter(
            (item: any) => item.usrOrganization === this.orgId
          );
          if (this.selectedTeamId != '0'){
            this.playerData=this.playerData.filter(
              (item: any) => item.asiCurrentClubTeam == this.selectedTeamId
            );
          }else{
            this.playerData=this.playerData
          }
        }
        console.log(this.playerData,"ingdata")
      })
  }
  async getallinjurypreventionByAsm(): Promise<void> {
    try {
      this.UserId = this.trainerId || this.UserId;
      // Fetch data based on whether the user is a coach or trainer
      this.ipaResponse = (this.Coach || this.IsAssessor)
        ? await this.assessmentService.getInjuryPreventionAnalysisByOrg(this.org).toPromise()
        : (await this.assessmentService.getallinjurypreventionByAsm(this.Id).toPromise())
            // Filter based on organization ID
            .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));
       
      // Additional filtering if Admin or selectedTeamId is set
      if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0') || (this.sciStaff && this.selectedTeamId != '0')) {
        this.ipaResponse = this.ipaResponse.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      } else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
        // If not Admin or Coach, filter by assessor ID
        this.ipaResponse = this.ipaResponse.filter(
          (item: any) => item.ipaAssessor === this.UserId
        );
       
        // Optionally filter by selected team ID
        if (this.selectedTeamId != '0') {
          this.ipaResponse = this.ipaResponse.filter(
            (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
          );
        }
      }else if (this.Coach){
        this.ipaResponse = this.ipaResponse.filter(
          (item: any) =>item.asiCoach === this.UserId
        );
      }
      this.getPlayer();
      this.getInjuryAIData();
      this.ipaPerformers = this.athletedata ? this.athletedata : this.ipaResponse;
      this.athleteReport = this.ipaPerformers;
      const uniqueAthletesMap = new Map();
      this.athleteReport.forEach(item => {
          uniqueAthletesMap.set(item.ipaAthlete, item);
      });
      this.athleteReport = Array.from(uniqueAthletesMap.values());
      this.reportCount = this.athleteReport.length;
 
      if (this.ipaPerformers && this.ipaPerformers.length > 0) {
        let deepSquatValues: number[] = [];
        let inlineLungeValues: number[] = [];
        let hurdleStepValues: number[] = [];
        let activeSLRValues: number[] = [];
        let shoulderMobilityValues: number[] = [];
        let pushUpValues: number[] = [];
        let rotaryStabilityValues: number[] = [];
 
        // const isValidNumber = (value: any): boolean => typeof value === 'number' && !isNaN(value);
 
        // this.athleteReport = this.athleteReport.filter(report =>
        //   isValidNumber(report.ipaDeepSquat) &&
        //   isValidNumber(report.ipaInlineLunge) &&
        //   isValidNumber(report.ipaHurdleStep) &&
        //   isValidNumber(report.ipaActiveSLR) &&
        //   isValidNumber(report.ipaShoulderMobility) &&
        //   isValidNumber(report.ipaPushUp) &&
        //   isValidNumber(report.ipaRotaryStability)
        // );
 
        this.athleteReport.forEach(report => {
          deepSquatValues.push(report.ipaDeepSquat);
          inlineLungeValues.push(report.ipaInlineLunge);
          hurdleStepValues.push(report.ipaHurdleStep);
          activeSLRValues.push(report.ipaActiveSLR);
          shoulderMobilityValues.push(report.ipaShoulderMobility);
          pushUpValues.push(report.ipaPushUp);
          rotaryStabilityValues.push(report.ipaRotaryStability);
        });
 
        const calculateMean = (data: number[]): number => data.reduce((sum, value) => sum + value, 0) / data.length;
        const calculateStandardDeviation = (data: number[], mean: number): number =>
          Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
 
        const meanDeepSquat = calculateMean(deepSquatValues);
        const stdDevDeepSquat = calculateStandardDeviation(deepSquatValues, meanDeepSquat);
        const meanInlineLunge = calculateMean(inlineLungeValues);
        const stdDevInlineLunge = calculateStandardDeviation(inlineLungeValues, meanInlineLunge);
        const meanHurdleStep = calculateMean(hurdleStepValues);
        const stdDevHurdleStep = calculateStandardDeviation(hurdleStepValues, meanHurdleStep);
        const meanActiveSLR = calculateMean(activeSLRValues);
        const stdDevActiveSLR = calculateStandardDeviation(activeSLRValues, meanActiveSLR);
        const meanShoulderMobility = calculateMean(shoulderMobilityValues);
        const stdDevShoulderMobility = calculateStandardDeviation(shoulderMobilityValues, meanShoulderMobility);
        const meanPushUp = calculateMean(pushUpValues);
        const stdDevPushUp = calculateStandardDeviation(pushUpValues, meanPushUp);
        const meanRotaryStability = calculateMean(rotaryStabilityValues);
        const stdDevRotaryStability = calculateStandardDeviation(rotaryStabilityValues, meanRotaryStability);
 
        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
          return stdDev === 0 ? 0 : (value - mean) / stdDev;
        };
 
        this.athleteReport.forEach(report => {
          report.zScoreDeepSquat = this.zScoreToPercent(safeCalculateZScore(report.ipaDeepSquat, meanDeepSquat, stdDevDeepSquat));
          report.zScoreInlineLunge = this.zScoreToPercent(safeCalculateZScore(report.ipaInlineLunge, meanInlineLunge, stdDevInlineLunge));
          report.zScoreHurdleStep = this.zScoreToPercent(safeCalculateZScore(report.ipaHurdleStep, meanHurdleStep, stdDevHurdleStep));
          report.zScoreActiveSLR = this.zScoreToPercent(safeCalculateZScore(report.ipaActiveSLR, meanActiveSLR, stdDevActiveSLR));
          report.zScoreShoulderMobility = this.zScoreToPercent(safeCalculateZScore(report.ipaShoulderMobility, meanShoulderMobility, stdDevShoulderMobility));
          report.zScorePushUp = this.zScoreToPercent(safeCalculateZScore(report.ipaPushUp, meanPushUp, stdDevPushUp));
          report.zScoreRotaryStability = this.zScoreToPercent(safeCalculateZScore(report.ipaRotaryStability, meanRotaryStability, stdDevRotaryStability));
 
          report.overallZScore = (
            report.zScoreDeepSquat +
            report.zScoreInlineLunge +
            report.zScoreHurdleStep +
            report.zScoreActiveSLR +
            report.zScoreShoulderMobility +
            report.zScorePushUp +
            report.zScoreRotaryStability
          ) / 7;
        });
        // Sort reports by overallZScore and get the top 5
        const top5Performers = this.athleteReport
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
 
        const overallZScoreSum = top5Performers.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / top5Performers.length;
        this.averageOverallPercentage = averageOverallZScore? Number(averageOverallZScore.toFixed(2)):0;
        this.fitnessOverallPercentage = this.averageOverallPercentage;
 
       
       
 
        let performerLabels: string[] = [];

        if (this.Admin || this.OrgAdmin ) {
          // Use usrFullName for Admin, OrgAdmin, and Coach
          performerLabels = top5Performers.map(report => report.usrFullName);
        } else if(this.Coach){
          performerLabels = top5Performers.map(report => report.athleteName);
        }
        else {
          // Use naAthlete for other roles
          performerLabels = top5Performers.map(report => report.ipaAthlete);
        }
        
     
       
        const deepSquatData: [string, ...number[]] = ['Deep Squat Z-Score', ...top5Performers.map(report => report.zScoreDeepSquat.toFixed(4))];
        const inlineLungeData: [string, ...number[]] = ['Inline Lunge Z-Score', ...top5Performers.map(report => report.zScoreInlineLunge.toFixed(4))];
        const hurdleStepData: [string, ...number[]] = ['Hurdle Step Z-Score', ...top5Performers.map(report => report.zScoreHurdleStep.toFixed(4))];
        const activeSLRData: [string, ...number[]] = ['Active SLR Z-Score', ...top5Performers.map(report => report.zScoreActiveSLR.toFixed(4))];
        const shoulderMobilityData: [string, ...number[]] = ['Shoulder Mobility Z-Score', ...top5Performers.map(report => report.zScoreShoulderMobility.toFixed(4))];
        const pushUpData: [string, ...number[]] = ['Push Up Z-Score', ...top5Performers.map(report => report.zScorePushUp.toFixed(4))];
        const rotaryStabilityData: [string, ...number[]] = ['Rotary Stability Z-Score', ...top5Performers.map(report => report.zScoreRotaryStability.toFixed(4))];
 
        // Create or update the chart
        const c3 = (await import('c3')).default;
        const chart = c3.generate({
          bindto: '#new-chart', // Ensure the chart container has this ID
          data: {
            columns: [
              deepSquatData,
              inlineLungeData,
              hurdleStepData,
              activeSLRData,
              shoulderMobilityData,
              pushUpData,
              rotaryStabilityData
            ],
            types: {
              'Deep Squat Z-Score': 'area-spline',
              'Inline Lunge Z-Score': 'area-spline',
              'Hurdle Step Z-Score': 'area-spline',
              'Active SLR Z-Score': 'area-spline',
              'Shoulder Mobility Z-Score': 'area-spline',
              'Push Up Z-Score': 'area-spline',
              'Rotary Stability Z-Score': 'area-spline'
            }
          },
          axis: {
            x: {
              type: 'category',
              categories: performerLabels,
              tick: {
                rotate: 90,   // Rotate labels 90 degrees to make them vertical
                multiline: false
              },
              height: 100
            },
            y: {
              label: 'Z-Score'
            }
          }
        });
 
      } else {
        console.error('No valid data available for Z-score calculation.');
      }
 
    } catch (error) {
      console.error('Error fetching fitness report:', error);
    }
  }
 
  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;
 
    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
 
      const a1 =  0.254829592;
      const a2 = -0.284496736;
      const a3 =  1.421413741;
      const a4 = -1.453152027;
      const a5 =  1.061405429;
      const p  =  0.3275911;
 
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
 
      return sign * y;
    };
 
    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }
 
  async filterPerformers(type: 'All' | 'Excellent' | 'Good' | 'Average' | 'Poor', criteria: 'overall') {
    if (!this.athleteReport || this.athleteReport.length === 0) {
      console.warn('No athlete report data available.');
      return;
    }
  
    let sortedPerformers = [];
    switch (type) {
      case 'All':
        sortedPerformers = this.athleteReport
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        this.reportTitle = 'Overall Performers In Injury Prevention';
        break;
  
      case 'Excellent':
        sortedPerformers = this.athleteReport
          .filter(performer => performer.overallZScore > 85)
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        this.reportTitle = 'Excellent Performers In Injury Prevention';
        break;
  
      case 'Good':
        sortedPerformers = this.athleteReport
          .filter(performer => performer.overallZScore > 70 && performer.overallZScore <= 85)
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        this.reportTitle = 'Good Performers In Injury Prevention';
        break;
  
      case 'Average':
        sortedPerformers = this.athleteReport
          .filter(performer => performer.overallZScore > 40 && performer.overallZScore <= 70)
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        this.reportTitle = 'Average Performers In Injury Prevention';
        break;
  
      case 'Poor':
        sortedPerformers = this.athleteReport
          .filter(performer => performer.overallZScore <= 40)
          .sort((a, b) => a.overallZScore - b.overallZScore)
          .slice(0, 10);
        this.reportTitle = 'Poor Performers In Injury Prevention';
        break;
  
      default:
        console.warn(`Unknown type: ${type}`);
        return;
    }
  
    // Do NOT recalculate `averageOverallPercentage` here. Leave it unchanged.
  
    // Determine labels based on user role
    let performerLabels: string[] = [];
    if (this.Admin || this.OrgAdmin || this.Coach) {
      performerLabels = sortedPerformers.map(report => report.usrFullName || report.athleteName);
    } else {
      performerLabels = sortedPerformers.map(report => report.ipaAthlete);
    }
  
    // Ensure all Z-Scores are converted to 4 decimal places
    const deepSquatData: [string, ...number[]] = ['Deep Squat Z-Score', ...sortedPerformers.map(report => Number(report.zScoreDeepSquat.toFixed(4)))];
    const inlineLungeData: [string, ...number[]] = ['Inline Lunge Z-Score', ...sortedPerformers.map(report => Number(report.zScoreInlineLunge.toFixed(4)))];
    const hurdleStepData: [string, ...number[]] = ['Hurdle Step Z-Score', ...sortedPerformers.map(report => Number(report.zScoreHurdleStep.toFixed(4)))];
    const activeSLRData: [string, ...number[]] = ['Active SLR Z-Score', ...sortedPerformers.map(report => Number(report.zScoreActiveSLR.toFixed(4)))];
    const shoulderMobilityData: [string, ...number[]] = ['Shoulder Mobility Z-Score', ...sortedPerformers.map(report => Number(report.zScoreShoulderMobility.toFixed(4)))];
    const pushUpData: [string, ...number[]] = ['Push Up Z-Score', ...sortedPerformers.map(report => Number(report.zScorePushUp.toFixed(4)))];
    const rotaryStabilityData: [string, ...number[]] = ['Rotary Stability Z-Score', ...sortedPerformers.map(report => Number(report.zScoreRotaryStability.toFixed(4)))];
  
    // Ensure chart rendering happens after the DOM is ready
    try {
      const c3 = (await import('c3')).default;
  
      // Clear previous chart if necessary
      const chartContainer = document.querySelector('#new-chart');
      if (chartContainer) {
        chartContainer.innerHTML = ''; // Clear previous content
      }
  
      // Generate chart
      c3.generate({
        bindto: '#new-chart',
        data: {
          columns: [
            deepSquatData,
            inlineLungeData,
            hurdleStepData,
            activeSLRData,
            shoulderMobilityData,
            pushUpData,
            rotaryStabilityData
          ],
          types: {
            'Deep Squat Z-Score': 'area-spline',
            'Inline Lunge Z-Score': 'area-spline',
            'Hurdle Step Z-Score': 'area-spline',
            'Active SLR Z-Score': 'area-spline',
            'Shoulder Mobility Z-Score': 'area-spline',
            'Push Up Z-Score': 'area-spline',
            'Rotary Stability Z-Score': 'area-spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            categories: performerLabels,
            tick: {
              rotate: 90,
              multiline: false
            },
            height: 130
          }
        },
        legend: { position: 'right' }
      });
    } catch (error) {
      console.error('Error rendering chart:', error);
    }
  }
  
  
 
 
 
  DownloadClick() {
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
 
        // Map the image URLs for multiple athletes
        this.imageUrls = this.admitcardReport.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return null; // Handle cases where there is no image
          }
        });
      });
    } else if (this.Isplayer) {
      this.assessmentService.getAdmitCardById(this.UserId).subscribe((response: any) => {
        this.admitcardReport = response;
        this.imageUrls = this.admitcardReport.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return null; // Handle cases where there is no image
          }
        });
      });
    }
  }
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      let pdf: jsPDF | null = null; // Initialize pdf as null
      this.admitcardReport.forEach((athlete: any, index: number) => {
        const athleteElement = DATA.querySelectorAll('.modal-body')[index]; // Target each athlete
        if (athleteElement) {
          html2canvas(athleteElement as HTMLElement, {
            scale: 2, // Higher scale for better quality
          }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 0.7);
 
            if (!pdf) {
              pdf = new jsPDF('p', 'mm', 'a4'); // Initialize pdf only once
            }
 
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
 
            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;
 
            const widthScale = (pdfWidth * 0.5) / imgWidth;
            const heightScale = pdfHeight / imgHeight;
            const scale = Math.min(widthScale, heightScale); // Scale based on the smaller of the two
 
            const newImgWidth = imgWidth * scale;
            const newImgHeight = imgHeight * scale;
 
            const marginX = (pdfWidth - newImgWidth) / 2;
            const marginY = (pdfHeight - newImgHeight) / 2;
 
            if (index > 0) {
              pdf.addPage();
            }
 
            pdf.addImage(
              imgData,
              'JPEG',
              marginX,
              marginY,
              newImgWidth,
              newImgHeight,
              undefined,
              'FAST'
            );
 
            if (index === this.admitcardReport.length - 1) {
              pdf.save('AdmitCard.pdf');
            }
          });
        }
      });
    }
  }

  selectRole(role: string, roleId: any) {
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: {  Org:this.org, status:'True' } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }


}