import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AssessmentService } from '../services/assessment.service';
import { NotificationService } from '../services/Notifications.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../services/privilage.service';
import { Privilege } from '../model/Privillage';
import { NgSelectModule } from '@ng-select/ng-select';

interface IRow { }

@Component({
  selector: 'app-hydration-question',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule,NgSelectModule],
  templateUrl: './hydration-question.component.html',
  styleUrl: './hydration-question.component.css'
})
export class HydrationQuestionComponent implements OnInit {
  currentSection = 1;
  formValues: any = {
    athleteId: '',
    nutrition: {
      postMeal: '',
      mealQuality: '',
      hydration: '',
      supplements: '',
      hanCreateOn:''
    },
  };
  mode: any;
  teamId: any;
  athleteId: any;
  id: any;
  assessor: any;
  categoryId: any;
  hanId: any;
  ImageUrl: any;
  showImage: boolean = false;
  selectdata: any;
  rowData: IRow[] = [];
  playerList: any[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;
  image: any;
  Assessor: boolean;
  IsScienceStaff:boolean=false;
  filteredResponse: any;
  assessmentDate: any;
  minDate: any;
  maxDate: any;
  invalid:boolean=false;
  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,private cdRef: ChangeDetectorRef,private ngZone: NgZone,
    private priService:PrivilegeService
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
    this.IsScienceStaff=this.privilege.isScienceStaff;
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.id = params['id'];
      this.assessor = params['assessor'];
      this.categoryId = params['categoryId'];
    })
  }
  ngOnInit(): void {
    this.getProfileImage();
    this.getHydrationData(this.athleteId, this.id);
    this.GetPlayer();
    this.assessmentService.getpendingAssessment().subscribe(response => {
      this.filteredResponse = response.filter((item: any) => item.asmId == this.id);
      this.assessmentDate=this.filteredResponse[0].asmDate ? this.filteredResponse[0].asmDate.split('T')[0] : 'mm/dd/yyyy';
    });
}
today: string = new Date().toISOString().split('T')[0];
setDateConstraints(): void {
  this.ngZone.run(() => {
  const assessmentDate = this.assessmentDate;
  const today = new Date().toISOString().split('T')[0];
  this.minDate = assessmentDate;
  this.maxDate = today;
  this.formValues.nutrition.hanCreateOn  =this.formValues.nutrition.hanCreateOn ?this.formValues.nutrition.hanCreateOn : today;
  this.cdRef.detectChanges();
  this.invalid=false;
});
  const dateInput = document.querySelector('input[name="hanCreateOn"]') as HTMLInputElement;
  if (dateInput) {
    dateInput.showPicker?.();
  }
}
GetPlayer() {
  this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
    this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
    // Create a map to store unique assessments based on PlayerId (or any unique identifier)
    const uniqueAssessments = new Map<number, any>();
    console.log('Assessment submitted', response);

    // Filter the response to only include unique assessments
    this.selectdata.forEach((item: any) => {
      // Only add the item if it does not exist in the map
      if (!uniqueAssessments.has(item.aplPlayer)) {
        uniqueAssessments.set(item.aplPlayer, {
          "Player Name": `${item.usrFullName}`,
          Name: `${item.usrFullName}`,
          aplPlayer: `${item.aplPlayer}`,
          teamId: `${item.teamId}`,
          aplAssessment: `${item.aplAssessment}`,
          "Email": `${item.usrEmail}`,
          PlayerEmail: `${item.usrEmail}`,
          "Phone No": `${item.usrPhoneNo}`,
          PhoneNo: `${item.usrPhoneNo}`,
          "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
          "status": `${item.aplStatus}`,
        });
      }
    });

    // Convert the unique assessments map back to an array for your rowData
    this.rowData = Array.from(uniqueAssessments.values());
    this.playerName = this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
    this.playerList = this.rowData;
    this.updatePlayerLabels()
  }, error => {
    console.error('Error submitting assessment', error);
  });
}
updatePlayerLabels(): void {
  console.log(this.athleteId,"Athlete")
  this.playerList = this.playerList.map(player => ({
    ...player,
    displayLabel: !this.Assessor ? `${player.Name} - ${player.aplPlayer}` : player.aplPlayer
  }));
}
onAthleteChange(selectedPlayer: any): void {
  if (selectedPlayer) {
    const athleteId = selectedPlayer.aplPlayer;   
    const assessmentId = selectedPlayer.aplAssessment; 
    this.athleteId =selectedPlayer.aplPlayer
    this.minDate='';
    this.getProfileImage();
    this.getHydrationData(athleteId, assessmentId);
    this.formValues = {
      athleteId: '',
      nutrition: {
        postMeal: '',
        mealQuality: '',
        hydration: '',
        supplements: ''
      },
    };
    this.GetPlayer();
  }
}

getHydrationData(athleteId: any, id: any): void {
  const atheleId = Number(athleteId);
  const asmId = Number(id);

  this.assessmentService.getHydrationandNutritionByAsm(athleteId, id).subscribe(response => {
    if (response.length > 0) {
      const hydrationData = response[0];

      this.hanId = hydrationData.hanId || '';
      this.formValues.nutrition.postMeal = this.formatYesNo(hydrationData.answer1 || '');
      this.formValues.nutrition.mealQuality = this.formatMealQuality(hydrationData.answer2 || '');
      this.formValues.nutrition.hydration = this.formatHydrationAmount(hydrationData.answer3 || '');
      this.formValues.nutrition.supplements = this.formatYesNo(hydrationData.answer4 || '');
      this.formValues.nutrition.hanCreateOn = hydrationData.hanCreateOn ? hydrationData.hanCreateOn.split('T')[0] : 'mm/dd/yyyy';
      // Structure the questions and answers into JSON format
      const hydrationJson = {
        athleteId: athleteId,
        hanId: this.hanId,
        questions: [
          {
            question: "Do you consume a meal or snack rich in protein and carbohydrates within 30-60 minutes after training or competition?",
            answer: this.formValues.nutrition.postMeal || ''
          },
          {
            question: "How would you rate the quality of your post-training/competition meals?",
            answer: this.formValues.nutrition.mealQuality || ''
          },
          {
            question: "How much water or sports drinks do you consume post-training?",
            answer: this.formValues.nutrition.hydration || ''
          },
          {
            question: "Do you use any supplements (e.g., protein, electrolytes) for recovery?",
            answer: this.formValues.nutrition.supplements || ''
          }
        ]
      };

      console.log(hydrationJson);
      const jsonStr = JSON.stringify(hydrationJson, null, 2);
    }
  }, error => {
    console.error('Error fetching hydration data', error);
  });
}

// Helper function to format meal quality
formatMealQuality(quality: string): string {
  const qualityLower = quality.toLowerCase();

  if (qualityLower === 'poor') return 'Poor';
  if (qualityLower === 'fair') return 'Fair';
  if (qualityLower === 'good') return 'Good';
  if (qualityLower === 'very good') return 'Very Good';
  if (qualityLower === 'excellent') return 'Excellent';

  return quality; // Return as-is if no match
}

// Helper function to format hydration amounts
// Helper function to format hydration amounts
formatHydrationAmount(hydration: string): string {
  const hydrationLower = hydration.toLowerCase();

  // Extract the numeric value and unit using a regex
  const match = hydrationLower.match(/(\d+(\.\d+)?)\s?(ltr|ltrs|liter|liters|ml)/);

  if (match) {
    const amount = parseFloat(match[1]); // Get the numeric value
    const unit = match[3]; // Get the unit (ltr, ltrs, liter, liters, or ml)
    if (hydrationLower.includes('less') && hydrationLower.includes('500')) {
      return 'Less than 500 ml';
    }

    if (hydrationLower.includes('more') && amount >= 1) {
      return 'More than 1.5 liters';
    }

    if (unit === 'ml') {
      if (amount >= 500) {
        return '500 ml - 1 liter';
      } else if (amount < 500) {
        return 'Less than 500 ml';
      }
    } else {
      // Handle liter cases
      if (amount > 1.5) {
        return 'More than 1.5 liters';
      } else if (amount >= 1 && amount <= 1.5) {
        return '1 - 1.5 liters';
      } else if (amount >= 0.5 && amount < 1) {
        return '500 ml - 1 liter';
      } else if (amount < 0.5) {
        return 'Less than 500 ml';
      }
    }
  }

  return hydration; // Return as-is if no match
}

// Helper function to format Yes/No responses
formatYesNo(answer: string): string {
  const answerLower = answer.toLowerCase();
  if (answerLower === 'yes') return 'Yes';
  if (answerLower === 'no') return 'No';
  return answer; // Return as-is if no match
}

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
          console.log(this.image ,"image")
          
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        console.log(  this.image,"this.image")
        this.showImage = true;
          }
      );
      }
    );
  }
 
 

  // Method to check if all questions in the current section are answered
  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        return (
          this.formValues.nutrition.postMeal !== '' &&
          this.formValues.nutrition.mealQuality !== '' &&
          this.formValues.nutrition.hydration !== '' &&
          this.formValues.nutrition.supplements !== ''
        );

      default:
        return false;
    }
  }

  back():void{
    this.location.back();
  }

  submit(): void {
    const submissionData = [{
      hanId: this.hanId ? Number(this.hanId) : 0,
      hanAthleteId: Number(this.athleteId),
      hanAssessmentId: Number(this.id),
      hanAssessor: Number(this.assessor),
      hanQ1: this.formValues.nutrition.postMeal,
      hanQ2: this.formValues.nutrition.mealQuality,
      hanQ3: this.formValues.nutrition.hydration,
      hanQ4: this.formValues.nutrition.supplements,
      hanCreateOn: this.formValues.nutrition.hanCreateOn,
      hanCreatedBy: Number(this.assessor),
    }];
    if(!this.formValues.nutrition.hanCreateOn)
      {
        this.ngZone.run(() => {
          this.notify.failed("Please select Test Taken Date ");
          this.invalid=true;
          this.cdRef.detectChanges();
        });
        return;
      }

    this.assessmentService.saveHydrationandNutrition(submissionData).subscribe(response => {
      this.updateAssessmentStatus();
      this.notify.success("Answers submitted successfully");
      }, error => {
        this.notify.failed("Error submitting assessment");
    });
  }

  updateAssessmentStatus(): void {
    this.assessmentService.updateAssessmentStatus(
      Number(this.athleteId),
      Number(this.id),
      Number(this.categoryId),
      Number(this.assessor)
    ).subscribe(response => {
      console.log("Assessment status updated:", response);
    }, error => {
    });
  }

  isReadOnly(): boolean {
    return this.mode === 'view';
  }
}

