<div class="body-wrapper" *ngIf="currentSection === 1">
    <div class="row">
        <div class="col d-flex justify-content-start">
            <button type="button" class="btn btn-primary me-2" (click)="back()">
                <span class="btnText">Back</span>
            </button>
        </div>
    </div>
    <div class="mt-3">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="border rounded bg-white shadow-sm">
                    <div class="question p-4 border-bottom">
                        <div class="d-flex justify-content-between">
                            <p><strong>Player ID:</strong> {{ athleteId }}</p>

                            <div *ngIf="mode === 'edit'" class="dropdown-wrapper mb-2">
                                <div class="select-wrapper d-flex align-items-center">
                                    <label for="athleteDropdown" class="player-label">Select Player:</label>
                                    <ng-select  [(ngModel)]="athleteId" [items]="playerList" bindLabel="displayLabel" bindValue="aplPlayer" name="athleteId"
                                      (change)="onAthleteChange($event)" [searchable]="false" [clearable]="false" class="ng-select-custom">
                                      <ng-template ng-label-tmp let-item="item">
                                        <span>{{ item.displayLabel }}</span>
                                        <i class="fa fa-chevron-down"></i>
                                      </ng-template>
                                      <ng-template ng-option-tmp let-item="item">
                                        <span>{{ item.displayLabel }}</span>
                                    </ng-template>
                                    </ng-select>
                                  </div>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <!-- Image column -->
                            <div class="d-flex gap-4">
                                <!-- Image container with a fixed width -->
                                <div style="min-width: 150px; display: flex; align-items: start;">
                                  <img *ngIf="showImage && image"
                                       [src]="image"
                                       class="custom-img-width"
                                       alt="Player Image">
                                </div>
                              
                                <!-- Text content container with specified width -->
                                <div>
                                    <div *ngIf="Admin || IsScienceStaff" class="profile d-flex align-items-center mb-4">
                                        <div class="icon-bg">
                                            <i class='bx bx-user'></i>
                                         </div>
                                         <div>
                                        <h4 *ngIf="Admin || IsScienceStaff">{{ playerName[0]?.Name }}</h4>
                                        <p>Player Name:</p>
                                    </div> 
                                    </div>
                                    
                                    <div *ngIf="Admin || IsScienceStaff" class="profile d-flex align-items-center mb-4">
                                        <div class="icon-bg">
                                            <i class='bx bx-user'></i>
                                         </div>
                                         <div>
                                  <h4 *ngIf="Admin || IsScienceStaff">{{ playerName[0]?.PlayerEmail }}</h4>
                                  <p>Player Email</p>
                                </div>
                                  </div>
                                  <div  *ngIf="Admin || IsScienceStaff" class="profile d-flex align-items-center mb-4">
                                    <div class="icon-bg">
                                        <i class='bx bx-user'></i>
                                     </div>
                                     <div>
                                  <h4 *ngIf="Admin || IsScienceStaff">{{ playerName[0]?.PhoneNo }}</h4>
                                  <p>Player Phone No</p>
                                </div>
                                  </div>
                                  <div class="input-field">
                                    <label>Test Taken Date</label>
                                    <input class="w-100"
                                    type="date"
                                    [disabled]="mode === 'view'"
                                    name="hanCreateOn"
                                    [(ngModel)]="formValues.nutrition.hanCreateOn " 
                                    required
                                    [min]="this.minDate"
                                    [max]="this.maxDate"
                                    (focus)="setDateConstraints()"
                                  />
                                  <div *ngIf="invalid" class="error-message">
                                    Date is required.
                                </div>
                                  </div>
                                </div>
                              </div>
                              


                            <!-- Dropdown column (only visible in edit mode, aligned right) -->
                            <div class="col-md-4 d-flex justify-content-end">
                               
                            </div>
                        </div>

                        <!-- Player ID column (always below the image) -->
                        <div class="row mt-2">
                            <div class="d-flex justify-content-between align-items-center mt-4">
                                <h4>Hydration and Nutrition</h4>
                            </div>
                        </div>

                        <div class="question1 p-4 border-bottom">
                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">1.</h3>
                                <h5>Do you consume a meal or snack rich in protein and carbohydrates within 30-60
                                    minutes
                                    after training or
                                    competition?</h5>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="postMeal"
                                        [(ngModel)]="formValues.nutrition.postMeal" value="Yes"
                                        [disabled]="mode === 'view'">
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="postMeal"
                                        [(ngModel)]="formValues.nutrition.postMeal" value="No"
                                        [disabled]="mode === 'view'">
                                    <span>No</span>
                                </label>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">2.</h3>
                                <h5>How would you rate the quality of your post-training/competition meals?</h5>
                            </div>
                            <div class="form-check mb-2"
                                *ngFor="let option of ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent']">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="mealQuality"
                                        [(ngModel)]="formValues.nutrition.mealQuality" [value]="option"
                                        [disabled]="mode === 'view'">
                                    <span>{{option}}</span>
                                </label>
                            </div>


                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">3.</h3>
                                <h5>How much water or sports drinks do you consume post-training?</h5>
                            </div>
                            <div class="form-check mb-2"
                                *ngFor="let option of ['Less than 500 ml', '500 ml - 1 liter', '1 - 1.5 liters', 'More than 1.5 liters']">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="hydration"
                                        [(ngModel)]="formValues.nutrition.hydration" [value]="option"
                                        [disabled]="mode === 'view'">
                                    <span>{{option}}</span>
                                </label>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">4.</h3>
                                <h5>Do you use any supplements (e.g., protein, electrolytes) for recovery?</h5>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="supplements"
                                        [(ngModel)]="formValues.nutrition.supplements" value="Yes"
                                        [disabled]="mode === 'view'">
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="supplements"
                                        [(ngModel)]="formValues.nutrition.supplements" value="No"
                                        [disabled]="mode === 'view'">
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end p-3 bg-white">

                            <div *ngIf="mode === 'edit'">
                                <button class="btn btn-primary" type="button" (click)="submit()"
                                    [disabled]="!isSectionComplete(1)">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="toaster">
        <ng-toast />
    </div>