import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Input, EventEmitter, Output,NgZone, SimpleChanges, OnChanges} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { ChangeDetectorRef } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ChatService } from '../../services/chat.service';
import { PlayerDashboardComponent } from '../../player-dashboard/player-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IbmService } from '../../services/ibm.service';

@Component({
  selector: 'app-nutritional-dashboard',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule, FormsModule,NgSelectModule],
  templateUrl: './nutritional-dashboard.component.html',
  styleUrl: './nutritional-dashboard.component.css'
})
export class NutritionalDashboardComponent implements OnChanges{
  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>();
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedPerformance:string='';
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  GraphHeader: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = ''; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutritionOverallPercentage: any = null;
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = [];
  TopPerformer: any[] = [];
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  physioloyPerformers: any[] = [];
  admitcardReport: any;
  msg: any;
  nutResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  pfaPerformers: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: string = '';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  leveloneReference: any;
  nutritionReport: any;
  leveloneReport: any;
  teams: any[] = [];
  gender: number = 1;
  sport: number = 11;
  IBMReference: any;
  averageNaBodyWeight: any;
  averageNaBodyHeight: any;
  averageNaBMI: any;
  averageNaBodyFat: any;
  averageNaFatFreeWeight: any;
  averageNaSubcutaneousFat: any;
  averageNaVisceralFat: any;
  averageNaBodyWater: any;
  averageNaSkeletalMuscle: any;
  averageNaLeanMass: any;
  averageNaBoneMass: any;
  averageNaProtein: any;
  averageNaBMR: any;
  averageNaMetabolicAge: any;
  averageNaHealthScore: any;
  ImageUrl: any;
  overall:any
  averageBMI:any;
  averageBMR:any;
  averageBoneMass:any;
  averageProtein:any;

  averageBodyFat :any;
  averageBodyWater:any;
  averageFatFreeWeight:any;
  showImage: boolean=false;
  sciStaff: boolean = false;
  image: any;
  athletes: any;
  nutritionCount: any;
  catId: number=5;
  IsAssessor: boolean = false;
  selectOptions: any[]=[];
  nutIBM: any[] = [];
  aiData: any;
  playerData: any;
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,private ibmService: IbmService,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone
  ){
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });

    
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.IsAssessor = this.privilege.isAssessor ?? false;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }

  async ngOnInit(): Promise<void>{
    this.getPlayer()
    this.getTest();

    this.user = this.userService.getUser();
 
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.getNutAIData()
    this.isLoading = true;
    await this.getNutritionReport();
    this.loadNutritionTopPerformersData();
    this.isLoading = false;
    this.assessmentService.getInterNationalBenchMark(this.gender, this.sport).subscribe(response => {
      this['IBMReference'] = response;
  }, error => {
      console.error("Error fetching IBMReference:", error);
  });
  this.fetchoption();
  this.ibmCalculation()
  }
  ibmCalculation() {
    const genderId = 1; // Replace with actual values
    const asiCurrentClubTeam = '11'; // Replace with actual values

    // this.ibmService
    //   .calculateIBM(genderId, asiCurrentClubTeam, this.nutResponse)
    //   .subscribe((updatedPlayers) => {
    //     this.nutResponse = updatedPlayers;
    //     console.log(this.nutResponse, 'Updated cogResponse with nutIBM');
    //   });
  }
  // ibmCalculation(){
  //   const genderId = 1; // Replace with actual values
  //   const asiCurrentClubTeam = '11'; // Replace with actual values

  //   this.assessmentService.getInterNationalBenchMark(genderId, asiCurrentClubTeam).subscribe((response: any[]) => {
  //     console.log(response, "IBM Report");

  //     // Process each player's data
  //     this.nutResponse.forEach((player: any) => {
  //       const playerIBM: Record<string, number> = {}; // Define as a record with string keys and number values

  //       // Match each testColumnName with the player columns
  //       response.forEach(ibm => {
  //         const columnName = ibm.testColumnName; // e.g., 'naBodyWater'
  //         let ibmRange = ibm.ibmRange; // e.g., "7–12", "2.5–4.0", or "16–20"
        
  //         if (player[columnName] !== undefined && ibmRange) {
  //           // Normalize the range by replacing non-standard dashes with a hyphen
  //           ibmRange = ibmRange.replace(/–/g, '-');
        
  //           // Validate and parse the range
  //           const rangeMatch = ibmRange.match(/^([\d.]+)-([\d.]+)$/); // Matches ranges like "7-12" or "17.2-21.4"
        
  //           if (rangeMatch) {
  //             const lower = parseFloat(rangeMatch[1]); // Parse the lower bound
  //             const upper = parseFloat(rangeMatch[2]); // Parse the upper bound
        
  //             if (!isNaN(lower) && !isNaN(upper)) {
  //               const value = player[columnName];
        
  //               // Calculate percentage
  //               let percentage = ((value - lower) / (upper - lower)) * 100;
        
  //               // Ensure percentage is positive and capped at 100
  //               percentage = Math.min(Math.abs(percentage), 100);
        
  //               // Round to 2 decimal places
  //               percentage = parseFloat(percentage.toFixed(2));
        
  //               playerIBM[columnName] = percentage; // Store percentage
  //             }
  //           }
  //         }
  //       });
        
        
  //       player['nutIBM'] = playerIBM;
  //     });

  //     console.log(this.nutResponse, "Updated nutResponse with nutIBM");
  //   });
  // }
  fetchoption(){
    setTimeout(() => {
      this.selectOptions = [
        { value: 0, label: 'All' },
        { value: 1, label: 'Excellent' },
        { value: 2, label: 'Good' },
        { value: 3, label: 'Average' },
        { value: 4, label: 'Poor' },
        ];
      },100); 
   
  
  }
  getPlayer() {
    this.orgId = this.OrgAdmin ? this.org : this.orgId;

    if (this.orgId) {
      this.assessmentService.getAthleteByOrg(this.orgId).subscribe(
        (athletesData) => {
          this.athletes = athletesData;
          if (this.Coach) {
            this.nutritionCount = this.athletes.filter(
              (item: any) => item.asiCoach === this.UserId
            ).length;
          } else {
            // For OrgAdmin or other users, handle count based on team selection
            if (this.selectedTeamId != '0') {
              this.nutritionCount= this.athletes.filter(
                (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
              ).length;
            } else {
              // If no team is selected, count all athletes
              this.nutritionCount = this.athletes.length;
            }
          }
        },
        (error) => {
          console.error('Error fetching athletes:', error);
         
        }
      );
    } else {
      console.error('Organization ID is missing.');
      this.nutritionCount = this.athletes.length; 
    }
  }
  getColor(value: number): string {
    if (value >= 85) {
      return 'darkgreen';  
    } else if (value >= 70) {
      return 'lightgreen';
    } else if (value >= 40) {
      return 'orange';
    } else {
      return 'red';
    }
  }


  
  
 
  onNutritionStatusChange() {
    this.loadNutritionTopPerformersData();
  }
 
  onTeamSelected(selectedValue: any): void {
    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;
    this.getNutritionReport().then(() => {
    this.loadNutritionTopPerformersData();
    });
  }
  getTest(){
    this.assessmentService.getTests(this.catId).subscribe(response => {
     
  });
  }
  sendMessage() {
    this.apiResponse = this.playerData;
    this.msg = " give me result with their naAthlete,usrFullName,sports and result in both text and json format";
    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
    `;
    console.log(combinedMessage)
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          // Extract JSON array or object from the chat response
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            this.aiData = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            this.chatData.emit(this.chat);
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          this.chatData.emit(this.chat);
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
 
      this.isLoading = false;
 
      // Update each performer with matching assessment data and image URL
      this.athletedata.forEach((performer: any) => {
       const matchingAssessment = this.nutResponse.find((item: any) => item.naAthlete == performer.naAthlete)
          if (matchingAssessment) {
            Object.assign(performer, matchingAssessment);
            this.getProfileImage(performer.naAthlete)
            this.getNutritionReport();
            this.loadNutritionTopPerformersData();
          } else {
            console.error(`No matching assessment found`);
          }
      });      this.aiData.forEach((result: any) => {
        const matchingData = this.nutResponse.find((item: any) =>  item.naAthlete == result.naAthlete)
           if (matchingData) {
             Object.assign(result, matchingData);
           } else {
             console.error(`No matching data found`);
           }
       });
       console.log(this.aiData,"AIData")
       this.chatData.emit(this.aiData ?? this.chat);
    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });
 
  }
 
  loadNutritionTopPerformersData() {
    // this.nutResponse =this.athletedata ||this.nutrionReport;
    if (this.selectedStatus == 0) {
      // Show all data
      this.nutritiontopPerformer = [...this.nutrionReport];
      this.TopPerformer = this.nutrionReport
      .sort((a: any, b: any) => b.overallZScore - a.overallZScore)
      .slice(0, 5);
      this.header = "All Performers";
    } else if (this.selectedStatus == 1) {
      // Show performers with overallZScore >= 85 (Excellent Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 85) // Filter for scores >= 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore); // Sort descending
      this.header = "Excellent Performers";
    } else if (this.selectedStatus == 2) {
      // Show performers with overallZScore >= 70 and < 85 (Good Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85) // Filter for scores 70 <= overallZScore < 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore); // Sort descending
      this.header = "Good Performers";
    } else if (this.selectedStatus == 3) {
      // Show performers with overallZScore >= 40 and < 70 (Average Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70) // Filter for scores 40 <= overallZScore < 70
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore); // Sort descending
      this.header = "Average Performers";
    } else {
      // Show performers with overallZScore < 40 (Poor Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore < 40) // Filter for scores < 40
        .sort((a: any, b: any) => a.overallZScore - b.overallZScore); // Sort ascending
      this.header = "Poor Performers";
    }
    this.nutritiontopPerformer.forEach((performer: any) => {
    this.getProfileImage(performer.naAthlete)
       
    });
  }
 
  leveloneRefrences(age: number, athleteGender: any, sport: any) {
    this.assessmentService.levelonereference(age, athleteGender, sport).subscribe(response => {
      this.leveloneReference = response;
 
    });
  }
 
  calculateAge(dob: string): number {
    const birthDate = new Date(dob);
    const today = new Date();
 
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
 
    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
 
    return age;
  }
 
  getLorMarkRangeForEachParameter() {
    let result: any = {}; // Object to store lorMarkRange for each parameter comparison
 
    for (let reference of this.leveloneReference) {
      // Height comparison
      if (this.nutritionReport.naBodyHeight < reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naBodyHeight >= reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }
 
    for (let reference of this.leveloneReference) {
      // Weight comparison
      if (this.nutritionReport.naWeight < reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naWeight >= reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }
    this.leveloneReport = result;
    return result;
  }
 
  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;
 
    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
 
      const a1 =  0.254829592;
      const a2 = -0.284496736;
      const a3 =  1.421413741;
      const a4 = -1.453152027;
      const a5 =  1.061405429;
      const p  =  0.3275911;
 
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
 
      return sign * y;
    };
 
    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }
  getNutAIData(){
    this.assessmentService.getAllNutritionalForAI().subscribe(
      (response) => {
        this.playerData=response
        if(this.orgId != '0'){
          this.playerData=this.playerData.filter(
            (item: any) => item.usrOrganization === this.orgId
          );
          if (this.selectedTeamId != '0'){
            this.playerData=this.playerData.filter(
              (item: any) => item.asiCurrentClubTeam == this.selectedTeamId
            );
          }else{
            this.playerData=this.playerData
          }
        }
        console.log(this.playerData,"nutdata")
      })
  }
  async getNutritionReport(): Promise<void> {
    try {
     // Assign UserId as trainerId if available, otherwise keep the original UserId
  this.UserId = this.trainerId || this.UserId;

// Fetch data based on whether the user is a coach or trainer
this.nutResponse = (this.Coach || this.IsAssessor)
  ? await this.assessmentService.getNutritional(this.org).toPromise()
  : (await this.assessmentService.getAllNutritionalByAssessment().toPromise())
      // Filter based on organization ID
      .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));

// Additional filtering if Admin or selectedTeamId is set
if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0')|| (this.sciStaff && this.selectedTeamId != '0')) {
  this.nutResponse = this.nutResponse.filter(
    (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
  );
} else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
  // If not Admin or Coach, filter by assessor ID
  this.nutResponse = this.nutResponse.filter(
    (item: any) => item.naAssessor === this.UserId
  );
  
  // Optionally filter by selected team ID
  if (this.selectedTeamId != '0') {
    this.nutResponse = this.nutResponse.filter(
      (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
    );
  }
}else if (this.Coach){
  this.nutResponse = this.nutResponse.filter(
    (item: any) =>item.asiCoach === this.UserId
  );
}
  this.getPlayer();
  this.getNutAIData()
      // if (this.selectedTeamId != '0') {
      //   this.nutResponse = this.nutResponse.filter(
      //     (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
      //   );
      // }
      const calculateAverage = (data: any[], property: string): number => {
        const values = data.map((item: any) => item[property]);
        return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
    };
    
    // Calculate averages for all desired properties
    this.averageNaBodyWeight = calculateAverage(this.nutResponse, 'naBodyWeight');
    this.averageNaBodyHeight = calculateAverage(this.nutResponse, 'naBodyHeight');
    this.averageNaBMI = calculateAverage(this.nutResponse, 'naBMI');
    this.averageNaBodyFat = calculateAverage(this.nutResponse, 'naBodyFat');
    this.averageNaFatFreeWeight = calculateAverage(this.nutResponse, 'naFatFreeWeight');
    this.averageNaSubcutaneousFat = calculateAverage(this.nutResponse, 'naSubcutaneousFat');
    this.averageNaVisceralFat = calculateAverage(this.nutResponse, 'naVisceralFat');
    this.averageNaBodyWater = calculateAverage(this.nutResponse, 'naBodyWater');
    this.averageNaSkeletalMuscle = calculateAverage(this.nutResponse, 'naSkeletalMuscle');
    this.averageNaLeanMass = calculateAverage(this.nutResponse, 'naLeanMass');
    this.averageNaBoneMass = calculateAverage(this.nutResponse, 'naBoneMass');
    this.averageNaProtein = calculateAverage(this.nutResponse, 'naProtein');
    this.averageNaBMR = calculateAverage(this.nutResponse, 'naBMR');
    this.averageNaMetabolicAge = calculateAverage(this.nutResponse, 'naMetabolicAge');
    this.averageNaHealthScore = calculateAverage(this.nutResponse, 'naHealthScore');
    
      // Set the performers based on whether athletedata is available
      this.nutPerformers = this.athletedata || this.nutResponse;
      this.nutrionReport = this.nutPerformers;
      const uniqueAthletesMap = new Map();
      this.nutrionReport.forEach(item => {
          uniqueAthletesMap.set(item.naAthlete, item);
      });
      this.nutrionReport = Array.from(uniqueAthletesMap.values());
      this.reportNutritionCount = this.nutrionReport.length;
 
      if (this.nutrionReport.length > 0) {
        // List of keys that require z-score calculation
        const nutritionKeys = [
          'naBodyHeight', 'naBodyWeight', 'naBMI', 'naBodyFat', 'naSkeletalMuscle',
          'naBodyWater', 'naLeanMass', 'naBoneMass', 'naProtein', 'naBMR',
          'naHealthScore', 'naFatFreeWeight', 'naSubcutaneousFat', 'naVisceralFat', 'naMetabolicAge'
        ];
 
        // Initialize arrays for nutrition values
        let nutritionValues: { [key: string]: number[] } = {};
        nutritionKeys.forEach(key => nutritionValues[key] = []);
 
        // Function to calculate mean and standard deviation
        const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
          const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
          const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
          return { mean, stdDev };
        };
 
        // Collect valid data for each key
        // this.nutrionReport = this.nutrionReport.filter(report =>
        //   nutritionKeys.every(key => typeof report[key] === 'number' && !isNaN(report[key]))
        // );
 
        // Populate nutrition value arrays
        this.nutrionReport.forEach(report => {
          nutritionKeys.forEach(key => {
            nutritionValues[key].push(report[key]);
          });
        });
 
        // Calculate mean and standard deviation for each key
        let stats: { [key: string]: { mean: number, stdDev: number } } = {};
        nutritionKeys.forEach(key => {
          stats[key] = calculateStats(nutritionValues[key]);
        });
 
        // Function to safely calculate z-score
        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
          return stdDev === 0 ? 0 : formatDecimalNumber(((value - mean) / stdDev),2);
        };


 
        // Fetch level one references for benchmark comparison
        for (let report of this.nutrionReport) {
          const age = this.calculateAge(report.usrDob);
          await this.leveloneRefrences(age, report.genderId, report.sportId);
 
          // Calculate height and weight percentages based on benchmarks or Z-scores
          const benchmarkHeight = this.leveloneReference?.find((ref:any) => ref.lorHeight)?.lorHeight || null;
          const benchmarkWeight = this.leveloneReference?.find((ref:any) => ref.lorWeight)?.lorWeight || null;
 
          // Height percentage calculation
          if (benchmarkHeight && report.naBodyHeight) {
            report.heightPercentage = Math.min((report.naBodyHeight / benchmarkHeight) * 100, 100);
          } else {
            const heightZScore = safeCalculateZScore(report.naBodyHeight, stats['naBodyHeight'].mean, stats['naBodyHeight'].stdDev);
            report.heightPercentage = Math.min(Math.max(((heightZScore + 3) / 6) * 100, 0), 100);
          }
 
          // Weight percentage calculation
          if (benchmarkWeight && report.naBodyWeight) {
            report.weightPercentage = Math.min((report.naBodyWeight / benchmarkWeight) * 100, 100);
          } else {
            const weightZScore = safeCalculateZScore(report.naBodyWeight, stats['naBodyWeight'].mean, stats['naBodyWeight'].stdDev);
            report.weightPercentage = Math.min(Math.max(((weightZScore + 3) / 6) * 100, 0), 100);
          }
 
          // Calculate z-scores and overall z-score for each report
          let overallZScore = 0;
          nutritionKeys.forEach(key => {
            
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], stats[key].mean, stats[key].stdDev));
            overallZScore += report[zScoreKey];
          });
          report.overallZScore = overallZScore / nutritionKeys.length;
        }

        function formatDecimalNumber(value: number, decimalPlaces: number): number {
          return !isNaN(value) ? Number(value.toFixed(decimalPlaces)) : 0;
        }

        const calculateAverage = (data: any[], property: string): number => {
          const values = data.map((item: any) => item[property]);
          return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
      };
      
      // Calculate averages for all desired properties
      this.averageBMI = calculateAverage(this.nutrionReport, 'zScoreNaBMI');
      this.averageBodyFat = calculateAverage(this.nutrionReport, 'zScoreNaBodyFat');
      this.averageNaBMI = calculateAverage(this.nutrionReport, 'zScoreNaBodyHeight');
      this.averageBodyWater = calculateAverage(this.nutrionReport, 'zScoreNaBodyWater');
      this.averageNaFatFreeWeight = calculateAverage(this.nutrionReport, 'zScoreNaBodyWeight');
      this.averageBoneMass = calculateAverage(this.nutrionReport, 'zScoreNaBoneMass');
      this.averageFatFreeWeight = calculateAverage(this.nutrionReport, 'zScoreNaFatFreeWeight');
      this.averageBMR = calculateAverage(this.nutrionReport, 'zScoreNaBMR');
      this.averageBoneMass = calculateAverage(this.nutrionReport, 'zScoreNaBoneMass');
      this.averageProtein = calculateAverage(this.nutrionReport, 'zScoreNaProtein');

 
        // Calculate average overall Z-Score
        const overallZScoreSum = this.nutrionReport.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.nutrionReport.length;
        this.averageOverallPercentage =averageOverallZScore? formatDecimalNumber((averageOverallZScore),2) :0;
        this.nutritionOverallPercentage = this.averageOverallPercentage;
 
        this.filterPerformers('top', 'overall');
      } else {
        console.error('No valid data available for Z-score calculation.');
      }
    } catch (error) {
      console.error('Error fetching nutrition report:', error);
    }
  }
 
 
  filterPerformers(filterType: any, chart: string): void {
    // const filterType = filterTypes?.target?.value || filterTypes;
    let filteredReport: any[] = [];
    filteredReport = this.nutrionReport
    .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10);
    if (chart === 'overall') {
      if (filterType === 'All') {
        filteredReport = this.nutrionReport
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10); // Sort descending
        this.GraphHeader = "Overall ";
      }else if (filterType === 'excellent') {
        filteredReport = this.nutrionReport
          .filter((performer: any) => performer.overallZScore >= 85) // Excellent performers
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10); // Sort descending
        this.GraphHeader = "Excellent";
      } else if (filterType === 'good') {
        filteredReport = this.nutrionReport
          .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85) // Good performers
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10);
        this.GraphHeader = "Good";
      } else if (filterType === 'average') {
        filteredReport = this.nutrionReport
          .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70) // Average performers
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10);
        this.GraphHeader = "Average";
      } else if (filterType === 'poor') {
        filteredReport = this.nutrionReport
          .filter((performer: any) => performer.overallZScore < 40) // Poor performers
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10);
        this.GraphHeader = "Poor";
      }else{
        filteredReport = this.nutrionReport
          .sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10); // Sort descending
        this.GraphHeader = "Overall ";
      }
    }
 
    const nutritionKeys = [
     'naBodyHeight', 'naBodyWeight', 'naBMI', 'naBodyFat', 'naSkeletalMuscle',
     'naBodyWater', 'naLeanMass', 'naBoneMass', 'naProtein', 'naBMR',
     'naHealthScore', 'naFatFreeWeight', 'naSubcutaneousFat', 'naVisceralFat', 'naMetabolicAge'
    ];
 
    const generateChart = async (keys: string[], bindto: string, performerLabels: string[]) => {
      const chartData: [string, ...number[]][] = keys.map(key => {
        const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
        return [key.replace('na', '').replace(/([A-Z])/g, ' $1') + ' Z-Score in %', ...filteredReport.map(report => report[zScoreKey] as number)];
      });
      const c3 = (await import('c3')).default;
      if (chartData.length > 0) {
        c3.generate({
          bindto,
          data: {
            columns: chartData,
            types: chartData.reduce((acc, [label]) => ({ ...acc, [label]: 'area-spline' }), {})
          },
          axis: {
            x: { type: 'category', categories: performerLabels, tick: { rotate: 90, multiline: false }, height: 100 },
            y: { label: 'Z-Score' }
          },
          tooltip: {
            contents: (data) => {
              // Create the custom tooltip
              let html = '<table class="c3-tooltip">';
              html += '<thead><tr><th>Metric</th><th>Z-Score</th><th>Actual Value</th><th>IBM</th></tr></thead>';
              html += '<tbody>';
          
              // Loop through the data and build the rows
              data.forEach(item => {
                const key = item.id; // Get the key (e.g., "Body Weight Z-Score")
                const zScoreValue = item.value; // Get the Z-Score value
          
                // Extract the base key (e.g., "Body Weight") from the Z-Score key
                const baseKey = key.replace(' Z-Score in %', ''); // Adjust based on your label format
          
                // Find the corresponding performer for the current index
                const performer = filteredReport[item.index];
                const actualValue = performer[`na${baseKey.replace(/ /g, '')}`]; // Remove spaces and prepend 'na'
          
                // Find the corresponding IBM reference
                const ibmMatch = this['IBMReference'].find((ibm: { testColumnName: string; }) => ibm.testColumnName === `na${baseKey.replace(/ /g, '')}`);
                const ibmRange = ibmMatch ? ibmMatch.ibmRange : 'N/A';
          
                html += '<tr>';
                html += `<td>${baseKey}</td>`; // Metric Name
                html += `<td>${zScoreValue}</td>`; // Z-Score
                html += `<td>${actualValue}</td>`; // Actual Value from performer
                html += `<td>${ibmRange}</td>`; // IBM Range or 'N/A'
                html += '</tr>';
              });
          
              html += '</tbody></table>';
              return html;
            }
          }
          
          
        });
      } else {
        console.warn(`No valid data for chart on ${bindto}`);
      }
    };
 if(this.Admin || this.OrgAdmin){
  const performerLabels = filteredReport.map(report => report.usrFullName);
  if(chart == 'overall'){
    generateChart(nutritionKeys, '#nutrition-chart', performerLabels);
  }
 }else if( this.Coach){
  const performerLabels = filteredReport.map(report => report.athleteFullName);
  if(chart == 'overall'){
    generateChart(nutritionKeys, '#nutrition-chart', performerLabels);
  }
 }else{
  const performerLabels = filteredReport.map(report => report.naAthlete);
  if(chart == 'overall'){
    generateChart(nutritionKeys, '#nutrition-chart', performerLabels);
  }
 }

  }
 
  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
  
 
  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
          const athleteEntry = this.nutritiontopPerformer.find((entry: { naAthlete: any; }) => entry.naAthlete === userId);
          if (athleteEntry) {
            athleteEntry.image = this.image;
          } else {
            // this.nutritiontopPerformer.push({ naAthlete: userId, image: this.image });
          }
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.showImage = true;
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
            const athleteEntry = this.nutritiontopPerformer.find((entry: { naAthlete: any; }) => entry.naAthlete === userId);
            if (athleteEntry) {
              athleteEntry.image = this.image;
            } else {
              // this.nutritiontopPerformer.push({ naAthlete: userId, image: this.image });
            }
            this.cdr.detectChanges();
          },
          (error) => {
            console.error('Error fetching image:', error);
          }
        );
      }
    );
  }
  
  
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }
  selectRole(role: string, roleId: any) {
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: {  Org:this.org, status:'True' } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }

}