import { Component, AfterViewInit, ElementRef, ViewChild, OnInit, Inject, PLATFORM_ID, NgZone, ChangeDetectorRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { PrivilegeService } from '../services/privilage.service';
import { UserService } from '../services/user.service';
import { User } from '../model/User';
import { Privilege } from '../model/Privillage';
import { PrivilegeEnum } from '../model/PrivilageEnum';
import { SharedService } from '../services/shared.service';
import { AssessmentService } from '../services/assessment.service';
import { catchError, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfWorkerService } from '../services/pdf.worker.service';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'app-sidenavbar',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink],
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements AfterViewInit, OnInit {
  isDownloading$ = this.progressService.isDownloading$;
  progress$ = this.progressService.progress$;
  user: User | null = null;
  isSidebarClosed = false;
  Isplayer: boolean = false;
  privilege: any;

  @ViewChild('sidebarBtn') sidebarBtn!: ElementRef;
  @ViewChild('sidebar') sidebar!: ElementRef;
  @ViewChild('themeToggleBtn', { static: false }) themeToggleBtn!: ElementRef;

  user_id: any;
  isFormActive = false;
  isReportActive = false;
  isDashboardActive = false;
  isAssessorActive = false;
  Admin: boolean = false;
  activeSubMenu: string = '';
  OrgAdmin: boolean = false;
  Coach: boolean = false;
  name: any;
  detail: any;
  userId: any;
  isNutritionalSubMenuOpen: boolean = false;
  isNutritionalSubMenuHidden: boolean = false;
  NutritionalactiveLink: string = '';
  isSubMenuOpen: boolean = false;
  isSubMenuHidden = false;
  isUserManagementOpen: boolean = false;
  isHovered: boolean = false;
  isNutrionalHovered: boolean = false;
  isPhysicalFitness: boolean = false;

  activeLink: string = ''; // Property to track the active link
  profileImageUrl: any;
  isLoading: boolean = true;
  response: any;
  imageBlob: any;
  ImageUrl: any;
  isNutritional: boolean = false;
  cognative: boolean = false;
  cardio: boolean = false;
  sciStaff: boolean = false;
  assessor: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private assessmentService: AssessmentService,private sidebarService: SidebarService,
    private sanitizer: DomSanitizer, private ngZone: NgZone, private cdRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any,private progressService: PdfWorkerService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['Active']) {
        this.isSubMenuOpen = params['Active'];
        this.setActiveLink('/registerrequest?Active=true');
      }
    });
  }

  ngOnInit(): void {
    this.setInitialActiveLink();
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.detail$.subscribe((data: any) => {
        this.detail = data;
        this.name = data.user?.usrFullName || this.detail?.user?.usrFullName || '';
        this.userId = this.detail?.user?.usrId ?? '';
      });

      this.privilege = this.priService.getPrivileges();
      this.Isplayer = this.privilege?.isPlayer ?? false;
      this.Admin = this.privilege?.isAdmin ?? false;
      this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
      this.Coach = this.privilege?.isCoach ?? false;
      this.isPhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
      this.isNutritional = this.privilege?.isNutrition ?? false;
      this.cognative = this.privilege?.isCognitive ?? false;
      this.cardio = this.privilege?.isCardio ?? false;
      this.sciStaff = this.privilege.isScienceStaff ?? false;
      this.assessor = this.privilege.isAssessor ?? false;
      this.user = this.userService.getUser();

      this.getdashboardname();
      this.getProfileImage();

      if (this.user) {
        console.log('Logged in user:', this.user);
      }
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        const activeTab = localStorage.getItem('activeTab');
        if (activeTab === 'form') {
          this.activateReportForm();
        } else if (activeTab === 'report') {
          this.activateReport();
        } else if (this.router.url === '/dashboard') {
          this.activateDashboard();
        } else if (this.router.url === '/assessor') {
          this.activateAssessor();
        }
      }
    }
  }
  toggleSidebar() {
    // Call the service method to toggle the sidebar state
    this.sidebarService.toggleSidebar();
  }
  // To manage the visibility of the submenu
  private setInitialActiveLink() {
    this.activeLink = this.router.url;
    this.NutritionalactiveLink = this.router.url // Set the active link based on the current URL
  }
  // Method to set the active link
  setActiveLink(link: string) {
    this.ngZone.run(() => {
      this.activeLink = link; // Update the active link
      this.isSubMenuHidden = false; // Hide the submenu if an item is clicked
      console.log(this.activeLink, "link")
      this.cdRef.detectChanges();
    });
  }
  closeSubMenu() {
    this.isSubMenuOpen = false; // Ensure the submenu is closed
    this.cdRef.detectChanges(); // Trigger change detection if necessary
  }
  closeNutritionSubMenu() {
    this.isNutritionalSubMenuOpen = false; // Ensure the submenu is closed
    this.cdRef.detectChanges(); // Trigger change detection if necessary
  }
  

  setNutritionalActiveLink(link: string) {
    this.NutritionalactiveLink = link; // Update the active link
    this.isNutritionalSubMenuHidden = false; // Hide the submenu if an item is clicked
  }
  // Method to toggle the submenu
  toggleSubMenu() {
    if (!this.isSidebarClosed) { // Only toggle if sidebar is not closed
      this.isSubMenuOpen = !this.isSubMenuOpen; // Toggle submenu visibility
    } // Toggle the submenu visibility
  }
  SportsNutritiontoggleSubMenu() {
    if (!this.isSidebarClosed) { // Only toggle if sidebar is not closed
      this.isNutritionalSubMenuOpen = !this.isNutritionalSubMenuOpen; // Toggle submenu visibility
    } // Toggle the submenu visibility
  }
  onNutrionalMouseEnter() {
    this.isNutrionalHovered = true;
  }

  onNutrionalMouseLeave() {
    this.isNutrionalHovered = false;
  }

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }

  getProfileImage() {

    this.assessmentService.getEmptyImage(this.userId).subscribe(
      (response: any) => {
        if (response) {

          this.profileImageUrl = "assets/image/profileimg.jpg"
          console.log(this.profileImageUrl, "image")

        }
      },
      (error: any) => {
        this.assessmentService.getImage(this.userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.profileImageUrl = this.ImageUrl.changingThisBreaksApplicationSecurity
            console.log(this.profileImageUrl, "this.image")
          }
        );
      }
    );
  }


  getdashboardname(): string {
    if (this.OrgAdmin) return 'Organization Admin';
    if (this.Isplayer) return 'Player';
    if (this.Admin) return 'Admin';
    if (this.Coach) return 'Coach';
    if (this.sciStaff) return 'Science Staff';
    return 'Assessor'
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.sidebarBtn.nativeElement.addEventListener('click', () => {
        this.isSidebarClosed = !this.isSidebarClosed;
        localStorage.setItem('sidebarState', this.isSidebarClosed ? 'closed' : 'open');
        if (this.isSidebarClosed) {
          this.sidebar.nativeElement.classList.add('close');
          this.isSubMenuHidden = true;
        } else {
          this.sidebar.nativeElement.classList.remove('close');
          this.isSubMenuHidden = false;
        }
      });

      const arrows = document.querySelectorAll('.arrow');
      arrows.forEach(arrow => {
        arrow.addEventListener('click', (e: Event) => {
          const target = e.target as HTMLElement;
          const arrowParent = target.parentElement?.parentElement as HTMLElement;
          if (arrowParent) {
            arrowParent.classList.toggle('showMenu');
          }
        });
      });
      this.darklighttheme();

    }
  }

  darklighttheme() {
    // Check if the dark theme was previously enabled
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === 'dark') {
      document.body.classList.add('dark-theme');
      this.themeToggleBtn.nativeElement.classList.remove('bxs-moon');
      this.themeToggleBtn.nativeElement.classList.add('bxs-sun');
    }

    // Add the event listener to toggle the theme
    this.themeToggleBtn.nativeElement.onclick = () => {
      document.body.classList.toggle('dark-theme');
      if (document.body.classList.contains('dark-theme')) {
        this.themeToggleBtn.nativeElement.classList.remove('bxs-moon');
        this.themeToggleBtn.nativeElement.classList.add('bxs-sun');
        localStorage.setItem('theme', 'dark'); // Save dark theme in localStorage
      } else {
        this.themeToggleBtn.nativeElement.classList.remove('bxs-sun');
        this.themeToggleBtn.nativeElement.classList.add('bxs-moon');
        localStorage.setItem('theme', 'light'); // Save light theme in localStorage
      }
    };
  }

  dashboard() {
    this.router.navigate(['dashboard'], { relativeTo: this.route });
  }

  // toggleSubMenu(menu: string): void {
  //   if (menu === 'userManagement') {
  //     this.isUserManagementOpen = !this.isUserManagementOpen;
  //   }
  // }

  form() {
    if (this.privilege?.isPhysiotherapy) {
      this.router.navigate(['physiotherapy'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysiology) {
      this.router.navigate(['physiology'], { relativeTo: this.route });
    } else if (this.privilege?.isNutrition) {
      this.router.navigate(['nutrition'], { relativeTo: this.route });
    } else if (this.privilege?.isBioMechanical) {
      this.router.navigate(['biomechanical'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysicalFitness) {
      this.getPrivilage("PhysicalFitness");
      this.router.navigate(['physicalfitness'], { relativeTo: this.route });
    } else if (this.privilege?.isVideoAnalysis) {
      this.router.navigate(['LevelOneForm'], { relativeTo: this.route });
    } else if (this.privilege?.isLevelOne) {
      this.router.navigate(['LevelOneForm'], { relativeTo: this.route });
    } else if (this.privilege?.isCardio) {
      this.router.navigate(['CardiologyForm'], { relativeTo: this.route });
    } else if (this.privilege?.isCognitive) {
      this.router.navigate(['cognitive'], { relativeTo: this.route });
    }
  }


  navigateWithId(path: string) {
    this.router.navigate([path], { queryParams: { id: this.userId } });
  }

  report() {
    if (this.privilege?.isPhysiotherapy) {
      this.getPrivilage("physiotherapy");
      this.router.navigate(['physiotherapylist'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysiology) {
      this.router.navigate(['physiologylist'], { relativeTo: this.route });
    } else if (this.privilege?.isNutrition) {
      this.router.navigate(['nutritionlist'], { relativeTo: this.route });
    } else if (this.privilege?.isBioMechanical) {
      this.router.navigate(['cognitivelist'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysicalFitness) {
      this.getPrivilage("PhysicalFitness");
      this.router.navigate(['physicalfitnesslist'], { relativeTo: this.route });
    } else if (this.privilege?.isVideoAnalysis) {
      this.router.navigate(['levelonelist'], { relativeTo: this.route });
    } else if (this.privilege?.isLevelOne) {
      this.router.navigate(['levelonelist'], { relativeTo: this.route });
    } else if (this.privilege?.isCardio) {
      this.router.navigate(['CardiologyList'], { relativeTo: this.route });
    } else if (this.privilege?.isCognitive) {
      this.router.navigate(['cognitivelist'], { relativeTo: this.route });
    }
  }

  Assessor() {
    if (this.privilege?.isPhysiotherapy) {
      this.getPrivilage("physiotherapy");
      this.router.navigate(['physiotherapylist'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysiology) {
      this.router.navigate(['physiologylist'], { relativeTo: this.route });
    } else if (this.privilege?.isNutrition) {
      this.router.navigate(['nutritionlist'], { relativeTo: this.route });
    } else if (this.privilege?.isBioMechanical) {
      this.router.navigate(['cognitivelist'], { relativeTo: this.route });
    } else if (this.privilege?.isPhysicalFitness) {
      this.getPrivilage("PhysicalFitness");
      this.router.navigate(['PhysicalfitnessAssessment'], { relativeTo: this.route });
    } else if (this.privilege?.isVideoAnalysis) {
      this.router.navigate(['levelonelist'], { relativeTo: this.route });
    } else if (this.privilege?.isLevelOne) {
      this.router.navigate(['saifitness'], { relativeTo: this.route });
    } else if (this.privilege?.isCardio) {
      this.router.navigate(['CardiologyAssessment'], { relativeTo: this.route });
    } else if (this.privilege?.isCognitive) {
      this.router.navigate(['CognitiveAssessment'], { relativeTo: this.route });
    }
    // this.router.navigate(['PhysicalfitnessAssessment'], { relativeTo: this.route });
  }

  getPrivilage(name: string): void {
    const privileges = this.userService.getUserPrivileges();
    const privilege = privileges.find(p => p.modName === name);
    if (privilege) {
      this.userService.setRamPrivilege(privilege.ramPrivilege as PrivilegeEnum);
    }
  }

  activateReportForm() {
    this.isFormActive = true;
    this.isReportActive = false;
    this.isDashboardActive = false;
    this.isAssessorActive = false;
    localStorage.setItem('activeTab', 'form');
    this.form();
  }

  activateReport() {
    this.isReportActive = true;
    this.isFormActive = false;
    this.isDashboardActive = false;
    this.isAssessorActive = false;
    localStorage.setItem('activeTab', 'report');
    this.report();
  }

  activateDashboard() {
    this.isDashboardActive = true;
    this.isFormActive = false;
    this.isReportActive = false;
    this.isAssessorActive = false;
    localStorage.setItem('activeTab', 'dashboard');
  }

  activateAssessor() {

    this.isAssessorActive = true;
    this.isFormActive = false;
    this.isReportActive = false;
    this.isDashboardActive = false;
    localStorage.setItem('activeTab', 'assessor');
    this.Assessor();
  }

  clearUser() {
    this.userService.clearUser();
    this.logout();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  showSubMenu(): void {
    this.isSubMenuHidden = false;
  }

  // Keep submenu open when hovering over it
  keepSubMenuOpen(): void {
    this.isSubMenuHidden = false;
  }

  // Hide submenu when the mouse leaves
  hideSubMenu(): void {
    this.isSubMenuHidden = true;
  }
}
