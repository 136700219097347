<div class="modal fade" id="fileUploadModal" tabindex="-1" aria-labelledby="fileUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploadModalLabel">{{ name }} Files Upload</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-4" *ngIf="name == 'Sports Talent Identification Assessment' || name == 'SAI Health & Fitness Assessment'">
          <ng-select
            class="ng-select-custom"
            [(ngModel)]="selectedAssessment"
            [items]="assessments"
            bindLabel="asmName"
            bindValue="asmId"
            placeholder="Select Assessment"
            [searchable]="true"
            (change)="onAssessmentSelect()"
          ></ng-select>
        </div>
        <div class="file-input-container">
          <input type="file" multiple (change)="onFileSelected($event)" class="form-control" />
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button
          class="btn btn-primary"
          (click)="downloadTemplate(name)"
          [disabled]="!selectedAssessment"
        >
          Download Template
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="onUpload()"
          [disabled]="!selectedFiles || selectedFiles.length === 0"
          title="Please ensure that you have chosen the correct file for the selected assessment"
        >
          <span class="btnText">Upload</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 350px;">
    <div class="modal-content" style="max-height: 300px;">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModalModalLabel">File Error</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        {{ errorMessage }}  
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="duplicateModal" tabindex="-1" aria-labelledby="duplicateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 900px;" >
    <div class="modal-content" style=" height: auto;padding: 5px;">
      <div class="modal-header" style="padding: 0px 15px;">
        <div>
          <h5 class="modal-title" id="duplicateModalLabel">Upload Issues</h5>
          
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
      </div>
      <div *ngIf="emptySheetsMessage" class="alert alert-warning">
        {{ emptySheetsMessage }}
      </div>
      <div class="modal-body" style="padding: 0px 15px;">
        <!-- AG Grid Table -->
        <ag-grid-angular
          class="ag-theme-quartz"
          style="width: 100%; height: 350px;"
          [rowData]="issueData"
          [columnDefs]="issueColumns"
          [gridOptions]="gridOptions" 
          [pagination]="true"
          [defaultColDef]="defaultColDef">
        </ag-grid-angular>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
      </div>
    </div>
  </div>
</div>

<!-- Tabs for Section Selection -->
<div class="modal fade" id="filePreviewModal" tabindex="-1" aria-labelledby="filePreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg"> <!-- Adjusted to accommodate AG Grid -->
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <h5 class="modal-title" id="filePreviewModalLabel">Preview File Data</h5>
          <p>
            <span style="color: red;">
              There is a mismatch in the data type. Ensure that number fields have only numbers and text fields have only words.
            </span>
          </p>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Tab Navigation -->
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let sheet of sheetNames">
            <button
              class="nav-link"
              [class.active]="sheet === selectedTab"
              (click)="selectTab(sheet)"
            >
              {{ sheet }}
            </button>
          </li>
        </ul>

        <!-- Table Data -->
        <div *ngIf="showTable">
          <div class="table-container">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th *ngFor="let col of displayedColumns">{{ col }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of fileData; let rowIndex = index">
                  <td
                    *ngFor="let cell of row; let colIndex = index"
                    [class.invalid-column]="isHighlighted(rowIndex, colIndex)"
                  >
                    {{ cell }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- AG Grid for Upload Issues -->
        <div style="margin-top: 20px;">
          <h6>Upload Issues</h6>
          <ag-grid-angular
            class="ag-theme-quartz"
            style="width: 100%; height: 350px;"
            [rowData]="filterData"
            [columnDefs]="issueColumns"
            [gridOptions]="gridOptions"
            [pagination]="true"
            [defaultColDef]="defaultColDef"
          ></ag-grid-angular>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="toaster">
  <ng-toast></ng-toast>
</div>
