import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, Component, ComponentFactoryResolver, Injector, NgModule, NgZone, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { User } from '../../model/User';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { Location } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { PlayerReportComponent } from '../player-report/player-report.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PdfWorkerService } from '../../services/pdf.worker.service';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { PrivilegeService } from '../../services/privilage.service';
import { NotificationService } from '../../services/Notifications.service';
import { Privilege } from '../../model/Privillage';

interface IRow { }
@Component({
  selector: 'app-csshealth-fitness-detail',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular, NgSelectModule, FormsModule, PlayerReportComponent],
  templateUrl: './csshealth-fitness-detail.component.html',
  styleUrl: './csshealth-fitness-detail.component.css'
})
export class CSSHealthFitnessDetailComponent {
  @ViewChildren(PlayerReportComponent) playerReportComponents!: QueryList<PlayerReportComponent>;
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;
  groupedData: { [key: string]: any[] } = {};

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  gridOptions: GridOptions = {
    rowSelection: 'multiple', // Enable multi-row selection
    suppressRowClickSelection: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  org: any;
  role: any;
  category: any;
  test: any;
  selectdata: any;
  status: any;
  selectedStatus: number = 0;
  statusOptions = [
    { value: 0, label: 'Z-score' },
    { value: 1, label: 'IBM-score' },
    { value: 2, label: 'Polynomial' },
  ];
  gridApi: any;
  selectedPlayer: any;
  jsonData: any;
  athleteId: any;
  nutRemark: any;
  cardioRemark: any;
  injuryRemark: any;
  cogRemark: any;
  categoryRemark: any;
  remark: any = {
    ausId: 0,
    ausAthlete: '',
    ausAssessment: '',
    ausSummary: '',
    ausActionPlan: '',
    ausRemark: '',
    ausEndurance: '',
    ausAgility: '',
    ausStrength: '',
    ausFlexibility: '',
    ausBalance: '',
    ausCognitive: '',
    ausCardio: '',
    ausInjury: '',
    ausIsokinetic: '',
    ausConcentric: '',
    ausUpperBodyPower: '',
    ausLowerBodyPower: '',
    ausNutrition: ''
  };
  endurance: any = {
    DistanceCovered: '',
    Level: '',
    VO2Max: '',
    Speed: ''
  }
  strength: any = {
    PushUps: '',
    Squats: '',
    PartialCurlUps: '',
    RightHandGrip: '',
    LeftHandGrip: ''
  }
  flexibility: any = {
    ApleysScratchTest: '',
    SitandReach: ''
  }
  balance: any = {
    StaticBalanceRightEyesOpen: '',
    StaticBalanceLeftEyesOpen: '',
    StaticBalanceRightEyesClose: '',
    StaticBalanceLeftEyesClose: ''
  }
  upperBodyPower: any = {
    SeatedMedBallThrows: '',
    Force: '',
    Power: ''
  }
  lowerBodyPower: any = {
    BroadJump: '',
    Force: '',
    Power: ''
  }
  isokinetic: any = {
    RightAverageTorque: '',
    RightPeakTorque: '',
    LeftAverageTorque: '',
    LeftPeakTorque: ''
  }
  Concentric: any = {
    RightAverageTorque: '',
    RightPeakTorque: '',
    LeftAverageTorque: '',
    LeftPeakTorque: ''
  }

  enduranceRemark: any;
  strengthRemark: any;
  balancehRemark: any;
  UBPRemark: any;
  LBPRemark: any;
  isokineticRemark: any;
  Ttest: any;
  Ttestemark: any;
  formData: any;
  combineData: any;
  chat: any;
  ConcentricRemark: any;
  playerId: any;
  summary: any;
  actionPlan: any;
  summaryRemark: any;
  flexRemark: any;
  isLoading:boolean= false;
  Coach:boolean | undefined;
  privilege: Privilege;
  OrgAdmin: boolean=false;
  IsScience: boolean=false;
  athleteCount: any;
  successCount: any = 0;
  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,
    private ngZone: NgZone, private chatService: ChatService,
    private router: Router, private route: ActivatedRoute, private location: Location, private resolver: ComponentFactoryResolver,
    private injector: Injector,private cdRef: ChangeDetectorRef,private notify: NotificationService,
    private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,private priService:PrivilegeService,
    private progressService: PdfWorkerService
  ) {
    this.route.queryParams.subscribe(params => {
      this.asmId = params['asmId'];
      this.category = params['category'];
      this.status = params['selectStatus'];

    });
    this.privilege = this.priService.getPrivileges();
    this.Admin=this.privilege.isAdmin;
    this.Isplayer = this.privilege.isPlayer;
    this.Coach = this.privilege.isCoach;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.IsScience=this.privilege.isScienceStaff
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role = this.detail.user.usrRole;
      this.Status = 0;
    });

    this.getPendingAssessment();
    this.assessmentService.getPlayerByAssessmentId(this.asmId).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.category);
      const uniqueAssessments = new Map<number, any>();
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            PlayerId: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            orgId: `${item.usrOrganization}`,
            id: `${item.coaId}`,
            "Email": `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
            "status": `${item.aplStatus}`,

          });
        }
      });
      this.rowData = Array.from(uniqueAssessments.values());



      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  onBack(): void {
    this.router.navigate(['/Fitness'], {
      queryParams: {
        statusId: this.status
      }
    });
  }

  getPendingAssessment() {

    this.assessmentService.getAssessmentTest(this.asmId).subscribe(
      (response) => {
        this.test = response;
        this.groupDataBySsName();
        console.log(response, "test")
        const uniqueAssessments = new Map<number, any>();
      },
      (error) => {
        console.error('Error retrieving pending assessments', error);
      }
    );

  }
  groupDataBySsName() {
    this.groupedData = this.test.reduce((acc: { [x: string]: { [y: string]: any[]; }; }, item: { ssName: string; assesserName: string; }) => {
      if (!acc[item.ssName]) {
        acc[item.ssName] = {};
      }
      if (!acc[item.ssName][item.assesserName]) {
        acc[item.ssName][item.assesserName] = [];
      }
      acc[item.ssName][item.assesserName].push(item);
      return acc;
    }, {});
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        hide: this.status != 1,
      },
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB' },
      { field: 'Phone No' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              if (_params.data.status == 1) {
                this.router.navigate(['/report'], {
                  queryParams: {
                    id: _params.data.PlayerId,
                    orgId: _params.data.orgId,
                    asmId: this.asmId,
                    selectedStatus: this.selectedStatus
                  }
                });
              }
            });
          });

          // const editButton = document.createElement('button');
          // editButton.classList.add('btn');
          // editButton.innerHTML = '<i class="fa fa-edit"></i>';
          // editButton.addEventListener('click', () => {
          //   this.ngZone.run(() => {
          //     this.router.navigate(['/CardiologyForm'], {
          //       queryParams: { id: _params.data.id }
          //     });
          //   });
          // });

          container.appendChild(viewButton);
          // container.appendChild(editButton);


          return container;
        },
        width: 120
      }

    ];

    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
  onGridReady(params: any) {
    this.gridApi = params.api;

    // Listen to row selection events and update selectedPlayer array
    this.gridApi.addEventListener('rowSelected', (event: any) => {
      this.updateSelectedTest();
    });
  }
  updateSelectedTest() {
    // Get all selected nodes (rows) from the grid
    const selectedNodes = this.gridApi.getSelectedNodes();

    // Map selected rows to player data
    this.selectedPlayer = selectedNodes.map((node: any) => node.data);
    console.log(this.selectedPlayer, "player data")
  }

  printPDF(): void {
    this.progressService.setIsDownloading(true); // Indicate that downloading has started

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const margin = 10;
    const padding = 5;
    const imgWidth = pageWidth - 2 * (margin + padding);

    let currentPageNumber = 1; // Track page numbers

    const addBorderToPage = () => {
        pdf.setLineWidth(0.5);
        pdf.setDrawColor(0);
        pdf.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
    };

    const addPageNumber = () => {
        pdf.setFontSize(10);
        pdf.text(`${currentPageNumber}`, pageWidth / 2, pageHeight - 5, { align: 'center' });
    };

    const waitForImages = async (section: HTMLElement) => {
        const imgElements = section.querySelectorAll('img') as NodeListOf<HTMLImageElement>;
        await Promise.all(
            Array.from(imgElements).map((img) =>
                new Promise<void>((resolve) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = () => resolve();
                        img.onerror = () => resolve();
                    }
                })
            )
        );
    };

    const processSection = async (section: HTMLElement, yPosition: number): Promise<number> => {
      await waitForImages(section);

      const canvas = await html2canvas(section, {
        scale: 1,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        logging: false,
        ignoreElements: (el) => el.classList.contains('no-print'),
      });

      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yPosition + imgHeight > pageHeight - margin - padding) {
            addPageNumber();
            pdf.addPage();
            currentPageNumber++;
            addBorderToPage();
            yPosition = margin + padding;
        }

      pdf.addImage(imgData, 'JPEG', margin + padding, yPosition, imgWidth, imgHeight);
      return yPosition + imgHeight + padding;
    };

    const generatePDF = async () => {
        const totalPlayers = this.selectedPlayer.length;
        let currentIndex = 0;

        const processNextPlayer = async () => {
            if (currentIndex >= totalPlayers) {
                addPageNumber();
                pdf.save('PlayerReports.pdf');
                this.progressService.setIsDownloading(false);
                this.progressService.setProgress(0);
                return;
            }

            const player = this.selectedPlayer[currentIndex];
            const progress = Math.round(((currentIndex + 1) / totalPlayers) * 100);
            this.progressService.setProgress(progress);

            const factory = this.resolver.resolveComponentFactory(PlayerReportComponent);
            const componentRef = factory.create(this.injector);

            componentRef.instance.orgId = player.orgId;
            componentRef.instance.usrId = player.PlayerId;
            componentRef.instance.asmtId = this.asmId;
            componentRef.instance.selectedScore = this.selectedStatus;

            this.viewContainerRef.insert(componentRef.hostView);

            await new Promise<void>((resolve) => setTimeout(resolve, 1500));

            await new Promise<void>((resolve) => {
                setTimeout(async () => {
                    try {
                        const content = componentRef.location.nativeElement.querySelector('#pdf-content');

                        if (content) {
                            const sections = content.querySelectorAll('.section') as NodeListOf<Element>;
                            let yPosition = margin + padding;

                            addBorderToPage();

                            for (const section of Array.from(sections)) {
                                yPosition = await processSection(section as HTMLElement, yPosition);
                            }

                            if (currentIndex < totalPlayers - 1) {
                                addPageNumber();
                                pdf.addPage();
                                currentPageNumber++;
                            }
                        }
                    } catch (error) {
                        console.error('Error processing PDF content:', error);
                    } finally {
                        this.viewContainerRef.detach(this.viewContainerRef.indexOf(componentRef.hostView));
                        componentRef.destroy();
                        currentIndex++;
                        processNextPlayer(); // Continue processing next player
                    }
                }, 3000);
            });
        };

        processNextPlayer();
    };

    generatePDF();
  }


  // Method to get styles from the component's CSS file
  getComponentStyles(): string {
    // Assuming styles are in the component's CSS file
    const styles = `
      @page {
  size: A4;
  margin: 8cm 0 3cm 0;

  @top-left {
    content: element(header);
  }

  @bottom-left {
    content: element(footer);
  }
}
.body-wrapper {
        /* Set the height to 100% of the viewport height */
  margin-top: 40px;
  padding: 30px;
  font-size: 10pt;
  background: rgb(255, 255, 255);  /* White background */
}

.contain{
padding: 20px;
}
.border-cont{
  border: 2px solid #1a0270;
  border-radius: 7px;
}
a {
  color: inherit;
  text-decoration: none;
}
hr {
  margin: 1cm 0;
  height: 0;
  border: 0;
  border-top: 1mm solid #60D0E4;
}


header {
  height: auto;
  padding: 0 0.5cm;
  position: running(header);
  border-radius: 7px;
  
}

header .headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* header .headerSection:first-child {
  padding-top: .5cm;
}

header .headerSection:last-child {
  padding-bottom: 8px;
} */

.items{
  height: 100%;
}
header .headerSection .logoAndName {
  display: flex;
  align-items: center; 
  width: 100%;
  background-color: white;
  height: 140px;
border-radius: 7px;
  /* border: 2px solid #e5e5e5; */
  padding: 0 10px;
}
header .headerSection .user-detail{
  /* background: linear-gradient(60deg, #6cd3ff 25%, #B8E69F 40%, #c1e037 75%); */
  background-color:#E8E8E8;
  width: 50%;
  height: 140px;
  padding: 25px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
header .headerSection .logoAndName .logo-image {
  width: 4.5cm; 
  height: 3.5cm;
  /* margin-right: .5cm; */
  /* border-radius: 50%;  */
  overflow: hidden; 
}

header .headerSection .logoAndName .logo-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

header .headerSection .invoiceDetails {
  display: flex;
  flex-direction: column;
  text-align: right;
}

header .headerSection .invoiceDetails h2 {
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: 600;
  color:black;
}

header .headerSection .invoiceDetails p {
  font-size: 14px;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color:rgb(24, 24, 24);
}

header .headerSection .image {
  width: 4.5cm; 
  height: 2.5cm; 
  display: flex;
  align-items: center;
  justify-content: center; 
  /* margin-left: 1cm; */
  /* border-radius: 100px;  */
}

header .headerSection .image img {
  width: 100%; 
  height: 100%; 
  object-fit: contain;
  /* border-radius: 100px;  */
}
header .headerSection .profile-image{
  width: 4.5cm; 
  height: 4.5cm; 
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 11px; 
}
header .headerSection .profile-image img {
  width: 100%; 
  height: 100%; 
  object-fit: cover;
}
.diagram{
  width: 6.5cm; 
  height: 4.5cm; 
  display: flex;
  align-items: center;
  justify-content: center; 

}
.diagram img{
  width: 100%; 
  height: 100%; 
  object-fit: cover;
  border-radius: 7px; 
}
header h1,
header h2,
header h3,
header p {
  margin: 0;
}

header h1 {
  font-family: 'Montserrat', sans-serif;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}


main {
  
  /* padding: 25px 0; */
}

.container h4{
  font-family: 'Poppins';
}
aside {
  -prince-float: bottom;
  padding: 0 2cm .5cm 2cm;
}

aside>div {
  display: flex;
  justify-content: space-between;
}

aside>div>div {
  width: 45%;
}

aside>div>div ul {
  list-style-type: none;
  margin: 0;
}

.row.d-flex {
  display: flex;
  height: 100%;
}
 
.card1, .card2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: none;
  margin-bottom: 8px;
}
 
.card1 {
  background-color: rgb(229 241 255);
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
 
.card2 {
  background-color: #1C4488;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card1 .card-body {
  padding: 1.25rem 1.25rem;
}
.card2 .card-body {
  padding: 1.25rem 1.25rem;
}

.customemail{
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #0049FA;
}

.card1 .card-body .items h4{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 1.2;
  color: black;
  font-weight: 500;
  white-space: nowrap;
}
.card1 .card-body .items h3{
  color: rgb(83, 83, 83);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
}
.card1 .card-body .items p b{
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
}
.card1 .card-body .items span{
  color:  black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
/* .card1 .card-body .items span{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: rgb(224, 224, 224);
} */
.card2 .card-body .items h4{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 1.2;
  color: white;
  font-weight: 600;
}
.card2 .card-body .items span{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: rgb(224, 224, 224);
}
.card2 .card-body .items h2{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 66px;
  line-height: 1.2;
  color: white;
  font-weight: bold;
}

.card2 .card-body .items p{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.2;
  color: white;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.header-cont{
  position: relative;
  background-color:#1C4488 ;
  width: 100%;
  padding: 8px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* clip-path: polygon(0 0, 91% 0, 100% 100%, 0 100%);   */
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .header-cont {
      font-size: 1.2rem;
      padding: 8px 15px;
  }
}
.ms-3 h4{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: black;
  margin: 0;
}
.ms-3 p{
  margin: 0;
}
.header-cont h2{
  color: white;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family:Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding-right: 20px;
}

.card-custom {
  border-radius: 7px;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 25px 0;
   /* border: 2px solid #e5e5e5;  */
}
.custom-img {
  max-width: 120px; 
  height: auto;   
  object-fit: contain; 
}
.custom-img1{
  max-width: 150px; 
  height: auto;   
  object-fit: contain; 
}

.mt-3 h4{
  color:var(--green-color); ;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.remark h4{
  margin-top: 10px !important;
  color:var(--green-color); ;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.balanceremark h4{
  margin-top: 35px !important;
  color:var(--green-color); ;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.box{
  width: 50px;
  height: 50px;
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
 
}
.box-container {
  display: flex;
  align-items: center;
  gap: 15px;

}

.box .count{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
}

.circle-custom{
  width: 60px;
  height: 60px;
  border: 5px solid var(--green-color); ;
  border-radius: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
 
}
.circle-custom h2{
  font-size: 14px;
  margin: 0 !important;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
}
.circle-custom1{
  width: 60px;
  height: 60px;
  border: 5px solid var(--green-color); ;
  border-radius: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
 
}
.circle-custom1 h2{
  font-size: 12px !important;
  margin: 0 !important;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
}

.Summary{
font-size: 14px;
}
.high-chart{
  width: 60%;
  height: 200px;
  display: block;
}
.high-chart-data{
  width: 100%;
  height: 200px;
  display: block;
}

.form-select{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.btn {
  padding: var(--btn-padding);
}

.btn-primary {
  background: var(--btn-bg-color);

  font-size: var(--btn-font-size);
  font-family: var(--btn-font-family);
   /* box-shadow: var(--btn-box-shadow-one);  */
   color: var(--btn-color);
   font-weight: 600;
  border: none;
  white-space: nowrap;
}

.btn-primary:hover {
  cursor: pointer;
  background: var(--btn-bg-hover-color);
  transition: all 0.5s ease;
  color: var(--btn-hover-color);
  box-shadow: none;
}

.btn-primary:active {
  /* box-shadow: var(--btn-active-box-shadow); */
  transform: var(--btn-active-transform);
  color: var(--btn-hover-color);
  background: var(--btn-bg-color);
}


.donut-circle {
  width: 140px; /* Outer circle size */
  height: 140px;
  border-radius: 50%;
  background-color:orange; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-circle {
  width: 110px; /* Inner circle size */
  height: 110px;
  border-radius: 50%;
  background: rgb(213 238 255);/* White inner circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-circle h2 {
  margin: 0;
  font-size: 26px;
  color: #000; /* Text color */
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;

}


.dark-green {
  background-color: #006400; /* Dark Green */
}

.light-green {
  background-color: #32CD32; /* Light Green */
}

.yellow {
  background-color: orange; /* Yellow */
}

.red {
  background-color:rgb(247,74,62); /* Red */
}

/* .custom-table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 5px; 
} */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
thead th {
  background-color: #1C4488;
  color: white; 
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
}
tbody td{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 18px;
color: #000;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.donut-container{
  width: 100%;
  height: 100%;
  background: rgb(233, 246, 255);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  position: relative;
}
.score-tag{
  position: absolute;
  bottom: 0px;
  right: 5px;
  margin: 0;
}
.score-tag p{
  font-size: 9px;
  color: #999;
  margin: 0;
  padding: 0;
}
.donut-circle img{
  width: 100%;
  height: auto;
}
.equal-height {
  display: flex;
}

.equal-height > .col-md-9,
.equal-height > .col-md-3 {
  display: flex;
  flex-direction: column;
  
}

.col-md-9 {
  flex: 0 0 75%;
}

.col-md-3 {
  flex: 0 0 25%;
}
.table-img {
  max-width: 50px; 
  height: auto;   
  object-fit: contain; 
}
.custom-table th:nth-child(2),
.custom-table td:nth-child(2),
.custom-table th:nth-child(3),
.custom-table td:nth-child(3){
    width: 14%; 
}
.custom-table th:nth-child(2),
.custom-table td:nth-child(2),
.custom-table th:nth-child(3),
.custom-table td:nth-child(3),
.custom-table th:nth-child(4),
.custom-table td:nth-child(4){
  text-align: center;
}
.custom-table tbody tr td:first-child {
  font-family: Arial, Helvetica, sans-serif; 
  font-weight: 300; 
  font-size: 18px;
}

tbody td.rating{
  font-family: var(--font-1) !important; 
  color: white !important;
  font-weight: 300 !important;
  font-size: 18px !important;
}


/* Set default width and height for all tables */
table {
  height: auto; /* Let the table grow based on content, adjust this if you want a fixed height */
  min-height: 200px; /* Set a minimum height so all tables maintain a baseline size */
  max-height: 100%;  /* Prevent the table from growing too large */
  table-layout: fixed; /* Ensure the table columns adjust uniformly */
}

/* You can also customize the default column widths */
table th,
table td {
  padding: 10px;
  text-align: left;
  word-wrap: break-word; /* Allow text to wrap if content is too long */
}

/* Set default width for columns (optional) */
table th:nth-child(1),
table td:nth-child(1) {
  width: 40%; /* Adjust based on your needs */
}

table th:nth-child(2),
table td:nth-child(2) {
  width: 15%;
}

table th:nth-child(3),
table td:nth-child(3) {
  width: 15%;
}

table th:nth-child(4),
table td:nth-child(4) {
  width: 30%;
}

/* Set scroll behavior for tables with fixed height */
table {
  overflow: auto;
}
.main-title h4 {
  text-transform: uppercase;
  font-family: 'Times New Roman', serif;
  font-weight: bold;
  font-size: 28px;
}

.subtitle h6 {

  color: #1C4488;
  text-transform: uppercase;
  font-family: 'Times New Roman', serif;
  font-weight: 500;
  font-size: 22px;
 
}
.desc{
  text-transform: none;
  font-size: .7em;
  font-weight: normal;
  font-style: italic;
  font-family: "Playfair Display", "Bookman", serif;
  color: #666666;
  letter-spacing: -0.005em;
  word-spacing: 1px;
  letter-spacing: none;
  margin: 0;
}
 
.bench-tag h5{
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}
.bench-tag h5 span{
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-family: "Playfair Display", "Bookman", serif;
  color: #666666;
  letter-spacing: -0.005em;
  word-spacing: 1px;
  letter-spacing: none;
  margin: 0;
}
/* app.component.css */
button {
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #007bff;
  color: white;
  z-index: 1100;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}



.back-to-top:hover{
  background-color:transparent;
  border: 1px solid rgb(46, 46, 46);
  color: black;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
  z-index: 1100;

}
form .fields .input-field {
  display: flex;
  /* width: calc(100% / 4 + 25px); */
  flex-direction: column;
  margin: 4px 0;
}

.input-field label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.input-field input,
.input-field select {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  height: 100px;
  margin: 8px 0;
}

.input-field input:focus,
.input-field select:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.input-field select,
.input-field input[type="date"] {
  color: #707070;
}
.input-field input::placeholder {
  color: white; /* Default placeholder color */
}

.input-field input.invalid-field::placeholder {
  color: red; /* Placeholder color when field is invalid */
}
.input-field input.has-value::placeholder {
  color: white; /* Placeholder color when field has a value */
}
.input-field textarea.invalid-field::placeholder {
  color: red; /* Placeholder color when field is invalid */
}
.input-field textarea.invalid-field::placeholder {
  color: red; /* Placeholder color when field is invalid */
}
.input-field textarea.invalid-field {
  border: 1px solid red;
 
}
.input-field input.invalid-field {
  border: 1px solid red;
 
}

.error-message {
  color: red;
  font-size: 10px; /* Extra small text */
  margin-top: 5px;
  font-family: Arial, sans-serif; /* Ensure consistent font */
}

.input-field label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.input-field input,.input-field textarea,
.input-field select {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  height: 100px;
  margin: 8px 0;
}

.input-field input :focus,
.input-field select:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.input-field select,
.input-field input[type="date"] {
  color: #707070;
}

.input-field input[type="date"]:valid {
  color: #333;
}
.input-field input[type="date"]:valid {
  color: #333;
}
.ng-select-custom {
  background-color: var(--green-color);
  box-shadow: 1px var(--green-color) ;
  border-radius: 50px; 
  appearance: none; 
  outline: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 14px;
  width: 180px;
  padding: 5px 15px;
  position: relative;
  transition: box-shadow 0.3s ease;
  color: white;

}
 
/* Style the ng-select container */
.ng-select-custom .ng-select-container {
  border: none;
  background-color: transparent;
  color: rgb(228, 228, 228); 
  display: flex;
  align-items: center;
  justify-content: space-between; 
 
}

/* Center the dropdown panel */
::ng-deep ng-dropdown-panel {
  display: flex;
  width: 180px;
  justify-content: center; 
  margin-top: 6px; 
  border-radius: 18px;
  border-bottom: none; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
  background-color: white;
  left: 0;

}
 
/* Style the dropdown icon */
.ng-select-custom .fa-chevron-down {
  position: absolute;
  top: 20%;
  right: 5px;  
  pointer-events: none;
  color:rgb(255, 255, 255); 
  font-size: 12px; 
}
 
/* Customize dropdown panel */
::ng-deep ng-dropdown-panel .ng-option {
  color: #0d121b;
  background-color: #fff;
  padding: 5px 10px;
  width: 180px !important;
 
}
 
/* Hover effect for dropdown options */
::ng-deep ng-dropdown-panel .ng-option:hover {
  background-color: var(--green-color); /* Light gray background on hover for a nice effect */
  color: white;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

    `;
    return styles;
  }
  async generateSummary() {
    if (!this.selectedPlayer) {
      console.log("Please select players")
      return;
    }
    
    const athleteIds = this.selectedPlayer.map((player: { PlayerId: any; }) => player.PlayerId);
    this.athleteCount = athleteIds.length;
    console.log(this.athleteCount,"athleteCount")
   await this.getUserCategoryRemarks(athleteIds);
    this.isLoading=true;   
  }
  async getAllAssessmentData(athleteIds: number[]) {
    for (const athleteId of athleteIds) {
      try {
        const result = await forkJoin({
          fitnessData: this.assessmentService.getPhysicalFitnessByAsm(athleteId, this.asmId),
          cognitiveData: this.assessmentService.getCognitiveByAsm(athleteId, this.asmId),
          injuryPreventionData: this.assessmentService.getInjuryByAsm(athleteId, this.asmId),
          nutritionData: this.assessmentService.getNutritionByAsm(athleteId, this.asmId),
          sportsPerformanceData: this.assessmentService.getSportsperformanceByAsm(athleteId, this.asmId),
          cardioData: this.assessmentService.getCardioByAsm(athleteId, this.asmId)
        }).toPromise(); // Convert observable to promise for sequential execution
  
        this.formData = this.formatAssessmentData(result);
        console.log(this.formData, `Assessment Data for Athlete ${athleteId}`);
        this.playerId = athleteId;
  
        // Trigger getSummary after assigning formData
        await this.getSummary();
      } catch (error) {
        console.error(`Error fetching data for Athlete ${athleteId}:`, error);
      }
    }
  }
  

  formatAssessmentData(data: any) {
    return {
      fitness: data.fitnessData,
      cognitive: data.cognitiveData,
      injuryPrevention: data.injuryPreventionData,
      nutrition: data.nutritionData,
      sportsPerformance: data.sportsPerformanceData,
      cardio: data.cardioData
    };
  }


  getUserCategoryRemarks(athleteIds: any) {
    this.isLoading = true; // Start loading
    const athletePromises = athleteIds.map((athleteId: any) =>
      this.assessmentService.getUserCategoryRemarks([athleteId], this.asmId).toPromise()
    );
    
    Promise.all(athletePromises)
      .then((responses: any[]) => {
        // Combine all responses into a single array
        this.categoryRemark = responses.flat();
    
        // Check if any player already has remarks
        const playersWithRemarks = this.categoryRemark.filter((remark: { ausId: any }) => remark.ausId);
    
        if (playersWithRemarks.length > 0) {
          const confirmUpdate = confirm("Some players already have remarks. Do you want to update?");
          if (!confirmUpdate) {
            this.isLoading = false;
            return; // Exit if user cancels
          }
        }
    
        // Populate remark object with the first player's data (if available)
        if (this.categoryRemark.length > 0) {
          const firstRemark = this.categoryRemark[0];
          this.remark.ausId = firstRemark.ausId ?? 0;
          this.remark.ausAssessment = firstRemark.ausAssessment ?? this.asmId;
          this.remark.ausAthlete = firstRemark.ausAthlete ?? athleteIds;
          this.remark.ausSummary = firstRemark.ausSummary;
          this.remark.ausActionPlan = firstRemark.ausActionPlan;
          this.remark.ausRemark = firstRemark.ausRemark;
          this.remark.ausEndurance = firstRemark.ausEndurance;
          this.remark.ausAgility = firstRemark.ausAgility;
          this.remark.ausStrength = firstRemark.ausStrength;
          this.remark.ausUpperBodyPower = firstRemark.ausUpperBodyPower;
          this.remark.ausLowerBodyPower = firstRemark.ausLowerBodyPower;
          this.remark.ausFlexibility = firstRemark.ausFlexibility;
          this.remark.ausBalance = firstRemark.ausBalance;
          this.remark.ausCardio = firstRemark.ausCardio;
          this.remark.ausNutrition = firstRemark.ausNutrition;
          this.remark.ausInjury = firstRemark.ausInjury;
          this.remark.ausCognitive = firstRemark.ausCognitive;
          this.remark.ausIsokinetic = firstRemark.ausIsokinetic;
          this.remark.ausConcentric = firstRemark.ausConcentric;
        }
    
        console.log(this.remark, "datas");
        this.isLoading = true;
        this.getAllAssessmentData(athleteIds);
      })
      .catch(error => {
        console.error("Error fetching category remarks:", error);
        this.isLoading = false; // Stop loading even if an error occurs
      });
    
  }
  
  getAllRemarks() {
    this.getEnduranceRemark()
    this.getStrengthRemark()
    this.getFlexibilityRemark()
    this.getBalanceRemark()
    this.getUpperBodyPowerRemark()
    this.getLowerBodyPowerRemark()
    this.getAgilityRemark()
    this.getNutRemark()
    this.getCardioRemark()
    this.getInjuryRemark()
    this.getCognitiveRemark()
    this.getIsokinetic()
    this.getConcentric()
    this.saveRemark()
  }
  async getSummary() {
    this.jsonData = this.formData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Sports performance test report. Analyze this report thoroughly and provide detailed Area of Improvement on the player";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    //Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.summary = chatContent;
        console.log(this.summary, "summary");
        await this.getActionPlan()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
  }
  async getActionPlan() {
    this.jsonData = this.formData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Sports performance test report. Analyze this report thoroughly and provide detailed Action plan on the player";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.actionPlan = chatContent;
        console.log(this.actionPlan, "summary");
        await this.getRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
  }
  async getRemark() {
    this.jsonData = this.formData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Sports performance test report. Analyze this report thoroughly and provide detailed remark on the player with 20 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.summaryRemark = chatContent;
        console.log(this.summaryRemark, "summary");
        await this.getNutRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
  }
  async getNutRemark() {
    if (this.formData.nutrition) {
      const transformedData = Object.fromEntries(
        Object.entries(this.formData.nutrition[0]).map(([key, value]) => {
          const newKey = key.startsWith("na") ? key.slice(2) : key;
          return [newKey.charAt(0).toLowerCase() + newKey.slice(1), value];
        })
      );
      this.jsonData = transformedData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's nutrition report. Analyze this report thoroughly and provide detailed remarks on the player's nutrition with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.nutRemark = chatContent;
        console.log(this.nutRemark, "nutRemark");
        await this.getCardioRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getCardioRemark()
    }

  }
  async getCardioRemark() {
    if (this.formData.cardio.length > 0) {
      const transformedData = Object.fromEntries(
        Object.entries(this.formData.cardio[0]).map(([key, value]) => {
          const newKey = key.startsWith("coa") ? key.slice(2) : key;
          return [newKey.charAt(0).toLowerCase() + newKey.slice(1), value];
        })
      );
      this.jsonData = transformedData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Cardio report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.cardioRemark = chatContent;
        console.log(this.cardioRemark, "cardioRemark");
        await this.getInjuryRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getInjuryRemark()
    }

  }
  async getInjuryRemark() {
    if (this.formData.injuryPrevention.length > 0) {
      const transformedData = Object.fromEntries(
        Object.entries(this.formData.injuryPrevention[0]).map(([key, value]) => {
          const newKey = key.startsWith("ipa") ? key.slice(2) : key;
          return [newKey.charAt(0).toLowerCase() + newKey.slice(1), value];
        })
      );
      this.jsonData = transformedData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Injury Prevention Analysis report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.injuryRemark = chatContent;
        console.log(this.injuryRemark, "injuryRemark");
        await this.getCognitiveRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getCognitiveRemark()
    }

  }
  async getCognitiveRemark() {
    if (this.formData.cognitive.length > 0) {
      const transformedData = Object.fromEntries(
        Object.entries(this.formData.cognitive[0]).map(([key, value]) => {
          const newKey = key.startsWith("cna") ? key.slice(2) : key;
          return [newKey.charAt(0).toLowerCase() + newKey.slice(1), value];
        })
      );
      this.jsonData = transformedData;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Cognitive Analysis report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.cogRemark = chatContent;
        console.log(this.cogRemark, "cogRemark");
        await this.getIsokinetic()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getIsokinetic()
    }

  }
  async getIsokinetic() {
    if (this.formData.sportsPerformance.length > 0) {
      this.isokinetic.RightAverageTorque = this.formData.sportsPerformance[0].spaAverageTorqueIR
      this.isokinetic.RightPeakTorque = this.formData.sportsPerformance[0].spaPeakTorqueIR
      this.isokinetic.LeftAverageTorque = this.formData.sportsPerformance[0].spaAverageTorqueIL
      this.isokinetic.LeftPeakTorque = this.formData.sportsPerformance[0].spaPeakTorqueIL
      this.jsonData = this.isokinetic;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Sports Performance Analysis Isokinetic Dynamometer report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.isokineticRemark = chatContent;
        console.log(this.isokineticRemark, "isokineticRemark");
        await this.getEnduranceRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getEnduranceRemark()
    }
  }
  async getConcentric() {
    if (this.formData.sportsPerformance[0].spaAverageTorqueCR || this.formData.sportsPerformance[0].spaPeakTorqueCR || this.formData.sportsPerformance[0].spaAverageTorqueCL || this.formData.sportsPerformance[0].spaPeakTorqueCL) {
      this.Concentric.RightAverageTorque = this.formData.sportsPerformance[0].spaAverageTorqueCR
      this.Concentric.RightPeakTorque = this.formData.sportsPerformance[0].spaPeakTorqueCR
      this.Concentric.LeftAverageTorque = this.formData.sportsPerformance[0].spaAverageTorqueCL
      this.Concentric.LeftPeakTorque = this.formData.sportsPerformance[0].spaPeakTorqueCL
      this.jsonData = this.isokinetic;
    console.log(this.jsonData, "json data");
  
    const assessmentDataJson = JSON.stringify(this.jsonData);
    const message = "This is my player's Sports Performance Analysis Concentric Dynamometer report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
    const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }`;
  
    console.log(combinedMessage, "sumdata");
  
    // Wait for the chat response using firstValueFrom
    try {
      const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        this.ConcentricRemark = chatContent;
        console.log(this.ConcentricRemark, "ConcentricRemark");
        await this.getEnduranceRemark()
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
    } catch (error) {
      console.error('Error fetching chat response:', error);
    }
    } else {
      await this.getEnduranceRemark()
    }

  }
  async getEnduranceRemark() {
    if (this.formData.fitness.length > 0) {
      if (this.formData.fitness[0].pfaYoYoTest || this.formData.fitness[0].pfaLevel || this.formData.fitness[0].pfaVO2 || this.formData.fitness[0].pfaSpeed) {
        this.endurance.DistanceCovered = this.formData.fitness[0].pfaYoYoTest
        this.endurance.Level = this.formData.fitness[0].pfaLevel
        this.endurance.VO2Max = this.formData.fitness[0].pfaVO2
        this.endurance.Speed = this.formData.fitness[0].pfaSpeed
        this.jsonData = this.endurance;
        console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's Endurance report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.enduranceRemark = chatContent;
            console.log(this.enduranceRemark, "enduranceRemark");
            await this.getStrengthRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
      } else {
        await this.getStrengthRemark()
      }
    } else {
      await this.saveRemark()
    }

  }
  async getStrengthRemark() {
    if (this.formData.fitness[0].pfaPushups || this.formData.fitness[0].pfaSquat || this.formData.fitness[0].pfaPartialCurlUp || this.formData.fitness[0].pfaGripStrengthRight || this.formData.fitness[0].pfaGripStrengthLeft) {
      this.strength.PushUps = this.formData.fitness[0].pfaPushups
      this.strength.Squats = this.formData.fitness[0].pfaSquat
      this.strength.PartialCurlUps = this.formData.fitness[0].pfaPartialCurlUp
      this.strength.RightHandGrip = this.formData.fitness[0].pfaGripStrengthRight
      this.strength.LeftHandGrip = this.formData.fitness[0].pfaGripStrengthLeft

      this.jsonData = this.strength;
      console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's Strength report Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.strengthRemark = chatContent;
            console.log(this.strengthRemark, "strengthRemark");
            await this.getFlexibilityRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
    } else {
      await this.getFlexibilityRemark()
    }

  }
  async getFlexibilityRemark() {
    if (this.formData.fitness[0].pfaAppleysScratchTest || this.formData.fitness[0].pfaSitNReach) {
      this.flexibility.ApleysScratchTest = this.formData.fitness[0].pfaAppleysScratchTest
      this.flexibility.SitandReach = this.formData.fitness[0].pfaSitNReach

      this.jsonData = this.flexibility;
      console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's Flexibility report Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.flexRemark = chatContent;
            console.log(this.flexRemark, "flexRemark");
            await this.getBalanceRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
    } else {
      await this.getBalanceRemark()
    }

  }
  async getBalanceRemark() {
    if (this.formData.fitness[0].pfaSingleLegBalanceRightEyesOpen || this.formData.fitness[0].pfaSingleLegBalanceLeftEyesOpen || this.formData.fitness[0].pfaSingleLegBalanceRightEyesClose || this.formData.fitness[0].pfaSingleLegBalanceLeftEyesClose) {
      this.balance.StaticBalanceRightEyesOpen = this.formData.fitness[0].pfaSingleLegBalanceRightEyesOpen
      this.balance.StaticBalanceLeftEyesOpen = this.formData.fitness[0].pfaSingleLegBalanceLeftEyesOpen
      this.balance.StaticBalanceRightEyesClose = this.formData.fitness[0].pfaSingleLegBalanceRightEyesClose
      this.balance.StaticBalanceLeftEyesClose = this.formData.fitness[0].pfaSingleLegBalanceLeftEyesClose
      this.jsonData = this.balance;
      console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's Balance Test report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.balancehRemark = chatContent;
            console.log(this.balancehRemark, "balancehRemark");
            await this.getUpperBodyPowerRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
    } else {
      await this.getUpperBodyPowerRemark()
    }

  }
  async getUpperBodyPowerRemark() {
    if (this.formData.fitness[0].pfaAppleysScratchTest || this.formData.fitness[0].pfaSitNReach || this.formData.fitness[0].pfaAppleysScratchTest) {
      this.upperBodyPower.SeatedMedBallThrows = this.formData.fitness[0].pfaAppleysScratchTest
      this.upperBodyPower.Force = this.formData.fitness[0].pfaSitNReach
      this.upperBodyPower.Power = this.formData.fitness[0].pfaAppleysScratchTest

      this.jsonData = this.upperBodyPower;
      console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's upper Body Power Test report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.UBPRemark = chatContent;
            console.log(this.UBPRemark, "UBPRemark");
            await this.getLowerBodyPowerRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
    } else {
      await this.getLowerBodyPowerRemark()
    }

  }
  async getLowerBodyPowerRemark() {
    if (this.formData.fitness[0].pfaAppleysScratchTest || this.formData.fitness[0].pfaSitNReach || this.formData.fitness[0].pfaAppleysScratchTest) {
      this.lowerBodyPower.BroadJump = this.formData.fitness[0].pfaAppleysScratchTest
      this.lowerBodyPower.Force = this.formData.fitness[0].pfaSitNReach
      this.lowerBodyPower.Power = this.formData.fitness[0].pfaAppleysScratchTest
      this.jsonData = this.lowerBodyPower;
      console.log(this.jsonData, "json data");
  
      const assessmentDataJson = JSON.stringify(this.jsonData);
      const message = "This is my player's Lower Body Power Test report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
      const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }`;
    
      console.log(combinedMessage, "sumdata");
    
      // Wait for the chat response using firstValueFrom
      try {
        const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
        const chatContent = chatResponse?.choices?.[0]?.message?.content;
        if (chatContent) {
          this.LBPRemark = chatContent;
          console.log(this.LBPRemark, "LBPRemark");
          await this.getAgilityRemark()
        } else {
          console.error('Unexpected chat response structure:', chatResponse);
        }
      } catch (error) {
        console.error('Error fetching chat response:', error);
      }
    } else {
      await this.getAgilityRemark()
    }

  }
  async getAgilityRemark() {
    if (this.formData.fitness[0].pfaTTest) {
      this.Ttest = this.formData.fitness[0].pfaTTest
      this.jsonData = this.Ttest;
      console.log(this.jsonData, "json data");
      
        const assessmentDataJson = JSON.stringify(this.jsonData);
        const message = "This is my player's Agility Test report. Analyze this report thoroughly and provide detailed remarks on the player with 40 words";
        const combinedMessage = `
        {
          "data": ${assessmentDataJson},
          "message": "${message}"
        }`;
      
        console.log(combinedMessage, "sumdata");
      
        // Wait for the chat response using firstValueFrom
        try {
          const chatResponse = await firstValueFrom(this.chatService.getChatResponse(combinedMessage));
          const chatContent = chatResponse?.choices?.[0]?.message?.content;
          if (chatContent) {
            this.Ttestemark = chatContent;
            console.log(this.Ttestemark, "Ttestemark");
            await this.saveRemark()
          } else {
            console.error('Unexpected chat response structure:', chatResponse);
          }
        } catch (error) {
          console.error('Error fetching chat response:', error);
        }
    } else {
      await this.saveRemark()
    }

  }
  saveRemark() {
    this.remark.ausId= 0
    this.remark.ausAssessment = this.asmId
    this.remark.ausAthlete = this.playerId
    this.remark.ausSummary = this.summary ?? this.remark.ausSummary;
    this.remark.ausActionPlan = this.actionPlan ?? this.remark.ausActionPlan;
    this.remark.ausRemark = this.summaryRemark ?? this.remark.ausRemark;
    this.remark.ausEndurance = this.enduranceRemark ?? this.remark.ausEndurance;
    this.remark.ausAgility = this.Ttestemark ?? this.remark.ausAgility;
    this.remark.ausStrength = this.strengthRemark ?? this.remark.ausStrength;
    this.remark.ausUpperBodyPower = this.UBPRemark ?? this.remark.ausUpperBodyPower;
    this.remark.ausLowerBodyPower = this.LBPRemark ?? this.remark.ausLowerBodyPower;
    this.remark.ausFlexibility = this.flexRemark ?? this.remark.ausFlexibility;
    this.remark.ausBalance = this.balancehRemark ?? this.remark.ausBalance;
    this.remark.ausCardio = this.cardioRemark ?? this.remark.ausCardio;
    this.remark.ausNutrition = this.nutRemark ?? this.remark.ausNutrition;
    this.remark.ausInjury = this.injuryRemark ?? this.remark.ausInjury;
    this.remark.ausCognitive = this.cogRemark ?? this.remark.ausCognitive;
    this.remark.ausIsokinetic = this.isokineticRemark ?? this.remark.ausIsokinetic;
    this.remark.ausConcentric = this.ConcentricRemark ?? this.remark.ausConcentric;
    console.log(this.remark, "remark")
    this.assessmentService.saveUserCategoryRemarks([this.remark]).subscribe(response => {
      this.successCount++;
      if (this.successCount === this.athleteCount) {
        // Show success message only when all saves are complete
        this.ngZone.run(() => {
          this.notify.success("Updated successfully");
          this.successCount=0
          this.cdRef.detectChanges();
        });
      }
      this.isLoading=false;
    }, error => {
      this.isLoading=false;
      this.ngZone.run(() => {
        this.notify.failed("Erroe Updating remark");
        this.cdRef.detectChanges();
      });

    });
  }
}
