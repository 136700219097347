<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<div class="body-wrapper">

  <br>
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
         <div class="d-flex justify-content-between align-items-start">

      <div>
          <button class="btn btn-primary mb-2" (click)="onBack()">Back</button>
        </div>

            <div class="d-flex justify-content-end gap-3">

              <div class="select-wrapper" *ngIf="(Admin || IsScience) && (this.status == 1)">
                  <ng-select 
                      class="ng-select-custom" 
                      [items]="statusOptions" 
                      [searchable]="false" 
                      [clearable]="false" 
                      [(ngModel)]="selectedStatus" 
                      bindLabel="label" 
                      bindValue="value">
                      
                      <!-- Custom label template -->
                      <ng-template ng-label-tmp let-item="item">
                          <span>{{ item.label }}</span>
                          <i class="fa fa-chevron-down ml-2"></i>
                      </ng-template>
                      
                      <!-- Optional custom dropdown item template -->
                      <ng-template ng-option-tmp let-item="item">
                          <span>{{ item.label }}</span>
                      </ng-template>
                  </ng-select>
              </div>
              <button *ngIf="(Admin || IsScience) && (this.status == 1)" class="btn btn-primary mb-2" (click)="printPDF()">Download Report</button>

              <button *ngIf="(Admin || IsScience) && (this.status == 1)" class="btn btn-primary mb-2" (click)="generateSummary()" >Generate Summary</button>

          </div>
        </div>
          <div class="d-sm-flex justify-content-between align-items-start">
            <div class="asm-title">
              <h4 class="card-title card-title-dash">{{this.test[0].asmName}}</h4>
              <!-- <p class="card-subtitle card-subtitle-dash">{{this.test[0].asmDescription}}</p> -->
            </div>
          </div>
          <!-- <div class="scrollable-div">
            <p class="card-subtitle card-subtitle-dash">{{this.test[0].asmDescription}}</p>
             </div> -->
            <div class="container mt-2">
              <div class="container-custom">
                  <div class="card-custom">
                      <div class="icon-wrapper">
                          <i class="fa fa-building"></i>
                      </div>
                      <span class="title">Organization : {{this.test[0].orgName}}</span>
                  </div>
                  <div class="card-custom">
                      <div class="icon-wrapper">
                          <i class="fa fa-users"></i>
                      </div>
                      <span class="title">Team : {{this.test[0].teamName}}</span>
                  </div>
              </div>
          </div>
            
          <div class="container-fluid">
            <div class="content" style="width: 100%; height: 100%;">
              <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true" (gridReady)="onGridReady($event)"/>
            </div>
            <div class="table-responsivee">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sub Category</th>
                    <th scope="col">Tests</th>
                    <th scope="col">Assessor</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let group of groupedData | keyvalue">
                    <tr>
                      <th colspan="3"  style="color:black">{{ group.key }}</th>
                    </tr>
                    <ng-container *ngFor="let assessorGroup of group.value | keyvalue">
                      <tr>
                        <td></td>
                        <!-- <td>{{ assessorGroup.key }}</td> -->
                        <td>
                          <div *ngFor="let assessment of assessorGroup.value">
                            {{ assessment.testName }} <span style="font-size: 10px;">{{ assessment.testUnit }}</span>
                          </div>
                        </td>
                        <td>{{ assessorGroup.value[0]?.assesserName }}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>