import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Input, Output, EventEmitter, OnChanges, NgZone,  SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import html2canvas from 'html2canvas';
import c3 from 'c3';
import { PlayerDashboardComponent } from '../../player-dashboard/player-dashboard.component';
import { AssessmentService } from '../../services/assessment.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { ChatService } from '../../services/chat.service';
import jsPDF from 'jspdf';
import { NgSelectModule } from '@ng-select/ng-select';
 
@Component({
  selector: 'app-cognitive-dashboard',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule, FormsModule, CognitiveDashboardComponent,NgSelectModule],
  templateUrl: './cognitive-dashboard.component.html',
  styleUrl: './cognitive-dashboard.component.css'
})
export class CognitiveDashboardComponent implements OnChanges {
 
  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any = null;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>();
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = ''; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = null;
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  physioloyPerformers: any[] = [];
  admitcardReport: any;
  msg: any;
  coaResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  cognitivePerformers: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: string = '';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  user: any;
  teams: any[] = []; // Holds the teams data
  selectedTeam: string = ''; // Holds the selected team name
  cogResponse: any;
  selectedPerformerType: number = 0;
  filteredReport: any[] = [];
  reportTitle: string = "Overall";
  gender: number = 1;
  sport: number = 11;
  IBMReference: any;
  averageCNaRulerDrop: any;
  averageCNaBlazePods: any;
  averageCNaBlazepodsTime: any;
  averageCNaWallToss: any;
  averageCNaPlateTaping: any;
  ImageUrl: any;
  showImage: boolean=false;
  image: any;
  sciStaff: boolean = false;
  athletes: any;
  cognitiveCount: any;
  averageCNaRulerDropz: any;
  averageCNaBlazePodsz: any;
  IsAssessor: boolean = false;
  selectOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Excellent' },
    { value: 2, label: 'Good' },
    { value: 3, label: 'Average' },
    { value: 4, label: 'Poor' },
    ];
  aiData: any;
  playerData: any;
  
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }
  onCognitiveStatusChange() {
    this.loadCognitiveTopPerformersData();
  }
 
  async ngOnInit(): Promise<void> {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.PhysicalFitness=this.privilege?.isPhysicalFitness ?? false;
    this.user = this.userService.getUser();
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.IsAssessor = this.privilege.isAssessor ?? false;
    this.getPlayer();
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.isLoading = true;
 
 
    this.getCognitiveReport().then(() => {
 
      this.loadCognitiveTopPerformersData();
    });
 
    this.assessmentService.getInterNationalBenchMark(this.gender, this.sport).subscribe(response => {
      this['IBMReference'] = response;
      console.log(this['IBMReference'], "IBMReference");
    }, error => {
      console.error("Error fetching IBMReference:", error);
    });
    this.isLoading = false;
 
 this.ibmCalculation()
  }
  ibmCalculation(){
    const genderId = 1; // Replace with actual values
    const asiCurrentClubTeam = '11'; // Replace with actual values

    this.assessmentService.getInterNationalBenchMark(genderId, asiCurrentClubTeam).subscribe((response: any[]) => {
      console.log(response, "IBM Report");

      // Process each player's data
      this.cognitiveResponse.forEach((player: any) => {
        const playerIBM: Record<string, number> = {}; // Define as a record with string keys and number values

        // Match each testColumnName with the player columns
        response.forEach(ibm => {
          const columnName = ibm.testColumnName; // e.g., 'naBodyWater'
          let ibmRange = ibm.ibmRange; // e.g., "7–12", "2.5–4.0", or "16–20"
        
          if (player[columnName] !== undefined && ibmRange) {
            // Normalize the range by replacing non-standard dashes with a hyphen
            ibmRange = ibmRange.replace(/–/g, '-');
        
            // Validate and parse the range
            const rangeMatch = ibmRange.match(/^([\d.]+)-([\d.]+)$/); // Matches ranges like "7-12" or "17.2-21.4"
        
            if (rangeMatch) {
              const lower = parseFloat(rangeMatch[1]); // Parse the lower bound
              const upper = parseFloat(rangeMatch[2]); // Parse the upper bound
        
              if (!isNaN(lower) && !isNaN(upper)) {
                const value = player[columnName];
        
                // Calculate percentage
                let percentage = ((value - lower) / (upper - lower)) * 100;
        
                // Ensure percentage is positive and capped at 100
                percentage = Math.min(Math.abs(percentage), 100);
        
                // Round to 2 decimal places
                percentage = parseFloat(percentage.toFixed(2));
        
                playerIBM[columnName] = percentage; // Store percentage
              }
            }
          }
        });
        
        
        player['nutIBM'] = playerIBM;
      });

      console.log(this.cognitiveResponse, "Updated cogResponse with nutIBM");
    });
  }
  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;

    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
 
      const a1 = 0.254829592;
      const a2 = -0.284496736;
      const a3 = 1.421413741;
      const a4 = -1.453152027;
      const a5 = 1.061405429;
      const p = 0.3275911;
 
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

      return sign * y;
    };

    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }
  orgStudents() {
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
      });
    }
  }
  getPlayer() {
    this.orgId = this.OrgAdmin ? this.org : this.orgId;

    if (this.orgId) {
      this.assessmentService.getAthleteByOrg(this.orgId).subscribe(
        (athletesData) => {
          this.athletes = athletesData;
          if (this.Coach) {
            this.cognitiveCount = this.athletes.filter(
              (item: any) => item.asiCoach === this.UserId
            ).length;
          } else {
           if (this.selectedTeamId != '0') {
              this.cognitiveCount = this.athletes.filter(
                (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
              ).length;
            } else {
              // If no team is selected, count all athletes
              this.cognitiveCount = this.athletes.length;
            }
          }
         
        },
        (error) => {
          console.error('Error fetching athletes:', error);
         
        }
      );
    } else {
      console.error('Organization ID is missing.');
      this.cognitiveCount = this.athletes.length; 
    }
  }
  
  sendMessage() {
    this.apiResponse = this.playerData;
    this.msg = " give me result with their cnaAthlete,athleteFullName,sports and result in both text and json format";
 
    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
    `;
    console.log(combinedMessage,"combinedMessage")
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          // Extract JSON array or object from the chat response
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            this.aiData = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            this.chatData.emit(this.chat);
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          this.chatData.emit(this.chat);
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }
 
      this.isLoading = false;
      // Update each performer with matching assessment data and image URL
      this.athletedata.forEach((performer: any) => {
        const matchingAssessment = this.cognitiveResponse.find((item: any) => item.cnaAthlete === performer.cnaAthlete)
        if (matchingAssessment) {
          Object.assign(performer, matchingAssessment);
          this.getProfileImage(performer.cnaAthlete)
          this.getCognitiveReport();
        } else {
          console.error(`No matching assessment found `);
        }
 
      });
      this.aiData.forEach((result: any) => {
        const matchingData = this.cognitiveResponse.find((item: any) =>  item.cnaAthlete === result.cnaAthlete)
           if (matchingData) {
             Object.assign(result, matchingData);
           } else {
             console.error(`No matching data found`);
           }
       });
       this.chatData.emit(this.aiData ?? this.chat);
    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });
 
  }
 
  getFilteredChat(): string {
    // Assuming "JSON Format" starts from a specific keyword you want to hide
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  // Return text before "JSON Format"
    }
    return this.chat;  // If "JSON Format" is not found, return the full text
  }
 
 
  onTeamSelected(selectedValue: any): void {
    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;
 
    // Fetch cognitive report data and load the top performers
    this.getCognitiveReport().then(() => {
      // Load top performers with the updated selected team ID
      this.loadCognitiveTopPerformersData();
    });
  }
 
  loadCognitiveTopPerformersData() {
 
    if (this.selectedTeamId == 0) {
 
      this.cogResponse = this.athleteReport;
    } else {
      if(this.Coach){
        this.cogResponse = this.athleteReport;
      }else{
        this.cogResponse = this.athleteReport.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      }
      
    }
    // Determine the top performers based on selected status
    if (this.selectedStatus == 0) {
      // Show all data
      this.CognitivePerformers = this.cogResponse
      .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      this.header = "All";
    } else if (this.selectedStatus == 1) {
      // Show top performers with overallZScore >= 85
      this.CognitivePerformers = this.cogResponse
        .filter((performer: any) => performer.overallZScore >= 85)
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore)
        ; // Get the top 10 performers
      this.header = "Excellent";
    } else if (this.selectedStatus == 2) {
      // Show under performers with overallZScore < 85
      this.CognitivePerformers = this.cogResponse
        .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85) // Filter for scores < 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore) // Sort ascending
        ; // Get the bottom 10 performers
      this.header = "Good";
    } else if (this.selectedStatus == 3) {
      // Show under performers with overallZScore < 85
      this.CognitivePerformers = this.cogResponse
        .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70) // Filter for scores < 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore) // Sort ascending
        ; // Get the bottom 10 performers
      this.header = "Average";
    } else {
      // Show under performers with overallZScore < 85
      this.CognitivePerformers = this.cogResponse
        .filter((performer: any) => performer.overallZScore < 40) // Filter for scores < 85
        .sort((a: any, b: any) => a.overallZScore - b.overallZScore) // Sort ascending
        ; // Get the bottom 10 performers
      this.header = "Poor";
    }
    // Fetch profile images for each performer
    this.CognitivePerformers.forEach((performer: any) => {
      this.getProfileImage(performer.cnaAthlete)
    });
  }
 
  async filterPerformers(type: 'All' | 'Excellent' | 'Good' | 'Average' | 'Poor', criteria: 'overall') {
    if (this.athleteReport && this.athleteReport.length > 0) {
      let sortedPerformers = [];
      let reportTitle = '';
   
      if (type === 'Excellent') {
        sortedPerformers = this.athleteReport
          .filter((performer: any) => performer.overallZScore >= 85)
          .sort((a, b) => b.overallZScore - a.overallZScore);
        reportTitle = 'Excellent';
      } else if (type === 'Good') {
        sortedPerformers = this.athleteReport
          .slice()
          .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85)
          .sort((a, b) => b.overallZScore - a.overallZScore);
        reportTitle = 'Good';
      } else if (type === 'Average') {
        sortedPerformers = this.athleteReport
          .slice()
          .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70)
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        reportTitle = 'Average';
      } else if (type === 'Poor') {
        sortedPerformers = this.athleteReport
          .slice()
          .filter((performer: any) => performer.overallZScore < 40)
          .sort((a, b) => a.overallZScore - b.overallZScore)
          .slice(0, 10);
        reportTitle = 'Poor';
      } else {
        sortedPerformers = this.athleteReport
          .sort((a, b) => b.overallZScore - a.overallZScore)
          .slice(0, 10);
        reportTitle = 'Overall';
      }
      
      // No change to averageOverallPercentage, calculate a local average
      const overallZScoreSum = sortedPerformers.reduce((sum, report) => sum + report.overallZScore, 0);
      const localAverageOverallZScore = overallZScoreSum / sortedPerformers.length;
      const fitnessOverallPercentage = Number(localAverageOverallZScore.toFixed(2));
  
      this.reportTitle = reportTitle;
      
      // Handle performer labels based on roles
      let performerLabels: string[] = [];
      if (this.Admin || this.OrgAdmin) {
        performerLabels = sortedPerformers.map(report => report.usrFullName);
      } else if (this.Coach) {
        performerLabels = sortedPerformers.map(report => report.athleteName);
      } else {
        performerLabels = sortedPerformers.map(report => report.cnaAthlete);
      }
  
      const rulerDropData: [string, ...number[]] = ['Ruler Drop', ...sortedPerformers.map(report => report.zScoreRulerDrop.toFixed(4))];
      const blazepodsData: [string, ...number[]] = ['Blazepods', ...sortedPerformers.map(report => report.zScoreBlazepods.toFixed(4))];
      const wallTossData: [string, ...number[]] = ['Wall Toss', ...sortedPerformers.map(report => report.zScoreWallToss.toFixed(4))];
      const plateTapingData: [string, ...number[]] = ['Plate Taping', ...sortedPerformers.map(report => report.zScorePlateTaping.toFixed(4))];
  
      // Load the C3.js chart library and generate the cognitive chart
      const c3 = (await import('c3')).default;
      c3.generate({
        bindto: '#cognitive-chart',
        data: {
          columns: [
            rulerDropData,
            blazepodsData,
            wallTossData,
            plateTapingData
          ],
          types: {
            'Ruler Drop': 'area-spline',
            'Blazepods': 'area-spline',
            'Wall Toss': 'area-spline',
            'Plate Taping': 'area-spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            categories: performerLabels,
            tick: {
              rotate: 90,
              multiline: false
            },
            height: 100
          },
          y: {
            label: {
              text: 'Cognitive Scores',
              position: 'outer-middle'
            },
            tick: {
              format: (d: number) => d.toFixed(2)
            }
          }
        },
        tooltip: {
          contents: (data) => {
            let html = '<table class="c3-tooltip">';
            html += '<thead><tr><th>Metric</th><th>Z-Score</th><th>Actual Value</th><th>IBM</th></tr></thead>';
            html += '<tbody>';
            data.forEach(item => {
              const key = item.id;
              const zScoreValue = item.value;
              const baseKey = key.replace(' Z-Score in %', '');
              const performer = this.athleteReport[item.index];
              const actualValue = performer[`cna${baseKey.replace(/ /g, '')}`];
              const ibmMatch = this['IBMReference'].find((ibm: { testColumnName: string; }) => ibm.testColumnName === `cna${baseKey.replace(/ /g, '')}`);
              const ibmRange = ibmMatch ? ibmMatch.ibmRange : 'N/A';
              html += `<tr><td>${baseKey}</td><td>${zScoreValue}</td><td>${actualValue}</td><td>${ibmRange}</td></tr>`;
            });
            html += '</tbody></table>';
            return html;
          }
        },
      });
    } else {
      console.warn('No athlete data available');
    }
  }
  
 
 
  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
          const athleteEntry = this.CognitivePerformers.find((entry: { cnaAthlete: any; }) => entry.cnaAthlete === userId);
          if (athleteEntry) {
            athleteEntry.image = this.image;
          } else {
            // this.CognitivePerformers.push({ cnaAthlete: userId, image: this.image });
          }
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.showImage = true;
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
            const athleteEntry = this.CognitivePerformers.find((entry: { cnaAthlete: any; }) => entry.cnaAthlete === userId);
            if (athleteEntry) {
              athleteEntry.image = this.image;
            } else {
              // this.CognitivePerformers.push({ cnaAthlete: userId, image: this.image });
            }
            this.cdr.detectChanges();
          },
          (error) => {
          }
        );
      }
    );
  }
 
  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
 
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }
 
  async selectOrganisation() {
    this.org = this.selectedOrg;
    this.orgStudents();
    this.isLoading = false;
    if (this.selectedOption === 'Cognitive') {
      await this.getCognitiveReport();
      this.loadCognitiveTopPerformersData();
      this.isLoading = false;
    }
  }
 
  async selectTrainer() {
    this.trainerId = this.selectedTrainer;
    this.isLoading = true;
 
    await this.getCognitiveReport();
    this.loadCognitiveTopPerformersData();
 
  }
 
  async onDropdownChange() {
    this.chat = '';
    this.athletedata = undefined;
    this.selectedStatus = 0;
    this.isLoading = false;
    if (this.selectedOption === 'Cognitive') {
      await this.getCognitiveReport();
      this.loadCognitiveTopPerformersData();
      this.isLoading = false;
    }
  }
  getCogAIData(){
    this.assessmentService.getAllCognitiveForAI().subscribe(
      (response) => {
        this.playerData=response
        if(this.orgId != '0'){
          this.playerData=this.playerData.filter(
            (item: any) => item.usrOrganization === this.orgId
          );
          if (this.selectedTeamId != '0'){
            this.playerData=this.playerData.filter(
              (item: any) => item.asiCurrentClubTeam == this.selectedTeamId
            );
          }else{
            this.playerData=this.playerData
          }
        }
        console.log(this.playerData,"cogdata")
      })
  }
  async getCognitiveReport(): Promise<void> {
    try {
      this.UserId = this.trainerId || this.UserId;
 
      // Fetch data based on whether the user is a coach or trainer
      this.cognitiveResponse = (this.Coach || this.IsAssessor)
        ? await this.assessmentService.getcognitiveDetail(this.org).toPromise()
        : (await this.assessmentService.getallcognitiveByassessment().toPromise())
          // Filter based on organization ID
          .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));
 
      // Additional filtering if Admin or selectedTeamId is set
      if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0')|| (this.sciStaff && this.selectedTeamId != '0')) {
        this.cognitiveResponse = this.cognitiveResponse.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      } else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
        // If not Admin or Coach, filter by assessor ID
        this.cognitiveResponse = this.cognitiveResponse.filter(
          (item: any) => item.cnaAssessor === this.UserId
        );
 
        // Optionally filter by selected team ID
        if (this.selectedTeamId != '0') {
          this.cognitiveResponse = this.cognitiveResponse.filter(
            (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
          );
        }
      } else if (this.Coach) {
        this.cognitiveResponse = this.cognitiveResponse.filter(
          (item: any) => item.asiCoach === this.UserId
        );
        console.log(this.cognitiveResponse,"coach")
      }
      this.getPlayer();
      this.getCogAIData()
      const calculateAverage = (data: any[], property: string): number => {
        const values = data.map((item: any) => item[property]);
        return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
      };
 
      this.averageCNaRulerDrop = calculateAverage(this.cognitiveResponse, 'cnaRulerDrop');
      this.averageCNaBlazePods = calculateAverage(this.cognitiveResponse, 'cnaBlazepods');
      this.averageCNaBlazepodsTime = calculateAverage(this.cognitiveResponse, 'cnaBlazepodsTime');
      this.averageCNaWallToss = calculateAverage(this.cognitiveResponse, 'cnaWallToss');
      this.averageCNaPlateTaping = calculateAverage(this.cognitiveResponse, 'cnaPlateTaping');
 
 
      this.cognitivePerformers = this.athletedata ? this.athletedata : this.cognitiveResponse;
      this.athleteReport = this.cognitivePerformers;
      const uniqueAthletesMap = new Map();
      this.athleteReport.forEach(item => {
          uniqueAthletesMap.set(item.cnaAthlete, item);
      });
      this.athleteReport = Array.from(uniqueAthletesMap.values());
      this.reportCount = this.athleteReport.length;
 
      if (this.cognitivePerformers && this.cognitivePerformers.length > 0) {
 
        let rulerDropValues: number[] = [];
        let blazepodsValues: number[] = [];
        let wallTossValues: number[] = [];
        let plateTapingValues: number[] = [];
 
        const isValidNumber = (value: any): boolean => typeof value === 'number' && !isNaN(value);
 
        this.athleteReport = this.athleteReport.filter(report =>
          isValidNumber(report.cnaRulerDrop) &&
          isValidNumber(report.cnaBlazepods) &&
          isValidNumber(report.cnaWallToss) &&
          isValidNumber(report.cnaPlateTaping)
        );
 
        this.athleteReport.forEach(report => {
          rulerDropValues.push(report.cnaRulerDrop);
          blazepodsValues.push(report.cnaBlazepods);
          wallTossValues.push(report.cnaWallToss);
          plateTapingValues.push(report.cnaPlateTaping);
        });
 
        const calculateMean = (data: number[]): number => data.reduce((sum, value) => sum + value, 0) / data.length;
        const calculateStandardDeviation = (data: number[], mean: number): number => Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
 
        const meanRulerDrop = calculateMean(rulerDropValues);
        const stdDevRulerDrop = calculateStandardDeviation(rulerDropValues, meanRulerDrop);
        const meanBlazepods = calculateMean(blazepodsValues);
        const stdDevBlazepods = calculateStandardDeviation(blazepodsValues, meanBlazepods);
        const meanWallToss = calculateMean(wallTossValues);
        const stdDevWallToss = calculateStandardDeviation(wallTossValues, meanWallToss);
        const meanPlateTaping = calculateMean(plateTapingValues);
        const stdDevPlateTaping = calculateStandardDeviation(plateTapingValues, meanPlateTaping);
 
        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
          return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(4));
        };
 
        this.athleteReport.forEach(report => {
          report.zScoreRulerDrop = this.zScoreToPercent(safeCalculateZScore(report.cnaRulerDrop, meanRulerDrop, stdDevRulerDrop));
          report.zScoreBlazepods = this.zScoreToPercent(safeCalculateZScore(report.cnaBlazepods, meanBlazepods, stdDevBlazepods));
          report.zScoreWallToss = this.zScoreToPercent(safeCalculateZScore(report.cnaWallToss, meanWallToss, stdDevWallToss));
          report.zScorePlateTaping = this.zScoreToPercent(safeCalculateZScore(report.cnaPlateTaping, meanPlateTaping, stdDevPlateTaping));
 
          report.overallZScore = Math.round(Number(((
            report.zScoreRulerDrop +
            report.zScoreBlazepods +
            report.zScoreWallToss +
            report.zScorePlateTaping
          ) / 4)));
        });
 
        const calculateAverage = (data: any[], property: string): number => {
          const values = data.map((item: any) => item[property]);
          return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
        };
   
        this.averageCNaRulerDropz = calculateAverage(this.athleteReport, 'zScoreRulerDrop');
        this.averageCNaBlazePodsz = calculateAverage(this.athleteReport, 'zScoreWallToss');

        const top5CognitivePerformers = [...this.athleteReport]
          .sort((a, b) => b.overallZScore - a.overallZScore) // Sort by overall Z-score (or use overallZScorePercentage if available)
          .slice(0, 5); // Get the top 5 performers
 
        // Store the top 5 performers
        this.cognitiveTop5Performers = top5CognitivePerformers;
 
        const overallZScoreSum = this.cognitiveTop5Performers.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.cognitiveTop5Performers.length;
 
        // Round off the average overall percentage
        this.averageOverallPercentage = Math.round(averageOverallZScore);
        let performerLabels: string[] = [];
 
        if(this.Admin || this.OrgAdmin){
          performerLabels = this.cognitiveTop5Performers.map(report => report.usrFullName);
         
         }else if( this.Coach){
          performerLabels = this.cognitiveTop5Performers.map(report => report.athleteName);
          
         }else{
         performerLabels= this.cognitiveTop5Performers.map(report => report.cnaAthlete);
          }
        //const performerLabels: string[] = this.cognitiveTop5Performers.map(report => report.usrFullName);
        const rulerDropData: [string, ...number[]] = ['Ruler Drop', ...this.cognitiveTop5Performers.map(report => report.zScoreRulerDrop.toFixed(4))];
        const blazepodsData: [string, ...number[]] = ['Blazepods', ...this.cognitiveTop5Performers.map(report => report.zScoreBlazepods.toFixed(4))];
        const wallTossData: [string, ...number[]] = ['Wall Toss', ...this.cognitiveTop5Performers.map(report => report.zScoreWallToss.toFixed(4))];
        const plateTapingData: [string, ...number[]] = ['Plate Taping', ...this.cognitiveTop5Performers.map(report => report.zScorePlateTaping.toFixed(4))];
 
        const c3 = (await import('c3')).default;
        // C3 chart generation part starts here
        const chart = c3.generate({
          bindto: '#cognitive-chart', // The element ID where the chart will be rendered
          data: {
            columns: [
              rulerDropData,
              blazepodsData,
              wallTossData,
              plateTapingData
            ],
            types: {
              'Ruler Drop': 'area-spline',
              'Blazepods': 'area-spline',
              'Wall Toss': 'area-spline',
              'Plate Taping': 'area-spline'
            }
          },
          axis: {
            x: {
              type: 'category',
              categories: performerLabels, // Use athlete names as labels
              tick: {
                rotate: 90, // Rotate the labels 90 degrees for better readability
                multiline: false
              },
              height: 100
            },
            y: {
              label: {
                text: 'Cognitive Scores ', // Y-axis label
                position: 'outer-middle'
              },
              tick: {
                format: (d: number) => d.toFixed(2) // Format Y-axis tick values to two decimal places
              }
            }
          },
          tooltip: {
            contents: (data) => {
              // Create the custom tooltip
              let html = '<table class="c3-tooltip">';
              html += '<thead><tr><th>Metric</th><th>Z-Score</th><th>Actual Value</th><th>IBM</th></tr></thead>';
              html += '<tbody>';
 
              // Loop through the data and build the rows
              data.forEach(item => {
                const key = item.id; // Get the key (e.g., "Body Weight Z-Score")
                const zScoreValue = item.value; // Get the Z-Score value
 
                // Extract the base key (e.g., "Body Weight") from the Z-Score key
                const baseKey = key.replace(' Z-Score in %', ''); // Adjust based on your label format
 
                // Find the corresponding performer for the current index
                const performer = this.athleteReport[item.index];
                const actualValue = performer[`cna${baseKey.replace(/ /g, '')}`]; // Remove spaces and prepend 'na'
 
                // Find the corresponding IBM reference
                const ibmMatch = this['IBMReference'].find((ibm: { testColumnName: string; }) => ibm.testColumnName === `cna${baseKey.replace(/ /g, '')}`);
                const ibmRange = ibmMatch ? ibmMatch.ibmRange : 'N/A';
 
                html += '<tr>';
                html += `<td>${baseKey}</td>`; // Metric Name
                html += `<td>${zScoreValue}</td>`; // Z-Score
                html += `<td>${actualValue}</td>`; // Actual Value from performer
                html += `<td>${ibmRange}</td>`; // IBM Range or 'N/A'
                html += '</tr>';
              });
 
              html += '</tbody></table>';
              return html;
            }
          },
          grid: {
            y: {
              show: true // Show Y-axis grid lines
            }
          }
        });
        // C3 chart generation part ends here
      }
      else {
        this.averageOverallPercentage = 0; // Handle case where no data is available
        const c3 = (await import('c3')).default;
        c3.generate({
          bindto: '#cognitive-chart', // Ensure the chart container has this ID
          data: {
            columns: [['No Data', 0]]
          }
        });
      }
    } catch (error) {
      console.error('Error fetching cognitive report:', error);
    }
  }
  getColor(value: number): string {
    if (value >= 85) {
      return 'darkgreen';  
    } else if (value >= 70) {
      return 'lightgreen';
    } else if (value >= 40) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  selectRole(role: string, roleId: any) {
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: { Org:this.org, status:'True' } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }

}