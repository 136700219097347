import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID , ViewChild} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../services/assessment.service';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../services/privilage.service';
import { UserService } from '../services/user.service';
import { SharedService } from '../services/shared.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ChatService } from '../services/chat.service';
import { PlayerDashboardComponent } from '../player-dashboard/player-dashboard.component';
import { AssessorDashboardComponent } from '../assessor-dashboard/assessor-dashboard.component'
import c3 from 'c3';
import {PhysicalFitnessDashboardComponent } from '../component/physical-fitness-dashboard/physical-fitness-dashboard.component';
import { NutritionalDashboardComponent } from '../component/nutritional-dashboard/nutritional-dashboard.component';
import { CognitiveDashboardComponent } from '../component/cognitive-dashboard/cognitive-dashboard.component';
import { InjuryPreventionDashboardComponent } from '../component/injury-prevention-dashboard/injury-prevention-dashboard.component';
import { LevelOneDashboardComponent } from '../component/level-one-dashboard/level-one-dashboard.component';
import { SportPerformanceComponent } from '../component/sport-performance/sport-performance.component'
import { OverallDashboardComponent } from '../component/overall-dashboard/overall-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfWorkerService } from '../services/pdf.worker.service';
 
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule, FormsModule, PhysicalFitnessDashboardComponent, NutritionalDashboardComponent,CognitiveDashboardComponent,InjuryPreventionDashboardComponent,LevelOneDashboardComponent, SportPerformanceComponent,OverallDashboardComponent,AssessorDashboardComponent,NgSelectModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  @ViewChild(OverallDashboardComponent) overallComponent!: OverallDashboardComponent;
  @ViewChild(PhysicalFitnessDashboardComponent) physicalFitnessComponent!: PhysicalFitnessDashboardComponent;
  @ViewChild(NutritionalDashboardComponent) nutritionComponent!: NutritionalDashboardComponent;
  @ViewChild(CognitiveDashboardComponent) cognitiveComponent!: CognitiveDashboardComponent;
  @ViewChild(InjuryPreventionDashboardComponent) injuryPreventionAnalysisComponent!: InjuryPreventionDashboardComponent;
  @ViewChild(SportPerformanceComponent) sportsPerformanceComponent!: SportPerformanceComponent;
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  userMessage: string = ""; // Default message
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = 'All'; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = null;
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  physioloyPerformers: any[] = [];
  admitcardReport: any[] = [];
  msg: any;
  nutResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  pfaPerformers: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: any = '0';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  selectedOptions: string = 'CSS';
  InjuryPrevention = false;
  sportsPerformance = false;
  sciStaff: boolean = false;
  assessor: boolean = false;
  selectedTeamId:any = 0;
  profileImageUrl = "assets/image/profileimg.jpg"
  teams:any;
  admitcard: any[]=[];
  admitcarddata: any[]=[];
  isButtonDisabled: boolean=false;
  dropdownItems: any[]=[];
  Allteam: any[]=[]; 
  tableColumns: string[] = [];
  tableData: any[] = [];
  columns: any[] = [];
  values: any[] = [];
  dropdownOptions = [
    { value: 'CSS', label: 'Sports Talent Identification' },
    { value: 'Level One', label: 'SAI Health & Fitness' }
  ];
assessmentOptions = [
    { value: 'All', label: 'All' },
    { value: 'Nutrition', label: 'Nutrition' },
    { value: 'PhysicalFitness', label: 'Physical Fitness' },
    { value: 'Cognitive', label: 'Cognitive' },
    { value: 'Injury Prevention Analysis', label: 'Injury Prevention' },
    { value: 'Sports Performance Analysis', label: 'Sports Performance' }
  ];
  isDownloading: boolean = false; // To track if the download is in progress
  progress: number = 0;
  text: boolean= false;
 constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private progressService: PdfWorkerService,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.assessor = this.privilege.isAssessor ?? false;
    this.selectOrganisation();
  }
 
 
  async ngOnInit(): Promise<void> {
    this.user = this.userService.getUser();
    this.assessmentService.getTeams().subscribe((data: any[]) => {
      if(this.org != '0'){
        this.teams = data.filter((item:any)=> item.teamOrganization === Number(this.org)); 
      }else{
        this.teams = data;
      }  
      this.Allteam = [{ teamId: '0', teamName: 'All Team' }, ...this.teams];  
    });
    
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
        this.dropdownItems = [{ orgId: '0', orgName: 'All' }, ...this.Orgainsation];
  
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.selectOrganisation();
    this.fetchOptions();
  }

  
  
  async selectOrganisation( ) {
    console.log("Selected Organization ID:", this.selectedOrg);
    // Reset Team and Category dropdowns when organization is changed
    this.selectedTeamId = '0'; // Reset Team dropdown
    this.selectedOption = 'All'; // Reset Category dropdown
    
    // When selectedOrg is '0' (i.e., "All"), reset Types dropdown to "Sports Talent Identification"
    if (this.selectedOrg === '0') {
      this.selectedOptions = 'CSS'; // Set Type dropdown to "Sports Talent Identification"
    }
  
    // Fetch and filter teams based on the selected organization
    this.assessmentService.getTeams().subscribe((data: any[]) => {
      if (this.selectedOrg !== '0') {
        // Filter teams by organization ID
        this.teams = data.filter((item: any) => item.teamOrganization === Number(this.selectedOrg));
        this.Allteam = [{ teamId: '0', teamName: 'All Team' }, ...this.teams];  
        // Filter admit card data by selected organization ID
        this.admitcarddata = this.admitcardReport.filter((item: any) => 
          item.usrOrganization === Number(this.selectedOrg)
        );
  
        this.orgStudents(); // Fetch students based on selected organization
      } else {
        this.teams = data;
        this.admitcarddata = [];
        this.admitcardReport = [];
      }
  
      if (this.OrgAdmin) {
        this.orgStudents();
      }
  
      // Enable or disable button based on the availability of admit card data
      this.isButtonDisabled = this.admitcarddata.length === 0;
    });
  
    // Reset other components related to the selection
    this.updateChildComponents();
    this.chat = null;
    this.athletedata = null;
  }
  clearChat(){
    this.chat=""
    location.reload();
  }
  orgStudents() {
    this.chat = null;
    this.athletedata = null;
  
    if (this.Admin || this.OrgAdmin || this.sciStaff) {
      this.assessmentService.getAdmitCard(this.selectedOrg).subscribe((response: any) => {
        this.admitcardReport = response;
        
        // Filter admit card report based on selected organization
        if (this.selectedOrg !== '0') {
          if(this.selectedTeamId !== '0'){
            this.admitcarddata = this.admitcardReport.filter((item: any) => 
              item.usrOrganization === Number(this.selectedOrg) &&item.asiCurrentClubTeam == this.selectedTeamId
            ); 
          }else{
            this.admitcarddata = this.admitcardReport.filter((item: any) => 
              item.usrOrganization === Number(this.selectedOrg) 
            );
          } } else {
          this.admitcarddata = [];
        }
  
        // Update button state based on admit card data availability
        this.isButtonDisabled = this.admitcarddata.length === 0;
      });
    }
  }

  
  selectAssessment(){
    this.chat=null;
    this.athletedata=null;
    if (this.selectedOption === 'All') {
      this.PhysicalFitness = this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
      this.overallComponent.sendMessage();
    } else if (this.selectedOption === 'Nutrition') {
      this.Nutrition = true;
      this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
      this.nutritionComponent.sendMessage();
    } else if (this.selectedOption === 'PhysicalFitness') {
      this.PhysicalFitness = true;
      this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
    } else if (this.selectedOption === 'Cognitive') {
      this.Cognitive = true;
      this.Nutrition = this.PhysicalFitness = this.InjuryPrevention = this.sportsPerformance = false;
      this.cognitiveComponent.sendMessage();
    } else if (this.selectedOption === 'Injury Prevention Analysis') {
      this.InjuryPrevention = true;
      this.Nutrition = this.PhysicalFitness = this.Cognitive = this.sportsPerformance = false;
      this.injuryPreventionAnalysisComponent.sendMessage();
    } else if (this.selectedOption === 'Sports Performance Analysis') {
      this.sportsPerformance = true;
      this.Nutrition = this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = false;
      this.sportsPerformanceComponent.sendMessage();
    }
  }
 
  handleMessageSend() {
    this.resetAnalysisFlags();
   
    switch (this.selectedOption) {
      case 'Nutrition':
        this.Nutrition = true;
        this.nutritionComponent.sendMessage();
        break;
      case 'PhysicalFitness':
        this.PhysicalFitness = true;
        this.physicalFitnessComponent.sendMessage();
        break;
      case 'Cognitive':
        this.Cognitive = true;
        this.cognitiveComponent.sendMessage();
        break;
      case 'Injury Prevention Analysis':
        this.InjuryPrevention = true;
        this.injuryPreventionAnalysisComponent.sendMessage();
        break;
      case 'Sports Performance Analysis':
        this.sportsPerformance = true;
        this.sportsPerformanceComponent.sendMessage();
        break;
      default:
        this.overallComponent.sendMessage();
    }
  }
 
  resetAnalysisFlags() {
    this.PhysicalFitness = this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
  }
 
  updateChildComponents() {
    switch (this.selectedOption) {
      case 'All':
        this.overallComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Nutrition':
        this.nutritionComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'PhysicalFitness':
        this.physicalFitnessComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Cognitive':
        this.cognitiveComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Injury Prevention Analysis':
        this.injuryPreventionAnalysisComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Sports Performance Analysis':
        this.sportsPerformanceComponent.onTeamSelected(this.selectedTeamId);
        break;
    }
  }
  handleChatFromChild(chat: any) {
    this.chat = chat;
    console.log(this.chat, "chat");

    if (Array.isArray(this.chat)) {
      console.log("The chat is an array.");
      this.text = false;
    } else {
      console.log("The chat is not an array.");
      this.text = true;
    }
    console.log(this.text,"text")
    if (this.selectedOption == 'All') {
      // Set columns for status 1
      this.columns = [
        { name: 'Athletes', value: 'athleteName' },
        { name: 'BMI (kg/m²)', value: 'bmi' },
        { name: 'GripLeft (Kgs)', value: 'left' },
        { name: 'GripRight (Kgs)', value: 'right' },
        { name: 'Lower Body Force (N)', value: 'lowerBodyForce' },
        { name: 'Lower Body Power (W)', value: 'lowerBodyPower' },
        { name: 'Upper Body Force (N)', value: 'upperBodyForce' },
        { name: 'Upper Body Power (W)', value: 'upperBodyPower' },
        { name: 'VO2 (ml/kg/min)', value: 'vO2' }
      ];
    } else if (this.selectedOption == 'Nutrition') {
      // Set columns for status 2
      this.columns = [
        { name: 'Athletes', value: 'usrFullName' },
        { name: 'Height', value: 'naBodyHeight' },
        { name: 'Weight', value: 'naBodyWeight' },
        { name: 'BMI', value: 'naBMI' },
        { name: 'Body Fat', value: 'naBodyFat' },
        { name: 'BMR', value: 'naBMR' },
        { name: 'Health Score', value: 'naHealthScore' },
        { name: 'Protein', value: 'naProtein' }
      ];
    } else if (this.selectedOption == 'PhysicalFitness') {
      // Set columns for status 2
      this.columns = [
        { name: 'Athletes', value: 'athleteName' },
        { name: 'Height', value: 'pfaHeight' },
        { name: 'Weight', value: 'pfaWeight' },
        { name: 'BMI', value: 'pfaBMI' },
        { name: 'Upper Body Power', value: 'pfaUpperBodyPower' },
        { name: 'Lower Body Power', value: 'pfaLowerBodyPower' },
        { name: 'Upper Body Force', value: 'pfaUpperBodyForce' },
        { name: 'Lower Body Force', value: 'pfaLowerBodyForce' }
      ];
    } else if (this.selectedOption == 'Cognitive') {
      // Set columns for status 2
      this.columns = [
        { name: 'Athletes', value: 'usrFullName' },
        { name: 'RulerDrop', value: 'cnaRulerDrop' },
        { name: 'Blazepods', value: 'cnaBlazepodsTime' },
        { name: 'Wall Toss', value: 'cnaWallToss' },
        { name: 'Plate Taping', value: 'cnaPlateTaping' }        
      ];
    } else if (this.selectedOption == 'Injury Prevention Analysis') {
      // Set columns for status 2
      this.columns = [
        { name: 'Athletes', value: 'usrFullName' },
        { name: 'Deep Squat', value: 'ipaDeepSquat' },
        { name: 'Inline Lunge', value: 'ipaInlineLunge' },
        { name: 'Hurdle Step', value: 'ipaHurdleStep' },
        { name: 'Active SLR', value: 'ipaActiveSLR' },
        { name: 'Shoulder Mobility', value: 'ipaShoulderMobility' },
        { name: 'PushUp', value: 'ipaPushUp' },
        { name: 'Rotary Stability', value: 'ipaRotaryStability' }
      ];
    } else if (this.selectedOption == 'Sports Performance Analysis') {
      // Set columns for status 2
      this.columns = [
        { name: 'Athletes', value: 'usrFullName' },
        { name: 'Average Torque CR', value: 'spaAverageTorqueCR' },
        { name: 'Average Torque CL', value: 'spaAverageTorqueCL' },
        { name: 'Peak Torque CR', value: 'spaPeakTorqueCR' },
        { name: 'Peak Torque CL', value: 'spaPeakTorqueCL' },
        { name: 'Average Torque IR', value: 'spaAverageTorqueIR' },
        { name: 'Average Torque IL', value: 'spaAverageTorqueIL' },
        { name: 'Peak Torque IR', value: 'spaPeakTorqueIR' },
        { name: 'Peak Torque IL', value: 'spaPeakTorqueIL' }
      ];
    }
    this.values = this.chat; 
  }
 
  getFilteredChat(): string {
    // Assuming "JSON Format" starts from a specific keyword you want to hide
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  // Return text before "JSON Format"
    }
    return this.chat;  // If "JSON Format" is not found, return the full text
  }
 
  formnavigation() {
    this.router.navigate(['/nutrition']);
  }
 
  getProfileImage(userId: any): Observable<any> {
    return this.assessmentService.getImage(userId).pipe(
      map((imageBlob: Blob | MediaSource) => {
        const objectURL = URL.createObjectURL(imageBlob);
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }),
      catchError((error) => {
        console.error('Error fetching image', error);
        return of(''); // Return an empty string or placeholder image URL on error
      })
    );
  }
 
  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
 
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return this.profileImageUrl;
      }
    });
  }
 
  showTable(data: any, ageGroups: number[]) {
    const table = document.getElementById('dataTable');
    const age = ageGroups[data.index];
    const category = data.name;
    let filteredAthletes = this.apiResponse.filter((item: any) => {
      if (item.aggAge !== age) return false;
      if (category === 'Good' && item.loaAverageRange >= 80) return true;
      if (category === 'Average' && item.loaAverageRange >= 60 && item.loaAverageRange < 80) return true;
      if (category === 'Below Average' && item.loaAverageRange  < 60) return true;
 
      return false;
    });
 
    this.fetchImageUrls();
    let tableRows = filteredAthletes.map((athlete: any) => `
      <tr>
        <td>${athlete.usrFullName}</td>  <!-- Person's name -->
        <td>${athlete.aggAge}</td>   <!-- Age -->
        <td>${athlete.loaHeight}</td>  <!-- Height -->
        <td>${athlete.loaWeight}</td>  <!-- Weight -->
        <td>${athlete.loaAverageRange}</td>  <!-- Performance range -->
      </tr>
    `).join('');
 
    // Dynamically update table content
    if (table) {
      table.innerHTML = `
        <table class="table">
          <thead>
            <tr>
           
              <th>Name</th>
              <th>Age</th>
              <th>Height</th>
              <th>Weight</th>
              <th>Average Range</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      `;
      table.style.display = 'block'; // Make the table visible
    }
  }
 
  async selectTrainer(){
   
  }
 
  async onDropdownChange() {
    this.chat=null;
    this.athletedata=null;
   if(this.selectedOptions === 'CSS'){
         this.selectedOption = 'All';
   }    
   if (this.selectedOptions === 'Level One') {
    this.Nutrition = false;
    this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
  }
  }
 
  DownloadClick() {
    if (this.Admin || this.OrgAdmin || this.sciStaff) {
      const organizationFilter = this.OrgAdmin ? this.org : this.selectedOrg;
    
      // Filter admitcard based on the user's organization
      this.admitcard = this.admitcardReport.filter((item: any) =>
          item.usrOrganization == organizationFilter
      );
      // Then, if a team is selected, filter by selected team
      if (this.selectedTeamId != 0 && this.selectedTeamId != '0') {
        this.admitcard = this.admitcard.filter((item: any) =>
          item.asiCurrentClubTeam == this.selectedTeamId
        );
      }
 
      // Map the image URLs for multiple athletes
      this.imageUrls = this.admitcard.map((athlete: any) => {
        if (athlete.usrImage) {
          return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
        } else {
          return this.profileImageUrl; // Handle cases where there is no image
        }
      });
 
    } else if (this.Isplayer) {
      this.assessmentService.getAdmitCardById(this.UserId).subscribe((response: any) => {
        this.admitcardReport = response;
        this.admitcard = this.admitcardReport;
 
        this.imageUrls = this.admitcard.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return this.profileImageUrl; // Handle cases where there is no image
          }
        });
      });
    }
  }
 
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      // Clone the modal content
      const clonedContent = DATA.cloneNode(true) as HTMLElement;
      clonedContent.style.position = 'absolute';
      clonedContent.style.top = '-9999px';
      clonedContent.style.left = '-9999px';
      document.body.appendChild(clonedContent);
  
      // Inject necessary styles to ensure rendering
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        .modal-body {
          padding: 15px;
        }
        .modal-dialog {
          max-width: fit-content;
          margin: auto;
        }
        .modal-dialog-centered {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
        }
        .logo-content {
          background-color: rgb(196, 52, 52);
          width: 17em;
          height: auto;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          background-size: cover;
          background-position: center;
        }
        .card1 {
          width: 17em;
          height: 22.5em;
          background-color: #ECF7FF;
          position: relative;
          transition: 1s ease-in-out;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          color: white;
          z-index: 1;
        }
        .logo-content::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          z-index: -1;
        }
        .logo-circle {
          width: 55px;
          height: 55px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        .logo-img {
          max-width: 100%;
          max-height: 100%;
        }
        .card1 .logo-content,
        .card1 .title span,
        .card1 .info,
        .card1 button,
        .card1 .title {
          position: relative;
          z-index: 2;
        }
        .card1 .logo-content h2 {
          font-family: var(--font-1);
          font-size: 22px;
          font-weight: 300;
          color: rgb(236, 236, 236);
          display: block;
          margin: 0;
          white-space: nowrap;
        }
        .card1 .logo-content p {
          font-size: 11px;
          color: #e4e4e4;
          margin: 0;
        }
        .card1 .title {
          font-family: var(--font-1);
          font-size: 18px;
          font-weight: 300;
          color: var(--green-color);
          text-align: center;
          display: block;
          margin: 0;
          white-space: nowrap;
        }
        .card1 .title span {
          font-family: var(--font-2);
          font-weight: bold;
          font-size: 18px;
          color: rgb(41, 41, 41);
        }
        .card1 .info {
          font-weight: 400;
          color: rgb(7, 7, 7);
          display: block;
          text-align: center;
          font-size: 0.72em;
          margin: 0.5em;
        }
        .card1 .img {
          width: 10em;
          height: 10em;
          background: white;
          border-radius: 4px;
          margin: 20px auto;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .card1 .img img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
        .card1 .share {
          margin-top: 1em;
          display: flex;
          justify-content: center;
          gap: 1em;
        }
        .card1 a {
          color: white;
          transition: .4s ease-in-out;
        }
      `;
      clonedContent.appendChild(styleElement);

      const modal = (window as any).bootstrap.Modal.getInstance(document.getElementById('exampleModal'));
      modal.hide();


      this.progressService.setIsDownloading(true); // Set downloading status to true
      const pdf = new jsPDF('p', 'mm', 'a4');
      let currentIndex = 0;
      const generatePage = () => {
        const athleteElements = Array.from(clonedContent.querySelectorAll('.modal-body')) as HTMLElement[];
        if (currentIndex < athleteElements.length) {
          const element = athleteElements[currentIndex];
          html2canvas(element, { scale: 2 })
            .then((canvas) => {
              const imgData = canvas.toDataURL('image/jpeg', 0.7);
              const imgWidth = canvas.width * 0.264583; // Convert pixels to mm
              const imgHeight = canvas.height * 0.264583;
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = pdf.internal.pageSize.getHeight();
              const x = (pdfWidth - imgWidth) / 2; // Center horizontally
              const y = (pdfHeight - imgHeight) / 2; // Center vertically
  
              if (currentIndex > 0) {
                pdf.addPage();
              }
              pdf.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);
  
              // Update progress
              this.progressService.setProgress(Math.round(((currentIndex + 1) / athleteElements.length) * 100));
  
              currentIndex++;
              setTimeout(generatePage, 0); // Allow other UI operations to run
            })
            .catch((error) => {
              console.error('Error generating PDF:', error);
            });
        } else {
          pdf.save('AdmitCard.pdf');
          document.body.removeChild(clonedContent); // Clean up cloned content
          this.progressService.setIsDownloading(false); // Set downloading status to false
          this.progressService.setProgress(0); // Reset progress
        }
      };
  
      setTimeout(() => {
        generatePage();
      }, 300); // Small delay to ensure DOM rendering
    }
  }
  fetchOptions() {
    // Simulate async data fetch
    setTimeout(() => {
      this.assessmentOptions = [
        { value: 'All', label: 'All' },
        { value: 'Nutrition', label: 'Nutrition' },
        { value: 'PhysicalFitness', label: 'Physical Fitness' },
        { value: 'Cognitive', label: 'Cognitive' },
        { value: 'Injury Prevention Analysis', label: 'Injury Prevention' },
        { value: 'Sports Performance Analysis', label: 'Sports Performance' }
      ];
    },100); // Simulated delay
  }
 
}