import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit,NgZone, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { PlayerDashboardComponent } from '../../player-dashboard/player-dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Router,RouterLink } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatService } from '../../services/chat.service';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-overall-dashboard',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule,RouterLink, FormsModule,NgSelectModule],
  templateUrl: './overall-dashboard.component.html',
  styleUrl: './overall-dashboard.component.css'
})
export class OverallDashboardComponent implements OnChanges  {

  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>();
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedPerformance:string='';
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  GraphHeader: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = ''; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutritionOverallPercentage: any = null;
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = [];
  TopPerformer: any[] = [];
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  physioloyPerformers: any[] = [];
  admitcardReport: any;
  msg: any;
  nutResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  pfaPerformers: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: string = '';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  leveloneReference: any;
  nutritionReport: any;
  leveloneReport: any;
  teams: any[] = [];
  gender: number = 1;
  sport: number = 11;
  IBMReference: any;
  averageNaBodyWeight: any;
  averageNaBodyHeight: any;
  averageNaBMI: any;
  averageNaBodyFat: any;
  averageNaFatFreeWeight: any;
  averageNaSubcutaneousFat: any;
  averageNaVisceralFat: any;
  averageNaBodyWater: any;
  averageNaSkeletalMuscle: any;
  averageNaLeanMass: any;
  averageNaBoneMass: any;
  averageNaProtein: any;
  averageNaBMR: any;
  averageNaMetabolicAge: any;
  averageNaHealthScore: any;
  ImageUrl: any;
  showImage: boolean=false;
  image: any;
  private benchmarksMap: Map<string, any> = new Map<string, any>();
  fitnessTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number }[] = [];
  enduranceTable:  { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number }[] = [];
  strengthTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number }[] = [];
  flexibilityTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number }[] = [];
  balanceTable: { testName: string; internationalBenchmarkMale?: string; internationalBenchmarkFemale?: string; averageValue: number }[] = [];
  shouldShowMaleColumn: boolean = false;
  shouldShowFemaleColumn: boolean = false;
  selectedStrenghtStatus: number = 0;
  selectedFlexibilitytStatus: number = 0;
  selectedBalancesStatus: number = 0;
  selectedEndurancetStatus: number = 0;
  selectedBalancetStatus: number = 0;
  fitnessHeader: any;
  strengthHeader: any;
  flexibilityHeader: any;
  enduranceHeader: any;
  balanceHeader: any;
  StrengthPerformers: any[] = [];
  FlexibilityPerformers: any[] = [];
  BalancePerformers: any[] = [];
  EndurancePerformers: any[] = [];
  strengthOverallPercentage: any;
  enduranceOverallPercentage: any;
  flexibilityOverallPercentage: any;
  balanceOverallPercentage: any;
  TopPerformers: any[] = [];
  athletes: any;
  overallCount: any;
  Assessor: any;
  sciStaff: any;
  performers: any;
  playerData: any;
  player: any = 0;
  aiData: any;

  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any,private ngZone: NgZone
  ){
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.Assessor = this.privilege?.isAssessor??false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }
  async onTeamSelected(selectedValue: any) {
    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;
    this.selectedStatus = 0;
    this.isLoading = true;
    await this.getNutritionReport();
    await this.getfitnessreport();
    await this.getTopPerformers();
    await this.loadoverallTopPerformersData();
    this.getOverallDetail()
    this.isLoading = false;
  }

  sendMessage() {
    this.apiResponse = this.playerData;
    this.msg = " give me result with their athleteId,usrFullName,sports and result in both text and json format";
  const assessmentDataJson = JSON.stringify(this.apiResponse);
  const message = "in this data give me " + this.userMessage + this.msg;
  const combinedMessage = `
    {
      "data": ${assessmentDataJson},
      "message": "${message}"
    }
  `;
  this.isLoading = true;
  this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
    const chatContent = chatResponse?.choices?.[0]?.message?.content;
    if (chatContent) {
      try {
        // Extract JSON array or object from the chat response
        const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
        if (jsonMatch) {
          const jsonString = jsonMatch[0].trim();
          this.athletedata = JSON.parse(jsonString);
          this.aiData = JSON.parse(jsonString);
          const textContent = chatContent.replace(jsonString, '').trim();
          this.chat = textContent;
          console.log(jsonString,"jsonString")
        } else {
          this.chat = chatContent;
          this.chatData.emit(this.chat);
          console.error('No JSON block found in the chat content.');
        }
      } catch (error) {
        this.chat = chatContent;
        this.chatData.emit(this.chat);
        console.error('Error parsing chat response JSON:', error);
      }
    } else {
      console.error('Unexpected chat response structure:', chatResponse);
    }

    this.isLoading = false;


    // Update each performer with matching assessment data and image URL
    this.athletedata.forEach((performer: any) => {
      // const matchingAssessment = this.this.pfaResponse.find((item: any) => (item.naAthlete === performer.athleteId)||(item.pfaAthlete === performer.athleteId))
      const matchingAssessment = this.nutrionReport.find((item: any) => item.naAthlete === performer.athleteId)
        if (matchingAssessment) {
          Object.assign(performer, matchingAssessment);
          // this.getProfileImage(performer.naAthlete)
          this.filterPerformers('chat', 'overall');
          this.getfitnessreport();
          this.getNutritionReport();
          this.loadNutritionTopPerformersData();
        } else {
          console.error(`No matching assessment found`);
        }
    });
    this.aiData.forEach((result: any) => {
      const matchingData = this.playerData.find((item: any) => item.athleteId === result.athleteId)
         if (matchingData) {
           Object.assign(result, matchingData);
         } else {
           console.error(`No matching data found`);
         }
     });
     console.log(this.aiData,"chat data")
     console.log(this.athletedata,"athletedata")
     this.chatData.emit(this.aiData ?? this.chat);
    this.filterPerformers('chat', 'overall');
  }, error => {
    console.error('Error from Chat Service:', error);
    this.isLoading = false;
  });

}
  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    await this.getNutritionReport();
    await this.getfitnessreport();
    await this.getTopPerformers();
    await this.loadoverallTopPerformersData();
    this.getPlayer();
    this.getOverallDetail()
    this.isLoading = false;
  }
getOverallDetail(){
  this.assessmentService.getOverallPlayerData(this.player).subscribe(
    (response) => {
      this.playerData=response
      if(this.orgId != '0'){
        this.playerData=this.playerData.filter(
          (item: any) => item.orgId === this.orgId
        );
        if (this.selectedTeamId != '0'){
          this.playerData=this.playerData.filter(
            (item: any) => item.teamId === this.selectedTeamId
          );
        }else{
          this.playerData=this.playerData
        }
      }
      console.log(this.playerData,"playerdata")
    })
}
  getPlayer() {
    // Set orgId based on OrgAdmin status
    this.orgId = this.OrgAdmin ? this.org : this.orgId;
  
    // Proceed if orgId is defined
    if (this.orgId) {
      this.assessmentService.getAthleteByOrg(this.orgId).subscribe(
        (athletesData) => {
          this.athletes = athletesData;
          if (this.Coach) {
            this.overallCount = this.athletes.filter(
              (item: any) => item.asiCoach === this.UserId
            ).length;
          } else {
            if (this.selectedTeamId != 0) {
              this.overallCount = this.athletes.filter(
                (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
              ).length;
            } else {
              this.overallCount = this.athletes.length;
            }
          }
        },
        (error) => {
          this.overallCount=0;
          this.averageOverallPercentage=0
          console.error('Error fetching athletes:', error);
        }
      );
    } else {
      // Log an error if orgId is missing
      console.error('Organization ID is missing.');
      this.overallCount = this.athletes ? this.athletes.length : 0;
    }
  }
  
  
  async getNutritionReport(): Promise<void> {
    try {
     // Assign UserId as trainerId if available, otherwise keep the original UserId
  this.UserId = this.trainerId || this.UserId;

// Fetch data based on whether the user is a coach or trainer
this.nutResponse = (this.Coach  || this.Assessor)
  ? (await this.assessmentService.getNutritional(this.org).toPromise())
  .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId))
  : (await this.assessmentService.getAllNutritionalByAssessment().toPromise())
      // Filter based on organization ID
      .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));

// Additional filtering if Admin or selectedTeamId is set
if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0')|| (this.sciStaff && this.selectedTeamId != '0')) {
  this.nutResponse = this.nutResponse.filter(
    (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
  );
} else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
  // If not Admin or Coach, filter by assessor ID
  this.nutResponse = this.nutResponse.filter(
    (item: any) => item.naAssessor === this.UserId
  );
  
  // Optionally filter by selected team ID
  if (this.selectedTeamId != '0') {
    this.nutResponse = this.nutResponse.filter(
      (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
    );
  }
}else if (this.Coach){
  this.nutResponse = this.nutResponse.filter(
    (item: any) =>item.asiCoach === this.UserId
  );
}
  this.getPlayer();
      // if (this.selectedTeamId != '0') {
      //   this.nutResponse = this.nutResponse.filter(
      //     (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
      //   );
      // }
      const calculateAverage = (data: any[], property: string): number => {
        const values = data.map((item: any) => item[property]);
        return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
    };
    
    // Calculate averages for all desired properties
    this.averageNaBodyWeight = calculateAverage(this.nutResponse, 'naBodyWeight');
    this.averageNaBodyHeight = calculateAverage(this.nutResponse, 'naBodyHeight');
    this.averageNaBMI = calculateAverage(this.nutResponse, 'naBMI');
    this.averageNaBodyFat = calculateAverage(this.nutResponse, 'naBodyFat');
    this.averageNaFatFreeWeight = calculateAverage(this.nutResponse, 'naFatFreeWeight');
    this.averageNaSubcutaneousFat = calculateAverage(this.nutResponse, 'naSubcutaneousFat');
    this.averageNaVisceralFat = calculateAverage(this.nutResponse, 'naVisceralFat');
    this.averageNaBodyWater = calculateAverage(this.nutResponse, 'naBodyWater');
    this.averageNaSkeletalMuscle = calculateAverage(this.nutResponse, 'naSkeletalMuscle');
    this.averageNaLeanMass = calculateAverage(this.nutResponse, 'naLeanMass');
    this.averageNaBoneMass = calculateAverage(this.nutResponse, 'naBoneMass');
    this.averageNaProtein = calculateAverage(this.nutResponse, 'naProtein');
    this.averageNaBMR = calculateAverage(this.nutResponse, 'naBMR');
    this.averageNaMetabolicAge = calculateAverage(this.nutResponse, 'naMetabolicAge');
    this.averageNaHealthScore = calculateAverage(this.nutResponse, 'naHealthScore');
    
      // Set the performers based on whether athletedata is available
      this.nutPerformers = this.athletedata || this.nutResponse;
      this.nutrionReport = this.nutPerformers;
      this.reportNutritionCount = this.nutrionReport.length;
 
      if (this.nutrionReport.length > 0) {
        // List of keys that require z-score calculation
        const nutritionKeys = [
          'naBodyHeight', 'naBodyWeight', 'naBMI', 'naBodyFat', 'naSkeletalMuscle',
          'naBodyWater', 'naLeanMass', 'naBoneMass', 'naProtein', 'naBMR',
          'naHealthScore', 'naFatFreeWeight', 'naSubcutaneousFat', 'naVisceralFat', 'naMetabolicAge'
        ];
 
        // Initialize arrays for nutrition values
        let nutritionValues: { [key: string]: number[] } = {};
        nutritionKeys.forEach(key => nutritionValues[key] = []);
 
        // Function to calculate mean and standard deviation
        const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
          const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
          const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
          return { mean, stdDev };
        };
 
        // Collect valid data for each key
        // this.nutrionReport = this.nutrionReport.filter(report =>
        //   nutritionKeys.every(key => typeof report[key] === 'number' && !isNaN(report[key]))
        // );
 
        // Populate nutrition value arrays
        this.nutrionReport.forEach(report => {
          nutritionKeys.forEach(key => {
            nutritionValues[key].push(report[key]);
          });
        });
 
        // Calculate mean and standard deviation for each key
        let stats: { [key: string]: { mean: number, stdDev: number } } = {};
        nutritionKeys.forEach(key => {
          stats[key] = calculateStats(nutritionValues[key]);
        });
 
        // Function to safely calculate z-score
        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
          return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
        };
 
        // Fetch level one references for benchmark comparison
        for (let report of this.nutrionReport) {
          const age = this.calculateAge(report.usrDob);
          await this.leveloneRefrences(age, report.genderId, report.sportId);
 
          // Calculate height and weight percentages based on benchmarks or Z-scores
          const benchmarkHeight = this.leveloneReference?.find((ref:any) => ref.lorHeight)?.lorHeight || null;
          const benchmarkWeight = this.leveloneReference?.find((ref:any) => ref.lorWeight)?.lorWeight || null;
 
          // Height percentage calculation
          if (benchmarkHeight && report.naBodyHeight) {
            report.heightPercentage = Math.min((report.naBodyHeight / benchmarkHeight) * 100, 100);
          } else {
            const heightZScore = safeCalculateZScore(report.naBodyHeight, stats['naBodyHeight'].mean, stats['naBodyHeight'].stdDev);
            report.heightPercentage = Math.min(Math.max(((heightZScore + 3) / 6) * 100, 0), 100);
          }
 
          // Weight percentage calculation
          if (benchmarkWeight && report.naBodyWeight) {
            report.weightPercentage = Math.min((report.naBodyWeight / benchmarkWeight) * 100, 100);
          } else {
            const weightZScore = safeCalculateZScore(report.naBodyWeight, stats['naBodyWeight'].mean, stats['naBodyWeight'].stdDev);
            report.weightPercentage = Math.min(Math.max(((weightZScore + 3) / 6) * 100, 0), 100);
          }
 
          // Calculate z-scores and overall z-score for each report
          let overallZScore = 0;
          nutritionKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], stats[key].mean, stats[key].stdDev));
            overallZScore += report[zScoreKey];
          });
          report.overallZScore = overallZScore / nutritionKeys.length;
        }
 
        // Calculate average overall Z-Score
        const overallZScoreSum = this.nutrionReport.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.nutrionReport.length;
        this.averageOverallPercentage = Number(averageOverallZScore.toFixed(2));
        this.nutritionOverallPercentage = this.averageOverallPercentage;
 
      } else {
        console.error('No valid data available for Z-score calculation.');
      }
    } catch (error) {
      console.error('Error fetching nutrition report:', error);
    }
  }
  loadNutritionTopPerformersData() {
    if (this.selectedStatus == 0) {
      // Show all data
      this.nutritiontopPerformer = [...this.nutrionReport];
      this.TopPerformer = this.nutrionReport
      .sort((a: any, b: any) => b.overallZScore - a.overallZScore)
      .slice(0, 5);
      this.header = "All Performers";
    } else if (this.selectedStatus == 1) {
      // Show performers with overallZScore >= 85 (Excellent Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 85) // Filter for scores >= 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 5); // Sort descending
      this.header = "Excellent Performers";
    } else if (this.selectedStatus == 2) {
      // Show performers with overallZScore >= 70 and < 85 (Good Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 70 && performer.overallZScore < 85) // Filter for scores 70 <= overallZScore < 85
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 5); // Sort descending
      this.header = "Good Performers";
    } else if (this.selectedStatus == 3) {
      // Show performers with overallZScore >= 40 and < 70 (Average Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore >= 40 && performer.overallZScore < 70) // Filter for scores 40 <= overallZScore < 70
        .sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 5); // Sort descending
      this.header = "Average Performers";
    } else {
      // Show performers with overallZScore < 40 (Poor Performers)
      this.nutritiontopPerformer = this.nutrionReport
        .filter((performer: any) => performer.overallZScore < 40) // Filter for scores < 40
        .sort((a: any, b: any) => a.overallZScore - b.overallZScore).slice(0, 5); // Sort ascending
      this.header = "Poor Performers";
    }
    this.nutritiontopPerformer.forEach((performer: any) => {
      this.getProfileImage(performer.naAthlete)
       
    });
  }
  leveloneRefrences(age: number, athleteGender: any, sport: any) {
    this.assessmentService.levelonereference(age, athleteGender, sport).subscribe(response => {
      this.leveloneReference = response;
 
    });
  }
 
  calculateAge(dob: string): number {
    const birthDate = new Date(dob);
    const today = new Date();
 
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
 
    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
 
    return age;
  }
 
  getLorMarkRangeForEachParameter() {
    let result: any = {}; // Object to store lorMarkRange for each parameter comparison
 
    for (let reference of this.leveloneReference) {
      // Height comparison
      if (this.nutritionReport.naBodyHeight < reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naBodyHeight >= reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }
 
    for (let reference of this.leveloneReference) {
      // Weight comparison
      if (this.nutritionReport.naWeight < reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naWeight >= reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }
    this.leveloneReport = result;
    return result;
  }

  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;
 
    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
 
      const a1 =  0.254829592;
      const a2 = -0.284496736;
      const a3 =  1.421413741;
      const a4 = -1.453152027;
      const a5 =  1.061405429;
      const p  =  0.3275911;
 
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
 
      return sign * y;
    };
 
    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }

  filterPerformers(filterType: any, chart: string): void {
    this.topPerformers=this.athletedata || this.topPerformers
    let filteredReport: any[] = this.topPerformers
      .sort((a: any, b: any) => b.totalScore - a.totalScore)
      .slice(0, 10);
  
    // Filter based on filterType and chart type
    if (chart === 'overall') {
      if (filterType === 'All') {
        filteredReport = this.topPerformers
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 10); 
        this.GraphHeader = "Overall ";
      } else if (filterType === 'excellent') {
        filteredReport = this.topPerformers
          .filter((performer: any) => performer.totalScore >= 85)
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 5);
        this.GraphHeader = "Excellent";
      } else if (filterType === 'good') {
        filteredReport = this.topPerformers
          .filter((performer: any) => performer.totalScore >= 70 && performer.totalScore < 85)
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 5);
        this.GraphHeader = "Good";
      } else if (filterType === 'average') {
        filteredReport = this.topPerformers
          .filter((performer: any) => performer.totalScore >= 40 && performer.totalScore < 70)
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 5);
        this.GraphHeader = "Average";
      } else if (filterType === 'poor') {
        filteredReport = this.topPerformers
          .filter((performer: any) => performer.totalScore < 40)
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 5);
        this.GraphHeader = "Poor";
      } else if (filterType === 'chat') {
        filteredReport = this.topPerformers;
        this.GraphHeader = "";
      } else {
        filteredReport = this.topPerformers
          .sort((a: any, b: any) => b.totalScore - a.totalScore)
          .slice(0, 5);
        this.GraphHeader = "Overall";
      }
    }
  
    // Update keys for overall Z-scores
    const scoreKeys = [
      'overallBalanceZScore',
      'overallEnduranceZScore',
      'overallFlexibilityScore',
      'overallStrengthZScore',
      'overallZScore'
    ];
  
    const generateChart = async (keys: string[], bindto: string, performerLabels: string[]) => {
      const chartData: [string, ...number[]][] = keys.map(key => {
        return [
          key.replace(/([A-Z])/g, ' $1').replace('Z Score', 'Z-Score') + ' in %',
          ...filteredReport.map(report => report[key] as number)
        ];
      });
  
      const c3 = (await import('c3')).default;
      if (chartData.length > 0) {
        c3.generate({
          bindto,
          data: {
            columns: chartData,
            types: chartData.reduce((acc, [label]) => ({ ...acc, [label]: 'area-spline' }), {})
          },
          axis: {
            x: { 
              type: 'category', 
              categories: performerLabels, 
              tick: { rotate: 90, multiline: false }, 
              height: 100 
            },
            y: { label: 'Z-Score' }
          },
          tooltip: {
            contents: (data) => {
              let html = '<table class="c3-tooltip">';
              html += '<thead><tr><th>Metric</th><th>Z-Score</th></tr></thead>';
              html += '<tbody>';
  
              data.forEach(item => {
                const key = item.id.replace(' Z-Score in %', '');
                const zScoreValue = item.value;
  
                html += '<tr>';
                html += `<td>${key}</td>`;
                html += `<td>${zScoreValue}</td>`;
                html += '</tr>';
              });
  
              html += '</tbody></table>';
              return html;
            }
          }
        });
      } else {
        console.warn(`No valid data for chart on ${bindto}`);
      }
    };
  
    // Handle different roles for generating performer labels
    let performerLabels = filteredReport.map(report => report.usrFullName);
    if (this.Admin || this.OrgAdmin || this.sciStaff) {
      performerLabels = filteredReport.map(report => report.usrFullName);
    } else if (this.Coach) {
      performerLabels = filteredReport.map(report => report.athleteFullName);
    } else {
      performerLabels = filteredReport.map(report => report.naAthlete);
    }
  
    // Generate chart with the new Z-Score keys
    if (chart === 'overall') {
      generateChart(scoreKeys, '#overall-chart', performerLabels);
    }
  }
  

  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
          const athleteEntry = this.topPerformers.find((entry: { pfaAthlete: any; }) => entry.pfaAthlete === userId);
          if (athleteEntry) {
            athleteEntry.image = this.image;
          } else {
            // this.topPerformers.push({ pfaAthlete: userId, image: this.image });
          }
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.showImage = true;
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
            const athleteEntry = this.topPerformers.find((entry: { pfaAthlete: any; }) => entry.pfaAthlete === userId);
            if (athleteEntry) {
              athleteEntry.image = this.image;
            } else {
              // this.topPerformers.push({ pfaAthlete: userId, image: this.image });
            }
            this.cdr.detectChanges();
          },
          (error) => {
          }
        );
      }
    );
  }
  
  
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }

  onStatusChange() {
    this.loadoverallTopPerformersData();
  }

  loadoverallTopPerformersData() {
    if (this.selectedStatus == 0) {
      // Show all data
      this.topPerformers = [...this.nutrionReport];
      this.TopPerformers = this.nutrionReport
      .sort((a: any, b: any) => b.totalScore - a.totalScore)
      .slice(0, 5);
      this.header = "All Performers";
      this.performers = "All";
    } else if (this.selectedStatus == 1) {
      // Show performers with totalScore >= 85 (Excellent Performers)
      this.topPerformers = this.nutrionReport
        .filter((performer: any) => performer.totalScore >= 85) // Filter for scores >= 85
        .sort((a: any, b: any) => b.totalScore - a.totalScore).slice(0, 5); // Sort descending
      this.header = "Excellent Performers";
      this.performers = "Excellent";
    } else if (this.selectedStatus == 2) {
      // Show performers with totalScore >= 70 and < 85 (Good Performers)
      this.topPerformers = this.nutrionReport
        .filter((performer: any) => performer.totalScore >= 70 && performer.totalScore < 85) // Filter for scores 70 <= totalScore < 85
        .sort((a: any, b: any) => b.totalScore - a.totalScore).slice(0, 5); // Sort descending
      this.header = "Good Performers";
      this.performers = "Good";
    } else if (this.selectedStatus == 3) {
      // Show performers with totalScore >= 40 and < 70 (Average Performers)
      this.topPerformers = this.nutrionReport
        .filter((performer: any) => performer.totalScore >= 40 && performer.totalScore < 70) // Filter for scores 40 <= totalScore < 70
        .sort((a: any, b: any) => b.totalScore - a.totalScore).slice(0, 5); // Sort descending
      this.header = "Average Performers";
      this.performers = "Average";
    } else {
      // Show performers with totalScore < 40 (Poor Performers)
      this.topPerformers = this.nutrionReport
        .filter((performer: any) => performer.totalScore < 40) // Filter for scores < 40
        .sort((a: any, b: any) => a.totalScore - b.totalScore).slice(0, 5); // Sort ascending
      this.header = "Poor Performers";
      this.performers = "Poor";
    }
    this.topPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
       
    });
  }


  loadTopPerformersData() {
    if (this.athletedata === undefined) {
      this.Performers = this.Response;
      if (this.selectedStatus == 0) {
        this.Performers = this.Performers.sort((a: any, b: any) => b.loaAverageRange - a.loaAverageRange)
          .slice(0, 5);
        this.header = "Top";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      } else {
        this.Performers = this.Performers
          .sort((a: any, b: any) => a.loaAverageRange - b.loaAverageRange)
          .slice(0, 5);
        this.header = "Under";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      }
    } else {
      this.Performers = this.athletedata;
      this.Performers.forEach((performer: any) => {
        this.getProfileImage(performer.loaAthlete)
      });
    }
  }

  loadFitnessTopPerformersData() {
     this.header = "";
     if (this.selectedStatus == 0) {
      this.FitnessPerformers = [...this.athleteReport]
      .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
      // Show all performers sorted by overallZScore
      this.TopPerformer = this.FitnessPerformers.slice(0, 5);
    } else {
      // Filter and set the header based on selectedStatus
      if (this.selectedStatus == 1) {
        this.FitnessPerformers = this.FitnessPerformers.filter((performer: any) => performer.overallZScore > 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
        this.header = "Excellent";
      } else if (this.selectedStatus == 2) {
        this.FitnessPerformers = this.FitnessPerformers.filter((performer: any) => performer.overallZScore > 70 && performer.overallZScore <= 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
        this.header = "Good";
      } else if (this.selectedStatus == 3) {
        this.FitnessPerformers = this.FitnessPerformers.filter((performer: any) => performer.overallZScore > 40 && performer.overallZScore <= 70).sort((a: any, b: any) => b.overallZScore - a.overallZScore);
        this.header = "Average";
      } else {
        this.FitnessPerformers = this.FitnessPerformers.filter((performer: any) => performer.overallZScore <= 40).sort((a: any, b: any) => a.overallZScore - b.overallZScore);
        this.header = "Poor";
      }
    }

    // Fetch the profile images for the performers
    this.FitnessPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)

      
    });
  }

  loadStrengthTopPerformersData() {
    this.header = "";
    if (this.selectedStrenghtStatus == 0) {
      this.StrengthPerformers = [...this.athleteReport]
      .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
      // Show top performers sorted by overallStrengthZScore
      this.TopPerformer = this.StrengthPerformers.slice(0, 5);
    } else {
      // Filter and set the header based on selectedStrenghtStatus
      if (this.selectedStrenghtStatus == 1) {
        this.StrengthPerformers = this.StrengthPerformers.filter((performer: any) => performer.overallStrengthZScore > 85)
          .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
        this.header = "Excellent";
      } else if (this.selectedStrenghtStatus == 2) {
        this.StrengthPerformers = this.StrengthPerformers.filter((performer: any) => performer.overallStrengthZScore > 70 && performer.overallStrengthZScore <= 85)
          .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
        this.header = "Good";
      } else if (this.selectedStrenghtStatus == 3) {
        this.StrengthPerformers = this.StrengthPerformers.filter((performer: any) => performer.overallStrengthZScore > 40 && performer.overallStrengthZScore <= 70)
          .sort((a: any, b: any) => b.overallStrengthZScore - a.overallStrengthZScore);
        this.header = "Average";
      } else {
        this.StrengthPerformers = this.StrengthPerformers.filter((performer: any) => performer.overallStrengthZScore <= 40)
          .sort((a: any, b: any) => a.overallStrengthZScore - b.overallStrengthZScore);
        this.header = "Poor";
      }
    }

    // Fetch profile images for the performers
    this.StrengthPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }


  loadFlexibilityTopPerformersData() {
    this.header = "";
    if (this.selectedFlexibilitytStatus == 0) {
      this.FlexibilityPerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
      this.TopPerformer = this.FlexibilityPerformers.slice(0, 5);
    } else {
      if (this.selectedFlexibilitytStatus == 1) {
        this.FlexibilityPerformers = this.FlexibilityPerformers.filter((performer: any) => performer.overallFlexibilityScore > 85)
          .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
        this.header = "Excellent";
      } else if (this.selectedFlexibilitytStatus == 2) {
        this.FlexibilityPerformers = this.FlexibilityPerformers.filter((performer: any) => performer.overallFlexibilityScore > 70 && performer.overallFlexibilityScore <= 85)
          .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
        this.header = "Good";
      } else if (this.selectedFlexibilitytStatus == 3) {
        this.FlexibilityPerformers = this.FlexibilityPerformers.filter((performer: any) => performer.overallFlexibilityScore > 40 && performer.overallFlexibilityScore <= 70)
          .sort((a: any, b: any) => b.overallFlexibilityScore - a.overallFlexibilityScore);
        this.header = "Average";
      } else {
        this.FlexibilityPerformers = this.FlexibilityPerformers.filter((performer: any) => performer.overallFlexibilityScore <= 40)
          .sort((a: any, b: any) => a.overallFlexibilityScore - b.overallFlexibilityScore);
        this.header = "Poor";
      }
    }

    // Fetch profile images for the performers
    this.FlexibilityPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }


  loadBalancesTopPerformersData() {
    this.header = "";
    if (this.selectedBalancesStatus == 0) {
      this.BalancePerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
      this.TopPerformer = this.BalancePerformers.slice(0, 5);
    } else {
      if (this.selectedBalancesStatus == 1) {
        this.BalancePerformers = this.BalancePerformers.filter((performer: any) => performer.overallBalanceZScore > 85)
          .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
        this.header = "Excellent";
      } else if (this.selectedBalancesStatus == 2) {
        this.BalancePerformers = this.BalancePerformers.filter((performer: any) => performer.overallBalanceZScore > 70 && performer.overallBalanceZScore <= 85)
          .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
        this.header = "Good";
      } else if (this.selectedBalancesStatus == 3) {
        this.BalancePerformers = this.BalancePerformers.filter((performer: any) => performer.overallBalanceZScore > 40 && performer.overallBalanceZScore <= 70)
          .sort((a: any, b: any) => b.overallBalanceZScore - a.overallBalanceZScore);
        this.header = "Average";
      } else {
        this.BalancePerformers = this.BalancePerformers.filter((performer: any) => performer.overallBalanceZScore <= 40)
          .sort((a: any, b: any) => a.overallBalanceZScore - b.overallBalanceZScore);
        this.header = "Poor";
      }
    }

    // Fetch profile images for the performers
    this.BalancePerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
     
    });
  }

  loadEnduranceTopPerformersData() {
    this.header = "";
    if (this.selectedEndurancetStatus == 0) {
      this.EndurancePerformers = [...this.athleteReport]
        .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
      this.TopPerformer = this.EndurancePerformers.slice(0, 5);
    } else {
      if (this.selectedEndurancetStatus == 1) {
        this.EndurancePerformers = this.EndurancePerformers.filter((performer: any) => performer.overallEnduranceZScore > 85)
          .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
        this.header = "Excellent";
      } else if (this.selectedEndurancetStatus == 2) {
        this.EndurancePerformers = this.EndurancePerformers.filter((performer: any) => performer.overallEnduranceZScore > 70 && performer.overallEnduranceZScore <= 85)
          .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
        this.header = "Good";
      } else if (this.selectedEndurancetStatus == 3) {
        this.EndurancePerformers = this.EndurancePerformers.filter((performer: any) => performer.overallEnduranceZScore > 40 && performer.overallEnduranceZScore <= 70)
          .sort((a: any, b: any) => b.overallEnduranceZScore - a.overallEnduranceZScore);
        this.header = "Average";
      } else {
        this.EndurancePerformers = this.EndurancePerformers.filter((performer: any) => performer.overallEnduranceZScore <= 40)
          .sort((a: any, b: any) => a.overallEnduranceZScore - b.overallEnduranceZScore);
        this.header = "Poor";
      }
    }

    // Fetch profile images for the performers
    this.EndurancePerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete)
    });
  }

  async getfitnessreport(): Promise<void> {
    try {
      // Fetch data based on coach/trainer
      this.UserId = this.trainerId || this.UserId;
 
      // Fetch data based on whether the user is a coach or trainer
      this.pfaResponse = (this.Coach || this.PhysicalFitness)
        ? (await this.assessmentService.getPhysicalFitnessDetail(this.org).toPromise())
        .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId))
        : (await this.assessmentService.getAllPhysicalFitnessByAssessment().toPromise())
            // Filter based on organization ID
            .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));
       
      // Additional filtering if Admin or selectedTeamId is set
      if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0') || (this.sciStaff && this.selectedTeamId != '0')) {
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      } else if (!this.Admin && !this.Coach && !this.OrgAdmin && !this.sciStaff) {
        // If not Admin or Coach, filter by assessor ID
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) => item.pfaAssessor === this.UserId
        );
       
        // Optionally filter by selected team ID
        if (this.selectedTeamId != '0') {
          this.pfaResponse = this.pfaResponse.filter(
            (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
          );
        }
      }else if (this.Coach){
        this.pfaResponse = this.pfaResponse.filter(
          (item: any) =>item.asiCoach === this.UserId
        );
      }

      this.pfaResponse.sort((a: any, b: any) => new Date(b.pfaDate).getTime() - new Date(a.pfaDate).getTime());

      this.pfaPerformers = this.athletedata ?? this.pfaResponse;
      this.athleteReport = this.pfaPerformers;
      this.reportCount = this.athleteReport.length;

      if (this.athleteReport.length > 0) {
        this.fitnessTable= [];
        this.enduranceTable= [];
        this.strengthTable = [];
        this.flexibilityTable = [];
        this.balanceTable = [];
        const uniqueBenchmarkRequests = new Map<string, { genderId: string; sportId: string }>();

        this.athleteReport.forEach((athlete: any) => {
          const key = `${athlete.genderId}-${athlete.sportId}`;
          if (!uniqueBenchmarkRequests.has(key)) {
            uniqueBenchmarkRequests.set(key, {
              genderId: athlete.genderId,
              //sportId: athlete.sportId,
              sportId: '11',
            });
          }
        });

        const benchmarkPromises = [...uniqueBenchmarkRequests.values()].map(async ({ genderId, sportId }) => {
          return await this.assessmentService.getInterNationalBenchMark(genderId, sportId).toPromise();
        });

        const benchmarksArray = await Promise.all(benchmarkPromises);
        benchmarksArray.forEach((benchmarkResponse, index) => {
          const { genderId, sportId } = [...uniqueBenchmarkRequests.values()][index];
          benchmarkResponse.forEach((benchmark: any) => {
            const key = `${genderId}-${sportId}-${benchmark.testColumnName}`;
            this.benchmarksMap.set(key, benchmark.ibmRange);
          });
        });
        const fitnessKeys = ['pfaHeight', 'pfaWeight', 'pfaBMI', 'pfaSingleLegBalanceLeftEyesOpen', 'pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaPartialCurlUp', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaSitNReach'];
        const enduranceKeys = ['pfaSpeed', 'pfaYoYoTest', 'pfaLevel', 'pfaVO2'];
        const strengthKeys = ['pfaPushups', 'pfaPullups', 'pfaSquat', 'pfaSingleLegSquat', 'pfaGripStrengthRight', 'pfaGripStrengthLeft', 'pfaPartialCurlUp'];
        const flexibilityKeys = ['pfaSitNReach'];
        const balanceKeys = ['pfaSingleLegBalanceLeftEyesOpen', 'pfaSingleLegBalanceLeftEyesClose', 'pfaSingleLegBalanceRightEyesOpen', 'pfaSingleLegBalanceRightEyesClose'];

        // Function to calculate averages and populate each table
        const populateTable = (keys: string[], table: any[]) => {
          const averageValues: { [key: string]: { total: number; count: number } } = {};
        
          // Calculate average values without considering genderId
          this.athleteReport.forEach(report => {
            keys.forEach(key => {
              if (report[key] !== undefined) {
                if (!averageValues[key]) {
                  averageValues[key] = { total: 0, count: 0 };
                }
                averageValues[key].total += report[key];
                averageValues[key].count += 1;
              }
            });
          });
        
          // Loop through the average values and populate the table
          for (const key in averageValues) {
            const averageValue = (averageValues[key].total / averageValues[key].count).toFixed(2);
        
            // Fetch benchmarks for both male and female
            const internationalBenchmarkMale = this.benchmarksMap.get(`1-11-${key}`) || 'N/A';
            const internationalBenchmarkFemale = this.benchmarksMap.get(`2-11-${key}`) || 'N/A';
        
            table.push({
              testName: key.replace(/^pfa/, ''), // Remove 'pfa' prefix from testName
              internationalBenchmarkMale,
              internationalBenchmarkFemale,
              averageValue,
            });
          }
        };

        // Populate each category's table
        populateTable(fitnessKeys, this.fitnessTable);
        populateTable(enduranceKeys, this.enduranceTable);
        populateTable(strengthKeys, this.strengthTable);
        populateTable(flexibilityKeys, this.flexibilityTable);
        populateTable(balanceKeys, this.balanceTable);

        const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
          const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
          const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
          return { mean, stdDev };
        };

        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));

        const processCategory = (keys: string[]) => {
          let categoryValues: { [key: string]: number[] } = {};
          keys.forEach(key => categoryValues[key] = []);
          this.athleteReport.forEach(report => keys.forEach(key => categoryValues[key].push(report[key])));
          let stats: { [key: string]: { mean: number, stdDev: number } } = {};
          keys.forEach(key => stats[key] = calculateStats(categoryValues[key]));
          return stats;
        };

        const allKeys = [...fitnessKeys, ...strengthKeys, ...flexibilityKeys, ...balanceKeys, ...enduranceKeys];
        // this.athleteReport = this.athleteReport.filter(report =>
        //   allKeys.every(key => typeof report[key] === 'number' && !isNaN(report[key]))
        // );

        const fitnessStats = processCategory(fitnessKeys);
        const strengthStats = processCategory(strengthKeys);
        const flexibilityStats = processCategory(flexibilityKeys);
        const balanceStats = processCategory(balanceKeys);
        const enduranceStats = processCategory(enduranceKeys);

        this.athleteReport.forEach(report => {
          let overallZScore = 0, overallStrengthZScore = 0, overallFlexibilityScore = 0, overallBalanceZScore = 0, overallEnduranceZScore = 0;

          fitnessKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], fitnessStats[key].mean, fitnessStats[key].stdDev));
            overallZScore += report[zScoreKey];
          });

          strengthKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], strengthStats[key].mean, strengthStats[key].stdDev));
            overallStrengthZScore += report[zScoreKey];
          });

          flexibilityKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], flexibilityStats[key].mean, flexibilityStats[key].stdDev));
            overallFlexibilityScore += report[zScoreKey];
          });

          balanceKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], balanceStats[key].mean, balanceStats[key].stdDev));
            overallBalanceZScore += report[zScoreKey];
          });

          enduranceKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], enduranceStats[key].mean, enduranceStats[key].stdDev));
            overallEnduranceZScore += report[zScoreKey];
          });

          report.overallZScore = overallZScore / fitnessKeys.length;
          report.overallStrengthZScore = overallStrengthZScore / strengthKeys.length;
          report.overallFlexibilityScore = overallFlexibilityScore / flexibilityKeys.length;
          report.overallBalanceZScore = overallBalanceZScore / balanceKeys.length;
          report.overallEnduranceZScore = overallEnduranceZScore / enduranceKeys.length;
        });

        // Final calculations for averages and percentages
        const overallZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.athleteReport.length;
        this.averageOverallPercentage = averageOverallZScore?Number(averageOverallZScore.toFixed(2)):0;
        this.fitnessOverallPercentage = this.averageOverallPercentage;

        const overallStrengthZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallStrengthZScore, 0);
        const averageOverallStrengthZScore = overallStrengthZScoreSum / this.athleteReport.length;
        this.strengthOverallPercentage = Math.min(Math.max(((averageOverallStrengthZScore + 3) / 6) * 100, 0), 100);

        const overallFlexibilityZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallFlexibilityScore, 0);
        const averageOverallFlexibilityZScore = overallFlexibilityZScoreSum / this.athleteReport.length;
        this.flexibilityOverallPercentage = Math.min(Math.max(((averageOverallFlexibilityZScore + 3) / 6) * 100, 0), 100);

        const overallBalanceZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallBalanceZScore, 0);
        const averageOverallBalanceZScore = overallBalanceZScoreSum / this.athleteReport.length;
        this.balanceOverallPercentage = Math.min(Math.max(((averageOverallBalanceZScore + 3) / 6) * 100, 0), 100);

        const overallEnduranceZScoreSum = this.athleteReport.reduce((sum, report) => sum + report.overallEnduranceZScore, 0);
        const averageOverallEnduranceZScore = overallEnduranceZScoreSum / this.athleteReport.length;
        this.enduranceOverallPercentage = Math.min(Math.max(((averageOverallEnduranceZScore + 3) / 6) * 100, 0), 100);

      } else {
        console.error('No valid data available for Z-score calculation.');
      }
    } catch (error) {
      console.error('Error fetching fitness report:', error);
    }
  }

  async getTopPerformers(): Promise<void>  {
    // Combine athleteReport and nutritionReport by matching pfaAthlete and naAthlete
    const performersList = this.athleteReport.map(athlete => {
      // Find matching nutrition data by naAthlete
      const matchingNutrition = this.nutrionReport.find(nutrition => nutrition.naAthlete == athlete.pfaAthlete);
   
      // If match found, combine athlete and nutrition data
      if (matchingNutrition) {
        return {
          ...athlete, // Spread athlete data
          ...matchingNutrition // Spread matching nutrition data
        };
      }
   
      // If no match, return athlete data as is
      return athlete;
    });
   
    // Define the fields for each category score
    const scoreFields = [
      "overallStrengthZScore",    // Strength
      "overallFlexibilityScore",  // Flexibility
      "overallBalanceZScore",     // Balance
      "overallEnduranceZScore"    // Endurance
    ];
   
    // Define the maximum possible score for each category (adjust as necessary)
    const maxScorePerField = 100;  // Assuming each Z-score is out of 100
    const maxTotalScore = maxScorePerField * scoreFields.length;  // Total maximum score
   
    // Map through performers and calculate total score
    const updatedPerformersList = performersList.map(performer => {
      const totalScore = scoreFields.reduce((acc, field) => {
        return acc + (performer[field] || 0);  // Add score if the field exists
      }, 0);
   
      // Convert totalScore to percentage (out of 100)
      const percentageScore = (totalScore / maxTotalScore) * 100;
   
      // Return performer with the percentageScore included
      return {
        ...performer,
        totalScore: percentageScore   // Use percentage score
      };
    });
   
    // Sort the performers by totalScore and select the top 5
    this.topPerformers = this.getSortedPerformers(updatedPerformersList, "totalScore");
   
    // Fetch profile images for the top performers
    this.topPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.pfaAthlete);
    });
    const uniqueAthletesMap = new Map();
    this.topPerformers.forEach((item:any) => {
        uniqueAthletesMap.set(item.pfaAthlete, item);
    });
    this.topPerformers = Array.from(uniqueAthletesMap.values());
    // Apply filter (assuming filterPerformers is part of your UI logic)
    this.filterPerformers('All', 'overall');
   
    // Set the nutrition report to the top performers for display
    this.nutrionReport = this.topPerformers;
  }
   
  // Example sorting method to sort performers by a specific score field
  getSortedPerformers(performers: any[], scoreField: string): any[] {
    return performers.sort((a, b) => b[scoreField] - a[scoreField]);
  }

  selectRole(role: string, roleId: any) {
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: {  Org:this.org, status:'True' } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }
   

}
