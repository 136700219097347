import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AssessmentService } from '../services/assessment.service';
import { NotificationService } from '../services/Notifications.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../services/privilage.service';
import { Privilege } from '../model/Privillage';
import { NgSelectModule } from '@ng-select/ng-select';

interface IRow { }

@Component({
  selector: 'app-sleep-question',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule,NgSelectModule],
  templateUrl: './sleep-question.component.html',
  styleUrls: ['./sleep-question.component.css']
})
export class SleepQuestionComponent implements OnInit {
  currentSection = 1;
  formValues: any = {
    athleteId: '',
    sleep: {
      hours: '',
      quality: '',
      naps: '',
      napDuration: '',
      sarCreateOn:''
    }
  };
  mode: any;
  teamId: any;
  athleteId: any;
  id: any;
  assessor: any;
  categoryId: any;
  sarId: any;
  ImageUrl: any;
  showImage: boolean = false;
  selectdata: any;
  rowData: IRow[] = [];
  playerList: any[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;
  image: any;
  Assessor: boolean;
  filteredResponse: any;
  assessmentDate: any;
  minDate: any;
  maxDate: any;
  invalid:boolean=false;
  sciStaff: boolean = false;
  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,private cdRef: ChangeDetectorRef,private ngZone: NgZone,
    private priService: PrivilegeService
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
 
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.id = params['id'];
      this.assessor = params['assessor'];
      this.categoryId = params['categoryId'];
    })
  }
  today: string = new Date().toISOString().split('T')[0];
  ngOnInit() {
    this.getProfileImage();
    this.getSleepData(this.athleteId, this.id);
    this.GetPlayer();
    this.assessmentService.getpendingAssessment().subscribe(response => {
      this.filteredResponse = response.filter((item: any) => item.asmId == this.id);
      this.assessmentDate=this.filteredResponse[0].asmDate ? this.filteredResponse[0].asmDate.split('T')[0] : 'mm/dd/yyyy';
    });

  }

  updatePlayerLabels(): void {
    console.log(this.athleteId,"Athlete")
    this.playerList = this.playerList.map(player => ({
      ...player,
      displayLabel: !this.Assessor ? `${player.Name} - ${player.aplPlayer}` : player.aplPlayer
    }));
  }
  setDateConstraints(): void {
    this.ngZone.run(() => {
    const assessmentDate = this.assessmentDate;
    const today = new Date().toISOString().split('T')[0];
    this.minDate = assessmentDate;
    this.maxDate = today;
    this.formValues.sleep.sarCreateOn =this.formValues.sleep.sarCreateOn?this.formValues.sleep.sarCreateOn: today;
    this.cdRef.detectChanges();
    this.invalid=false;
  });
    const dateInput = document.querySelector('input[name="sarCreateOn"]') as HTMLInputElement;
    if (dateInput) {
      dateInput.showPicker?.();
    }
  }
  GetPlayer() {
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
      console.log('Assessment submitted', response);

      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            aplPlayer: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            aplAssessment: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
            "status": `${item.aplStatus}`,
          });
        }
      });

      // Convert the unique assessments map back to an array for your rowData
      this.rowData = Array.from(uniqueAssessments.values());
      this.playerName = this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
      this.playerList = this.rowData;
      this.updatePlayerLabels();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  onAthleteChange(selectedPlayer: any) {
    if (selectedPlayer) {
      const athleteId = selectedPlayer.aplPlayer;
      const assessmentId = selectedPlayer.aplAssessment;
      this.athleteId = selectedPlayer.aplPlayer
      this.minDate='';
      this.getProfileImage();
      this.getSleepData(athleteId, assessmentId);
      this.formValues = {
        athleteId: '',
        sleep: {
          hours: '',
          quality: '',
          naps: '',
          napDuration: ''
        }
      };
      this.GetPlayer();
    }
  }

  getSleepData(athleteId: any, id: any): void {
    const atheleId = Number(athleteId);
    const asmId = Number(id);
  
    this.assessmentService.getSleepandRestByAsm(athleteId, id).subscribe(response => {
      if (response.length > 0) {
        const sleepData = response[0];
        console.log(response, "sleep data");
  
        this.sarId = sleepData.sarId || '';
        this.formValues.sleep.hours = this.formatSleepHours(sleepData.answer1 || '');
        this.formValues.sleep.quality = this.formatSleepQuality(sleepData.answer2 || '');
        this.formValues.sleep.naps = this.formatYesNo(sleepData.answer3 || '');
        this.formValues.sleep.napDuration = this.formatNapHours(sleepData.answer4 || '');
        this.formValues.sleep.sarCreateOn =sleepData.sarCreateOn ? sleepData.sarCreateOn.split('T')[0] : 'mm/dd/yyyy';; 
  
        // Structure the questions and answers into JSON format
        const sleepJson = {
          athleteId: athleteId,
          sarId: this.sarId,
          questions: [
            {
              question: 'How many hours of sleep do you usually get per night?',
              answer: this.formValues.sleep.hours || ''
            },
            {
              question: 'How would you rate the quality of your sleep?',
              answer: this.formValues.sleep.quality || ''
            },
            {
              question: 'Do you take naps during the day to help with recovery?',
              answer: this.formValues.sleep.naps || ''
            },
            {
              question: 'If yes, how long are your naps?',
              answer: this.formValues.sleep.napDuration || ''
            }
          ]
        };
  
        console.log(sleepJson);
        const jsonStr = JSON.stringify(sleepJson, null, 2);
      }
    }, error => {
      console.error('Error fetching sleep data', error);
    });
  }
  
  // Helper function to format sleep hours into predefined ranges
  formatSleepHours(hours: string): string {
    const hoursLower = hours.toLowerCase();
  
    // Use regex to match numeric value and unit
    const match = hoursLower.match(/(\d+)\s?(hours|hrs|hour|hr)?/);
    
    if (hoursLower.includes('less') && hoursLower.includes('5')) {
      return 'Less than 5 hours';
    } else if (hoursLower.includes('more') && hoursLower.includes('8')) {
      return 'More than 8 hours';
    }
    if (match) {
      const sleepHours = parseInt(match[1], 10); // Extract the numeric value
  
      if (sleepHours >= 7 && sleepHours <= 8) {
        return '7-8 hours';
      } else if (sleepHours >= 6 && sleepHours < 7) {
        return '6-7 hours';
      } else if (sleepHours >= 5 && sleepHours < 6) {
        return '5-6 hours';
      } else if (sleepHours < 5) {
        return 'Less than 5 hours';
      } else if (sleepHours > 8) {
        return 'More than 8 hours';
      }
    }

  
    return hours; // Return as-is if no match
  }
  formatNapHours(hours: string): string {
    const hoursLower = hours.toLowerCase();
  
    // Use regex to match numeric value and unit
    const match = hoursLower.match(/(\d+)\s?(minutes|min|minute|mins)?/);
    
    if (hoursLower.includes('less') && hoursLower.includes('20')) {
      return 'Less than 20 minutes';
    } else if (hoursLower.includes('more') && hoursLower.includes('60')) {
      return 'More than 60 minutes';
    }
    if (match) {
      const sleepHours = parseInt(match[1], 10); // Extract the numeric value
  
      if (sleepHours >= 20 && sleepHours < 40) {
        return '20-40 minutes';
      } else if (sleepHours >= 40 && sleepHours < 60) {
        return '40-60 minutes';
      } else if (sleepHours < 20) {
        return 'Less than 20 minutes';
      } else if (sleepHours > 60) {
        return 'More than 60 minutes';
      } 
    }

  
    return hours; // Return as-is if no match
  }
  
  // Helper function to format sleep quality
  formatSleepQuality(quality: string): string {
    const qualityLower = quality.toLowerCase();
  
    if (qualityLower === 'poor') return 'Poor';
    if (qualityLower === 'fair') return 'Fair';
    if (qualityLower === 'good') return 'Good';
    if (qualityLower === 'very good') return 'Very Good';
    if (qualityLower === 'excellent') return 'Excellent';
  
    return quality; // Return as-is if no match
  }
  
  // Helper function to format Yes/No responses
  formatYesNo(answer: string): string {
    const answerLower = answer.toLowerCase();
    if (answerLower === 'yes') return 'Yes';
    if (answerLower === 'no') return 'No';
    return answer; // Return as-is if no match
  }
  

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
          console.log(this.image ,"image")
          
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        console.log(  this.image,"this.image")
        this.showImage = true;
          }
      );
      }
    );
  }
 
 

  // Method to check if all questions in the current section are answered
  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        return (
         
          this.formValues.sleep.hours !== '' &&
          this.formValues.sleep.quality !== '' &&
          this.formValues.sleep.naps !== '' &&
          (this.formValues.sleep.naps === 'No' || this.formValues.sleep.napDuration !== '')
        );

      default:
        return false;
    }
  }

  back(): void {
    this.location.back();
  }

  submit(): void {
    const submissionData = [{
      sarId: this.sarId ? Number(this.sarId) : 0,
      sarAthleteId: Number(this.athleteId),
      sarAssessmentId: Number(this.id),
      sarAssessor: Number(this.assessor),
      sarQ1: this.formValues.sleep.hours,
      sarQ2: this.formValues.sleep.quality,
      sarQ3: this.formValues.sleep.naps,
      sarQ4: this.formValues.sleep.naps === 'Yes' ? this.formValues.sleep.napDuration : null,
      sarCreateOn:this.formValues.sleep.sarCreateOn,
      sarCreatedBy: Number(this.assessor),
    }];
if(!this.formValues.sleep.sarCreateOn)
{
  this.ngZone.run(() => {
    this.notify.failed("Please select Test Taken Date ");
    this.invalid=true;
    this.cdRef.detectChanges();
  });
  return;
}
    console.log("Submission Data:", submissionData);

    this.assessmentService.saveSleepandRest(submissionData).subscribe(response => {
      this.updateAssessmentStatus();
      this.notify.success("Answers submitted successfully");
    }, error => {
      this.notify.failed("Error submiting form");
    });
   
  }

  updateAssessmentStatus(): void {
    this.assessmentService.updateAssessmentStatus(
      Number(this.athleteId),
      Number(this.id),
      Number(this.categoryId),
      Number(this.assessor)
    ).subscribe(response => {
      console.log("Assessment status updated:", response);
    }, error => {
    });
  }


  isReadOnly(): boolean {
    return this.mode === 'view';
  }
}
